// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import moment from 'moment-timezone'
import $ from 'jquery'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Paper,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
    CardIcon,
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, Cell
} from 'recharts';
import { withAlert } from 'hoc'
import {
    Done,
    EuroSymbol,
    FlightLand,
    HourglassEmpty, PanTool,
    ShoppingBasket,
    SwapVert,
    Undo
} from '@material-ui/icons';
// Our view components

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        fontSize: "1rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        fontSize: "1.25rem",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Roboto-Regular',
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        fontSize: "1.25rem",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Roboto-Bold',
        fontWeight: "bold",
        flexGrow: "1"
    },
})};

class InboundAnalysis extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };
    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            dashboard_stats: null,
            open_po: [],
            inbound_receiving: [],
            date_from: '',
            date_to: '',
            stat_type: '',
            stats_detail: [],
            loading: false,
        };

        document.title = "Inbound Analysis";
    }

    render() {
        const { classes } = this.props;
        let loadingBar = null;
        if (this.state.loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
            <Grid container spacing={3}>
                {loadingBar}

                {this.renderStats()}
                {this.renderStatsDetail()}
                {this.renderInboundReceiving()}
                {this.renderOpenPO()}
            </Grid>
        );
    }

    // api call
    componentWillMount() {
        this.loadStats();
    }

    // api call
        // load initial data
    loadStats = () => {
        let customer = localStorage.getItem('customer_id');
        let warehouse = localStorage.getItem('warehouse_id');
        if (!customer || !warehouse) {
            // if customer of warehouse is not set, don't make ajax call
            this.props.alert("Customer id or Warehouse id is not set, please select warehouse or login again");
            return;
        }

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': customer,
            'warehouse': warehouse
        };
        const API_URL = base + '/api/customer/v1/analysis/inbound';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        req.then(this.loadStatsSuccess).catch(this.loadStatsError);
        this.setState({loading: true});
    }
    loadStatsSuccess = (resp) => {
        this.setState({loading: false});

        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        this.setState({
            dashboard_stats: {
                annual_inbound_value: resp.data.annual_inbound_value,
                annual_inbound_number: resp.data.annual_inbound_number,
                annual_inbound_unit: resp.data.annual_inbound_unit,
                annual_inbound_discrepancies: resp.data.annual_inbound_discrepancies,
            },
            inbound_receiving: resp.data.discrepancies,
            open_po: resp.data.top5_open_po,
        });
    }
    loadStatsError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // load stat details
    loadDetail = (type) => {
        let date_from = '';
        let date_to = '';
        if (!type) {
            // if type is not passed in, it is loading by date range, get data from state
            type = this.state.stat_type;
            date_from = this.state.date_from;
            date_to = this.state.date_to;
        } else {
            // if type is passed in, it is loading default, reset date range
            this.setState({date_from: '', date_to: ''})
        }

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/analysis/inbound';

        let req = axios({
            method: 'post',
            url: API_URL,
            data: {
                type,
                date_from,
                date_to,
            },
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadDetailSuccess.bind(this, type)).catch(this.loadDetailError);
    }
    loadDetailSuccess = (type, resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.props.alert(resp.Error);
            return;
        }

        if (!resp || (Array.isArray(resp) && resp.length === 0)) {
            this.props.alert("No data found.");
            return;
        }
        this.setState({stats_detail: resp, stat_type: type});
    }
    loadDetailError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

    // render func
    renderStats = () => {
        let dashboard_stats = this.state.dashboard_stats ? this.state.dashboard_stats : {
            annual_inbound_value: "N/A",
            annual_inbound_number: "N/A",
            annual_inbound_unit: "N/A",
            annual_inbound_discrepancies: "N/A",
        };

        let revenue = (dashboard_stats.annual_inbound_value !== "N/A" ? '$' : "")+utils.numberWithComma(dashboard_stats.annual_inbound_value);
        let po = utils.numberWithComma(dashboard_stats.annual_inbound_number);
        let unit = utils.numberWithComma(dashboard_stats.annual_inbound_unit);
        let discrepancy = utils.formatPercentage(dashboard_stats.annual_inbound_discrepancies);

        return (
          <React.Fragment>
              {this.renderSingleStatCard(revenue, "annual_inbound_value")}
              {this.renderSingleStatCard(po, "annual_inbound_number")}
              {this.renderSingleStatCard(unit, "annual_inbound_unit")}
              {this.renderSingleStatCard(discrepancy, "annual_inbound_discrepancies")}
          </React.Fragment>
        );
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        if (!val || val === "N/A") click_disabled = true;

        let title = key;
        let icon = null;
        switch (key) {
            case "annual_inbound_value":
                icon = <CardIcon style={{
                    backgroundColor: "#30C4EE"
                }} className={classes.statIconContainer}>
                    <EuroSymbol className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Revenue";
                break;
            case "annual_inbound_number":
                icon = <CardIcon style={{
                    backgroundColor: "#8791C8"
                }} className={classes.statIconContainer}>
                    <FlightLand className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. POs";
                break;
            case "annual_inbound_unit":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B90"
                }} className={classes.statIconContainer}>
                    <ShoppingBasket className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Units";
                break;
            case "annual_inbound_discrepancies":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B9B"
                }} className={classes.statIconContainer}>
                    <SwapVert className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Discrepancies";
                break;
        }

        let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

        return (
          <GridItem xs={12} sm={6} md={limit_width ? 6 : 3}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadDetail(key);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                      whiteSpace: "normal"
                                  }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{val}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderStatsDetail = () => {
        const { classes } = this.props;
        const { stats_detail, stat_type, date_from, date_to } = this.state;

        if (!stats_detail || stats_detail.length === 0 || !stat_type) return null;

        let detail_title = stat_type;
        let chart_value = '';
        let data = Array.from(stats_detail);

        let colSettings = [];
        switch (stat_type) {
            case 'annual_inbound_value':
                detail_title = "Total Inbound Revenue";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'inbound_value',
                        label: 'Inbound Revenue',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "inbound_value";
                break;
            case 'annual_inbound_number':
                detail_title = "Total PO";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'inbound_number',
                        label: 'Number of PO',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "inbound_number";
                break;
            case 'annual_inbound_unit':
                detail_title = "Total Units";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'inbound_unit',
                        label: 'Units',
                        render: utils.numberWithComma
                    }
                ];
                data = data.map((val)=>{return {
                    month: val.month,
                    inbound_unit: parseInt(val.inbound_unit)
                };});
                chart_value = "inbound_unit";
                break;
            case 'annual_inbound_discrepancies':
                detail_title = "Total Discrepancy";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'ordered',
                        label: 'Ordered',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'received',
                        label: 'Received',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'percentage',
                        label: 'Percentage',
                        render: (val) => {
                            if (!val) val = 0;
                            return (parseFloat(val*100).toFixed(2)+'%')
                        }
                    }
                ];
                // todo need to modify the chart setting to show percentage, may also need to format data
                chart_value = "percentage";
                // data = data.map((val)=>{return {
                //     month: val.month,
                //     percentage: parseFloat(val.percentage)
                // };});
                break;
        }

        let rows = Array.from(stats_detail);

        return (
            <GridItem xs={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h4" id="tableTitle">
                                {detail_title}
                            </Typography>
                            <br/>

                            <CustomInput
                              labelText='Date From'
                              formControlProps={{
                                  required: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  style:{marginRight: '1rem'},
                                  views:['year', 'month'],
                                  type: 'date',
                                  value: date_from,
                                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                              }}
                            />
                            <CustomInput
                              labelText='Date To'
                              formControlProps={{
                                  required: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  type: 'date',
                                  value: date_to,
                                  onChange: (e)=>{this.setState({date_to: e.target.value})}
                              }}
                            />
                            <Button style={{marginLeft: '2rem', marginTop: "1rem"}}  onClick={()=>{
                                const { date_from, date_to } = this.state;
                                if (moment(date_to).isBefore(date_from)) this.props.alert("Date to should be later than Date from.");
                                else this.loadDetail();
                            }}>Submit</Button>
                        </div>

                        <ResponsiveContainer width='95%' height={500}>
                            <LineChart
                              data={data}
                              margin={{
                                  top: 5, right: 30, left: 20, bottom: 5,
                              }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tickFormatter={(val)=>{
                                    if (typeof val === "string" && val.length >= 3){
                                        return val.substr(0,3);
                                    } else return val;
                                }}/>
                                <YAxis />
                                <Tooltip labelFormatter={(val)=>{
                                    let month = val;
                                    let year = '';
                                    if (typeof val === "string" && val.length >= 3){
                                        month = val.substr(0,3);
                                        year = val.substr(3);
                                    }
                                    month = this.displayFullMonth(month);
                                    return month + (year ? ', '+ year : '');
                                }}
                                         formatter={
                                             (value, name) => {
                                                 let label = name;
                                                 switch(name) {
                                                     case 'inbound_value':
                                                         label = 'Inbound Revenue';
                                                         value = parseFloat(value) ? ('$' + utils.numberWithComma(value)) : value;
                                                         break;
                                                     case 'inbound_number':
                                                         label = 'Number of PO';
                                                         value = parseInt(value) ? (utils.numberWithComma(value)) : value;
                                                         break;
                                                     case 'inbound_unit':
                                                         label = 'Units';
                                                         value = parseInt(value) ? (utils.numberWithComma(value)) : value;
                                                         break;
                                                     case 'percentage':
                                                         label = 'Percentage';
                                                         value = utils.formatPercentage(value);
                                                         break;
                                                 }
                                                 return [value, label];
                                             }
                                         }
                                />
                                <Legend verticalAlign="top" height={36} formatter={
                                    (value) => {
                                        let label_map = {
                                            'inbound_value': 'Inbound Revenue',
                                            'inbound_number': 'Number of PO',
                                            'inbound_unit': 'Number of Units',
                                            'percentage': 'Percentage',
                                        };
                                        return label_map[value] ? label_map[value] : value;
                                    }
                                }/>
                                <Line type="monotone" dataKey={chart_value} stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>

                        <DataTable
                          data={rows}
                          className={classes.table}
                          tableSettings={{
                              tableProps: {
                                  id: "db_order"
                              },
                          }}
                          withoutPaper
                          colSettings={colSettings}
                        />

                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderInboundReceiving = () => {
        const { classes } = this.props;
        const { inbound_receiving } = this.state;

        if (!inbound_receiving || inbound_receiving.length === 0) return null;

        let data = inbound_receiving.map((val)=>{
            return {
                month: val.month,
                ordered: parseInt(val.ordered),
                received: parseInt(val.received),
            };
        });

        return (
            <GridItem xs={12} md={6}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h4" id="tableTitle">
                                Inbound Receiving
                            </Typography>
                        </div>

                        <ResponsiveContainer width='95%' height={500}>
                            <BarChart
                              data={data}
                              margin={{
                                  top: 5, right: 30, left: 20, bottom: 5,
                              }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tickFormatter={(val)=>{
                                    if (typeof val === "string" && val.length >= 3){
                                        return val.substr(0,3);
                                    } else return val;
                                }}/>
                                <YAxis />
                                <Tooltip labelFormatter={(val)=>{
                                    let month = val;
                                    let year = '';
                                    if (typeof val === "string" && val.length >= 3){
                                        month = val.substr(0,3);
                                        year = val.substr(3);
                                    }
                                    month = this.displayFullMonth(month);
                                    return month + (year ? ', '+ year : '');
                                }}
                                formatter={
                                    (value, name)=>{
                                        value = utils.numberWithComma(value);
                                        return [value, name];
                                    }}
                                />
                                <Legend verticalAlign="top" height={36}/>
                                <Bar dataKey="ordered" fill="#3E5485" />
                                <Bar dataKey="received" fill="#F17022" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderOpenPO = () => {
        const { classes } = this.props;
        const { open_po } = this.state;

        let rows = Array.from(open_po);

        let colSettings = [
            {
                key: 'po_name',
                label: "Name",
            },
            {
                key: 'quantity',
                label: "Discrepancy",
            },
            {
                key: 'received_dt',
                label: "Received Date",
                render: utils.formatDate
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h4" id="tableTitle">
                            Open PO
                            <Button style={{float: 'right'}}  onClick={()=>{utils.export_table_to_csv("open_po");}}>Export</Button>
                        </Typography>
                    </div>

                    <DataTable
                      data={rows}
                      className={classes.table}
                      tableSettings={{
                          tableProps: {
                              id: "open_po"
                          },
                      }}
                      withoutPaper
                      colSettings={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }

    displayFullMonth = (code) => {
        let monthMap = {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        };
        return monthMap[code] ? monthMap[code] : code;
    }
}

export default withAlert(withStyles(styles)(InboundAnalysis))

import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";

const useStyles = makeStyles({
  default_button: {
    borderRadius: "1.5rem",
    // fontSize: "1.25rem",
    fontSize: "1.5rem",
    textTransform: "none",
    whiteSpace: "nowrap",
    // Todo add this if font is Neuton
    paddingTop: 0,
  },
  orange: {
    backgroundColor: "#F17022",
    color: "white",
    "&:hover": {
      backgroundColor: "#F17022",
      color: "white",
      boxShadow:
        "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)"
    }
  }

});

function RegularButton({ ...props }) {
  const {
    classes,
    className,
    round,
    children,
    fullWidth,
    disabled,
    variant,
    color,
    ...rest
  } = props;
  let default_classes = useStyles();
  let button_class  = default_classes.default_button + " " + (className ? className : "");
  if (!color) button_class += " " + default_classes.orange;
  return (
    <Button {...rest} classes={classes} color={color} className={button_class} variant={variant ? variant : 'contained'}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RegularButton;

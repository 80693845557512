import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Paper, Table, TableBody, TableCell, TableHead, TableRow, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, EnhancedTable,
} from 'components';
import { withAlert } from 'hoc'
import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
});

class ManageInventory extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    alert: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.keys = [];
    this.adjustment_key = '';

    this.dataType = '';

    this.state = {
      loading: false,
      tableData: null,
      active_inventory: '',
      type: 'inventory',
      keyword: '',
    };

    document.title = "Inventory";
  }

  render() {
    const { classes } = this.props;
    const { loading, type, keyword } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container spacing={3}>
        {loadingBar}
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchInventory}>
              <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="search_type">Search Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                  inputProps={{
                    name: 'search_type',
                    id: 'search_type',
                  }}
                >
                  {/*<MenuItem value='adjustment_history'>Adjustment History</MenuItem>*/}
                  <MenuItem value='inventory'>SKU/UPC</MenuItem>
                  <MenuItem value='adjustment_history'>Adjustment History</MenuItem>
                  <MenuItem value='item_name'>Name</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Keyword'
                labelProps={{shrink: true}}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  onChange: (e)=>{this.setState({keyword: e.target.value})},
                  value: keyword,
                  // placeholder: 'Use Linebreak to separate multiple keywords',
                  // multiline: true,
                  // rows: 3
                }}
              />

              <Button type='submit' className={classes.button}>Search</Button>
            </form>
          </CardContainer>
        </GridItem>

        <GridItem xs={12}/>

        {this.renderTable()}
      </Grid>
    );
  }

  componentWillMount() {
    this.loadDefaultInventory();
  }

  // API call
  // download adjustment history
  downloadReport = (e) => {
    e.preventDefault();
    let keyword = this.adjustment_key;

    let base = this.context.data.getBase();
    // let url = base + '/report/' + localStorage.getItem('warehouse_id') + '/' + localStorage.getItem('customer_id') + '/' + 'adjustment_history' + '/' + keyword;
    let url = base + '/api/customer/v1/report/' + localStorage.getItem('warehouse_id') + '/' + localStorage.getItem('customer_id') + '/' + 'adjustment_history' + '/' + keyword;

    let w = window.open(url);
    if (!w) {
     this.props.alert('To enable download, please allow pop up window');
    }
  }
  // load default inventory
  loadDefaultInventory = () => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : ''),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '')
    };
    const API_URL = base + '/api/customer/v1/inventory';

    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.loadDefaultInventorySuccess).catch(this.loadDefaultInventoryError);
  }
  loadDefaultInventorySuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
     this.props.alert(data.Error);
      return;
    }
    if (!data || data.length === 0) {
     this.props.alert("No data found");
      return;
    }
    this.dataType = 'item_name';
    this.setState({tableData: data});
  }
  loadDefaultInventoryError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    if (resp.status === 401) {
     alert('Session expired, please login again');
      this.context.data.logout();
    }

    let msg = "Internal server error";
    if (resp.data.Error) {
     this.props.alert(resp.data.Error);
      return;
    }
   this.props.alert(msg);
  }
  // search products
  searchInventory = (e) => {
    e.preventDefault();
    const { type, keyword }= this.state;

    if (!utils.formatString(keyword, true)) {
      this.props.alert("Search key word is invalid");
      return;
    }

    e.preventDefault();
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };

    const API_URL = base + '/api/customer/v1/inventory';
    let req = axios({
      method: 'post',
      data: {
        type,
        keyword: utils.formatString(keyword, true)
      },
      url: API_URL,
      headers: headers
    });

    this.dataType = type;

    this.keys = [];
    this.adjustment_key = '';
    req.then(this.searchInventorySuccess.bind(this, utils.formatString(keyword, true))).catch(this.searchInventoryError);
    this.setState({loading: true, tableData: null, active_inventory: ''})
  }
  searchInventorySuccess = (keyword, resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
     this.props.alert(resp.data.Error);
      return;
    }

    if (resp.data && resp.data.length === 0) {
     this.props.alert("No data found");
      return;
    }

    this.adjustment_key = keyword;

    this.setState({tableData: resp.data});
  }
  searchInventoryError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    // logout if session expired
    if (resp.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
      return;
    }

    // alert error if any
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
    }
  }

  // render search result table
  renderTable = () => {
    if (!this.state.tableData) return null;
    switch (this.dataType) {
      case "inventory":
        return this.renderInventory();
      case "item_name":
        return this.renderInventoryByName();
      case "adjustment_history":
        return this.renderAdjustment();
    }
  }
  renderInventory = () => {
    const { classes } = this.props;
    const {  tableData } = this.state;

    let item = Array.isArray(tableData.item_detail) ? tableData.item_detail[0] : tableData.item_detail;

    let in_stock = parseInt(tableData.in_stock);
    let allocated = parseInt(tableData.allocation);
    let open_order = parseInt(tableData.open_order);
    let onhold = parseInt(tableData.onhold);
    let orders = tableData.order_list;

    let orderRows = [];
    for (let order of orders) {
      let status = order.status_id;

      switch (parseInt(status)) {
        case 7:
        case 12:
          status = 'Allocated';
          break;
        default:
          status = utils.convertStatus(order.status_id)
      }

      orderRows.push(
        <TableRow key={order.order_key}>
          <TableCell>{order.order_key}</TableCell>
          <TableCell>{order.quantity}</TableCell>
          <TableCell>{status}</TableCell>
        </TableRow>
      );
    }

    if (orderRows.length === 0) {
      orderRows.push(
        <TableRow key={'empty'}>
          <TableCell colSpan={3}>No Data</TableCell>
        </TableRow>
      );
    }

    return (
      <React.Fragment>
        <GridItem xs={12} sm={12} md={6} key="detail">
          <CardContainer>
            <div className={classes.title}>
              <Typography variant="h4">
                Item Detail
              </Typography>
            </div>

            <div className={classes.tableWrapper} style={{width: "100%"}}>
              <Table className='with-border'>
                <TableHead>
                  <TableRow className='cls-table-header-text'>
                    <TableCell>Property</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>{item.sku}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>UPC</TableCell>
                    <TableCell>{item.upc}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Style</TableCell>
                    <TableCell>{item.style ? item.style : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    <TableCell>{item.size ? item.size : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Color</TableCell>
                    <TableCell>{item.color ? item.color : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Vendor</TableCell>
                    <TableCell>{item.brand ? item.brand : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>{item.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Quantity in Stock</TableCell>
                    <TableCell>{in_stock}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Allocated</TableCell>
                    <TableCell>{allocated}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Open Order</TableCell>
                    <TableCell>{open_order}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Onhold</TableCell>
                    <TableCell>{onhold}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Available</TableCell>
                    <TableCell>{in_stock - allocated - onhold - open_order}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} key="allocation">
          <CardContainer>
            <div className={classes.title}>
              <Typography variant="h4">
                {"Allocation"}
              </Typography>
            </div>

            <div className={classes.tableWrapper} style={{width: "100%"}}>
              <Table className='with-border'>
                <TableHead>
                  <TableRow className='cls-table-header-text'>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {orderRows}
                </TableBody>
              </Table>
            </div>
          </CardContainer>
        </GridItem>
      </React.Fragment>
    );
  }
  renderAdjustment = () => {
    const { classes } = this.props;

    let colSettings = [
      {
        key: 'sku',
        label: 'SKU',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'item_name',
        label: 'ItemName',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'adjustment',
        label: 'Adjustment',
        render: utils.tableCellOnDisplay,
      },{
        key: 'tracking',
        label: 'TrackingNumber',
        render: utils.tableCellOnDisplay,
      },{
        key: 'order_id',
        label: 'OrderId',
        render: utils.tableCellOnDisplay,
      },{
        key: 'po',
        label: 'PO',
        render: utils.tableCellOnDisplay,
      },{
        key: 'location',
        label: 'Location',
        render: utils.tableCellOnDisplay,
      },{
        key: 'pallet',
        label: 'Pallet',
        render: utils.tableCellOnDisplay,
      },{
        key: 'type',
        label: 'Type',
        render: utils.tableCellOnDisplay,
      },{
        key: 'created_dt',
        label: 'AdjustmentDate',
        render: utils.formatDate,
      }
    ];

    let product_sku = this.state.tableData[0].sku;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div>
            <Button onClick={this.downloadReport}>Download Full Report</Button>
            <DataTable
              data={this.state.tableData}
              withoutPaper
              tableSettings={{
                tableProps: {
                  className: "with-border",
                  id: 'table-export'
                }
              }}
              colSettings={colSettings}
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderInventoryByName = () => {
    const {classes} = this.props;
    const { tableData } = this.state;

    if (!tableData || tableData.length === 0) return null;

    let colSettings = [
      {
        key: 'sku',
        label: 'SKU',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'upc',
        label: 'UPC',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'style',
        label: 'Style',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'color',
        label: 'Color',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'description',
        label: 'Name',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'quantity',
        label: 'Quantity In Stock',
        render: utils.tableCellOnDisplay,
      }
    ];

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div className={classes.title} style={{height: '3rem'}}>
            <Typography variant="h4">
              Inventory
            </Typography>
          </div>
          <DataTable
            withoutPaper
            data={tableData}
            // tableTitle={"Inventory"}
            colSettings={colSettings}
          />
        </CardContainer>
      </GridItem>
    );
  }
}
export default withAlert(withStyles(styles)(ManageInventory));

import _ from 'lodash'
import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment-timezone'

function passwordValidator (pwd) {
  let password_value = pwd ? pwd : "";
  let requirements = {
    charLength: function() {
      if( password_value.length >= 8 ) return false;
      else return "Password length should be >= 8.";
    },
    illegalChar: function() {
      let regex = /["' ]/g;
      if( regex.test(password_value) ) return "Password should NOT contain space or quotes.";
      else return false;
    },
    lowercase: function() {
      let regex = /^(?=.*[a-z]).+$/;
      if( regex.test(password_value) ) return false;
      else return "Password should contain at lease one lowercase letter.";
    },
    uppercase: function() {
      let regex = /^(?=.*[A-Z]).+$/;
      if( regex.test(password_value) ) return false;
      else return "Password should contain at least one uppercase letter."
    },
    special: function() {
      let regex = /^(?=.*[0-9_\W]).+$/;
      if( regex.test(password_value) ) return false;
      else return "Password should contain at least a number or a special symbol."
    }
  };
  let errors = [];
  for (let key in requirements) {
    let validator = requirements[key];
    let error = validator();
    if (error) errors.push(error);
  }
  return errors;
}
function validateRequiredInput (value, key) {
  let err = "";
  if (!value) err += (key ? key : "This field") + " is required. \n";
  else if (!formatString(value)) err += key + " is invalid. \n";
  return err;
}
function formatString (val, multiline, replace_whitespace) {
  if (replace_whitespace) {
    // replace whitespace with +
    val = val.replace(/[ ]/g, "+");
  }

  if (multiline) {
    // the string to be formatted is multi line, split by \n
    // after format, concat it by , symbol
    // may need to allow custom separator later
    if (!val) return val;
    if (typeof val === "number") val = '' + val;

    let result = val;
    try {
      result = val.trim();
      result = result.replace(/["']/g, "");
      // result = result.split('\n').map((val)=>{return val.trim()}).join('\n');
      result = result.split('\n').map((val)=>{return val.trim();}).join(',');
      result = result.split(',').filter((v)=>v!=='').join(',');
    }
    catch (err) {
      console.log(err);
    }
    return result;
  } else {
    if (!val) return val;
    if (typeof val === "number") val = '' + val;

    let result = val;
    try {
      result = val.trim();
      result = result.replace(/["']/g, "");
    }
    catch (err) {
      console.log(err);
    }
    return result;
  }
} // string format, remove quote, trim
function formatDate (dt, key, rowData) {
  if (!dt) return '';

  let newDt = moment.utc(dt);
  newDt = newDt.local();

  return newDt.format('YYYY-MM-DD HH:mm:ss');
} // date format, convert utc to local time
function formatDateTime (dt, format_string, utc_to_local) {
  if (!dt) return '';
  if (!format_string) format_string = 'YYYY-MM-DD HH:mm:ss';

  if (utc_to_local) {
    let newDt = moment.utc(dt);
    newDt = newDt.local();
    return newDt.format(format_string);
  } else {
    let newDt = moment(dt);
    return newDt.format(format_string);
  }
}
function formatPercentage (val) {
  if (isNaN(parseFloat(val))) return val;
  val = parseFloat(val*100);
  return (val.toFixed(2)+'%');
}
function numberWithComma (val, fixed_digit) {
  if (val === "N/A") return val;
  val = parseFloat(val) ? parseFloat(val) : parseFloat("0");
  if (fixed_digit) val = val.toFixed(fixed_digit);
  val = '' + val;
  let parts = val.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
function convertBooleanToString (value) {
  switch (true) {
    case value === 1:
    case value === "1":
    case value === true:
    case value === "true":
    case value === "true":
      return "YES";
    default:
      return "NO"
  }
}
function convertStatus (code) {
  code = parseInt(code);
  let codeMap = {
    2:"Received",
    3:"Deleted",
    5: "On Hold",
    6:"Cancelled",
    7:"Picking",
    8:"Picked",
    9:"Shipped",
    12:"Verified",
    13:"Returned",
    14: "Inbound",
  };

  return codeMap[code] ? codeMap[code] : code;
}
function convertShortShip (code) {
  let code_map = {
    back_order: 'Short Ship',
    out_of_stock: 'Back Order',
  };
  return code_map[code] ? code_map[code] : code;
}
function convertPOStatus (code) {
  code = parseInt(code);
  let codeMap = {
    2:"Pending Received",
    4:"Received",
    7:"Receiving",
  };

  return codeMap[code] ? codeMap[code] : '';
}
function convertShippingMethod (code) {
  let convertMethod = {
    "BXZ.PKP": "Pack and Hold",
    "BXZ.USA.1": "Boxzooka 1-Day",
    "BXZ.USA.2": "Boxzooka 2-Day",
    "BXZ.USA.3": "Boxzooka 3-Day",
    "BXZ.USA.5": "Boxzooka 5-Day",
    "BXZ.USA.7": "Boxzooka 7-Day",
    "BXZ.SAMEDAY.NYC": "BXZ Same Day",
    "UPS.DOM.1": "UPS Next Day Air",
    "UPS.DOM.2": "UPS Second Day Air",
    "UPS.DOM.3": "UPS 3-Day Air",
    "UPS.EXP.1" : 'UPS Next Day Air Early',
    "UPS.GRD.RESI": "UPS Ground",
    "SUREPOST": "UPS Surepost",
    "DHLEC.MAX": "DHL 3-Day Priority",
    "DHLEC.STD.GRD": "DHL Standard Ground Shipping",
    "DHLEC.PLT": "International Standard (4-12 Days)",
    "DHLEC.BPM": "DHL Bound Printed Matter Service",
    "DHLEC.SAMEDAY": "DHL Same Day",
    "DHLEC.NEXTDAY": "DHL Next Day",
    "FDX.DOM.1": "FedEx Next Day Air",
    "FDX.DOM.2": "FedEx Second Day Air",
    "FDX.DOM.3": "FedEx 3-Day Air",
    "FDX.GRD": "FedEx Ground",
    "FDX.HOME": "FedEx Home",
    "SMARTPOST": "FedEx SmartPost",
    "FDX.EXP.1": 'FedEx Next Day Air Early',
    // right now using FedEx international
    "UPS.INTL.EXP": "UPS International Expedited (2-5 Days)",
    "FDX.INTL.ECO": "FedEx International Expedited (2-5 Days)",
    "USPS.PRIORITY": 'USPS Priority',
    "USPS.PARCEL": 'USPS ParcelSelect',
    "USPS.FIRST": 'USPS First',
    "USPS.EXPRESS": "USPS Express",
  };
  let lbl = convertMethod[code];
  if (lbl === undefined) {
    // if code doesn't match any label, just display code
    return code;
    // lbl = "Method not matched";
  }
  return lbl;
}
function convertAddress (order) {
  let addr = "";

  let name = '';
  if (order.fullname) {
    name = order.fullname;
  } else {
    name = order.firstname;
    if (order.lastname) name += ' ' + order.firstname;
  }

  if (name) {
    addr += name;
    addr += ", ";
  }
  if (order.address) {
    addr += order.address;
    addr += ", ";
  }
  if (order.city) {
    addr += order.city;
    addr += ", ";
  }
  if (order.province) {
    addr += order.province;
    addr += ", ";
  }
  if (order.postalcode) {
    addr += order.postalcode;
  }
  if (order.countrycode) {
    addr += order.countrycode;
  }
  if (addr[addr.length-1] === " ") addr = addr.slice(0, addr.length-2);
  return addr;
}
function tableCellOnDisplay (val, key, rowData) {
  if (val !== '0' && val !== 0) {
    val = val ? val : "";
  }

  return (<div style={{maxHeight: "80px", overflow: "auto"}}>{val}</div>);
}
  // Useful Functions
function generalAjaxErrorHandler (this_context, err, error_message, optional_callback) {
  // the context is the passed in 'this' reference
  this_context.setState({loading: false});
  // todo, determine when to call optional_callback, before or after
  if (optional_callback) optional_callback();
  let resp = err.response;
  if (!resp) return;
  // logout if session expired
  if (resp.status === 401) {
    alert('Session expired, please login again');
    this_context.context.data.logout();
    return;
  }
  let message_content = error_message ? error_message : "Internal server error.";
  if (this_context.props.alert) this_context.props.alert(message_content);
  else alert(message_content);
}
function initGoogleMap (callback) {
  return;
  // if (window.google) return;
  // let script = document.createElement('script');
  // console.log("Todo, might need to update API key");
  // script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-KafwpmUT7lLrerRJTftjlF3gTL2WvLY&libraries=places&callback=initGoogleMap';
  // // script.src = 'https://maps.googleapis.com/maps/api/js?key=asdfg&libraries=places&callback=initGoogleMap';
  // script.defer = true;
  // script.async = true;
  //
  // // Attach your callback function to the `window` object
  // window.initGoogleMap = function() {
  //   // JS API is loaded and available
  //   if (callback) callback();
  // };
  // // Append the 'script' element to 'head'
  // document.head.appendChild(script);
}
function playSound (audioContext) {
  let oscillator = audioContext ? audioContext.createOscillator() : null;
  if (!audioContext || !oscillator) {
    if (!window.AudioContext) window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
    let audioContext = null;
    try {
      audioContext = new window.AudioContext();
      oscillator = audioContext ? audioContext.createOscillator() : null;
    } catch (e) {
      console.log('!Your browser does not support AudioContext');
      return;
    }
  }
  // 创建一个GainNode,它可以控制音频的总音量
  let gainNode = audioContext.createGain();
  // 把音量，音调和终节点进行关联
  oscillator.connect(gainNode);
  // audioContext.destination返回AudioDestinationNode对象，表示当前audio context中所有节点的最终节点，一般表示音频渲染设备
  gainNode.connect(audioContext.destination);
  // 指定音调的类型，其他还有square|triangle|sawtooth
  oscillator.type = 'sine';
  // 设置当前播放声音的频率，也就是最终播放声音的调调
  oscillator.frequency.value = 493.88;
  // 当前时间设置音量为0
  gainNode.gain.setValueAtTime(0, audioContext.currentTime);
  // 0.01秒后音量为1
  gainNode.gain.linearRampToValueAtTime(40, audioContext.currentTime + 0.01);
  // 音调从当前时间开始播放
  oscillator.start(audioContext.currentTime);
  // 1秒内声音慢慢降低，是个不错的停止声音的方法
  // gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 2);
  // 1秒后完全停止声音
  oscillator.stop(audioContext.currentTime + 1.4);
}
function printBarcode (content) {
  let base = window.location.origin;
  if (base === "http://localhost:3000") base = 'https://sandboxdemo.boxzooka.com';

  // let url = base + '/packingid/' + content;
  let url = base + '/api/customer/v1/packingid/' + content;
  let w = window.open(url);
  if (!w) {
    alert('To enable print, please allow pop up window');
  }
}
function printPage (pageText, same_page_print) {
  let w = window.open();
  // let w = window.open('', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  if (!w) {
    alert("To enable print, please allow pop up window");
    return;
  }
  w.document.write(pageText);

  // if (same_page_print) {
  //     $('#iframe-print').remove();
  //     $('body').append(
  //         `<iframe src="" style='display: none' id="iframe-print"></iframe>`
  //     );
  //     document.getElementById('iframe-print').contentWindow.document.write(pageText);
  //
  // } else {
  //     let w = window.open();
  //     // let w = window.open('', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  //     if (!w) {
  //         alert("To enable print, please allow pop up window");
  //         return;
  //     }
  //     w.document.write(pageText);
  // }
}
function printText (printText, style, autoPrint) {
  if (style === undefined) style = "";
  var w = window.open();
  // style = "body {margin: 0;padding: 0;text-align: center} img {transform:rotate(90deg);position: fixed;top: 3.2cm;left: -2.9cm;width: 16cm;height: 9.9cm;}";
  var pageText = "<html><head><title></title><style>" + style + "</style></head><body>";
  pageText += printText;
  pageText += "</body>";
  w.document.write(pageText);
  if (autoPrint) {
    w.document.write("<scr" + "ipt>");
    w.document.write("function printPage(){window.print();window.close();}");
    w.document.write("setTimeout(printPage, 500)");
    w.document.write("</scr" + "ipt>");
  }
}  // wrap incoming text in html, apply the style comes in, and auto print it
function scrollToTop () {
  window.location = '#';
  document.getElementById('page-content-container').scrollTop = 0;
}
function export_table_to_csv (table_id, file_name, header_only) {
  let csv = [];
  // default table id is '#table-export'
  // current not allowed to directly pass in the table text to be exported
  if (!table_id) table_id = 'table-export';
  // set default file name
  if (!file_name) file_name = 'Exported_File';

  // if given file name doesn't contain extension .csv append
  if (file_name.indexOf('.csv') === -1) {
    file_name += '.csv';
  }

  let rows = document.querySelectorAll("table#" + table_id + " tr");
//        var rows = document.querySelectorAll("table tr");
  for (let i = 0; i < rows.length; i++) {
    let selector = "td, th";
    if (header_only) selector = 'th';
    let row = [], cols = rows[i].querySelectorAll(selector);
    for (let j = 0; j < cols.length; j++){
      row.push(cols[j].innerText.replace('\n', '').replace(',', '').trim());
    }
    csv.push(row.join(","));
  }
  // Download CSV
  // this.download_csv(csv.join("\n"), filename);
  csv = csv.join("\n");


  let csvFile;
  let downloadLink;
  // CSV FILE
  csvFile = new Blob([csv], {type: "text/csv"});
  // Download link
  downloadLink = document.createElement("a");
  // File name
  downloadLink.download = file_name;
  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);
  // Make sure that the link is not displayed
  downloadLink.style.display = "none";
  // Add the link to your DOM
  document.body.appendChild(downloadLink);
  // Lanzamos
  downloadLink.click();
} // todo, this can only export current table page. need to fix when it has multiple pages and filtered
function export_array_to_csv (array, headers, file_name) {
  // each array element should be an object
  // each object should be a simple object, that means each property value should be a string/number
  // the headers is the property list, need to follow the order of header to access the object value
  let csv = [];
  // set default file name
  if (!file_name) file_name = 'Exported_File';

  // if given file name doesn't contain extension .csv append
  if (file_name.indexOf('.csv') === -1) {
    file_name += '.csv';
  }

  if (!array || !headers || headers.length === 0) {
    csv = [];
  } else {
    csv.push(headers.map(elem=>{return elem.label}).join(','));
    for (let elem of array) {
      let text_array = [];
      for (let header of headers) {
        // let val = (''+elem[header.key]).replace('\n', '').replace(',', ' ').trim();
        let val = elem[header.key];
        if (header.onDisplay) val = header.onDisplay(val, header.key, elem);
        else if (header.render) val = header.render(val, header.key, elem);
        val = (''+val).replace('\n', '').replace(/,/g, '').trim();
        text_array.push(val);
      }
      csv.push(text_array.join(','));
    }
  }
  // Download CSV
  // this.download_csv(csv.join("\n"), filename);
  csv = csv.join("\n");

  let csvFile;
  let downloadLink;
  // CSV FILE
  csvFile = new Blob([csv], {type: "text/csv"});
  // Download link
  downloadLink = document.createElement("a");
  // File name
  downloadLink.download = file_name;
  // We have to create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);
  // Make sure that the link is not displayed
  downloadLink.style.display = "none";
  // Add the link to your DOM
  document.body.appendChild(downloadLink);
  // Lanzamos
  downloadLink.click();
}

export default {
  // Value Validations, Value Formatter
  passwordValidator,
  validateRequiredInput,
  formatString,
  formatDate,
  formatDateTime,
  formatPercentage,
  numberWithComma,
  convertBooleanToString,
  convertStatus,
  convertShortShip,
  convertPOStatus,
  convertShippingMethod,
  convertAddress,
  tableCellOnDisplay,
  generalAjaxErrorHandler,
  initGoogleMap,
  playSound,
  printBarcode,
  printPage,
  printText,
  scrollToTop,
  export_table_to_csv,
  export_array_to_csv,
  getGeneralStyles: (theme)=>({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectInput: {
      marginTop: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 700,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    customInput: {
      margin: theme.spacing(1, 0),
    },
    button: {
      marginTop: theme.spacing(1)
    },
    btnGray: {
      marginTop: theme.spacing(1),
      backgroundColor: "#999999",
      color: "white",
      "&:hover": {
        backgroundColor: "#999999",
        color: "white",
      }
    },
    btnGreen: {
      marginTop: theme.spacing(1),
      backgroundColor: "#4caf50",
      color: "white",
      "&:hover": {
        backgroundColor: "#4caf50",
        color: "white",
      }
    },
    btnLightBlue: {
      marginTop: theme.spacing(1),
      backgroundColor: "#00e5ff",
      color: "white",
      "&:hover": {
        backgroundColor: "#00e5ff",
        color: "white",
      }
    }
  }),
}

// export default {
//   passwordValidator: (pwd)=>{
//     let password_value = pwd ? pwd : "";
//     let requirements = {
//       charLength: function() {
//         if( password_value.length >= 8 ) return false;
//         else return "Password length should be >= 8.";
//       },
//       illegalChar: function() {
//         let regex = /["' ]/g;
//         if( regex.test(password_value) ) return "Password should NOT contain space or quotes.";
//         else return false;
//       },
//       lowercase: function() {
//         let regex = /^(?=.*[a-z]).+$/;
//         if( regex.test(password_value) ) return false;
//         else return "Password should contain at lease one lowercase letter.";
//       },
//       uppercase: function() {
//         let regex = /^(?=.*[A-Z]).+$/;
//         if( regex.test(password_value) ) return false;
//         else return "Password should contain at least one uppercase letter."
//       },
//       special: function() {
//         let regex = /^(?=.*[0-9_\W]).+$/;
//         if( regex.test(password_value) ) return false;
//         else return "Password should contain at least a number or a special symbol."
//       }
//     };
//     let errors = [];
//     for (let key in requirements) {
//       let validator = requirements[key];
//       let error = validator();
//       if (error) errors.push(error);
//     }
//     return errors;
//   },
//   // Useful Functions
//   generalAjaxErrorHandler: (this_context, err, error_message, optional_callback)=>{
//     // the context is the passed in 'this' reference
//     this_context.setState({loading: false});
//     // todo, determine when to call optional_callback, before or after
//     if (optional_callback) optional_callback();
//     let resp = err.response;
//     if (!resp) return;
//     // logout if session expired
//     if (resp.status === 401) {
//       alert('Session expired, please login again');
//       this_context.context.data.logout();
//       return;
//     }
//     let message_content = error_message ? error_message : "Internal server error.";
//     if (this_context.props.alert) this_context.props.alert(message_content);
//     else alert(message_content);
//   },
//   // date format, convert utc to local time
//   formatDate:  (dt, key, rowData) => {
//     if (!dt) return '';
//
//     let newDt = moment.utc(dt);
//     newDt = newDt.local();
//
//     return newDt.format('YYYY-MM-DD HH:mm:ss');
//   },
//   initGoogleMap: (callback)=>{
//     if (window.google) return;
//     let script = document.createElement('script');
//     console.log("Todo, might need to update API key");
//     script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-KafwpmUT7lLrerRJTftjlF3gTL2WvLY&libraries=places&callback=initGoogleMap';
//     // script.src = 'https://maps.googleapis.com/maps/api/js?key=asdfg&libraries=places&callback=initGoogleMap';
//     script.defer = true;
//     script.async = true;
//
//     // Attach your callback function to the `window` object
//     window.initGoogleMap = function() {
//       // JS API is loaded and available
//       if (callback) callback();
//     };
//     // Append the 'script' element to 'head'
//     document.head.appendChild(script);
//   },
//   formatDateTime: (dt, format_string, utc_to_local)=>{
//     if (!dt) return '';
//     if (!format_string) format_string = 'YYYY-MM-DD HH:mm:ss';
//
//     if (utc_to_local) {
//       let newDt = moment.utc(dt);
//       newDt = newDt.local();
//       return newDt.format(format_string);
//     } else {
//       let newDt = moment(dt);
//       return newDt.format(format_string);
//     }
//   },
//   formatPercentage: (val) => {
//     if (isNaN(parseFloat(val))) return val;
//     val = parseFloat(val*100);
//     return (val.toFixed(2)+'%');
//   },
//   numberWithComma: (val, fixed_digit) => {
//     if (val === "N/A") return val;
//     val = parseFloat(val) ? parseFloat(val) : parseFloat("0");
//     if (fixed_digit) val = val.toFixed(fixed_digit);
//     val = '' + val;
//     let parts = val.toString().split(".");
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     return parts.join(".");
//   },
//   // string format, remove quote, trim
//   formatString: (val, multiline, replace_whitespace) => {
//     if (replace_whitespace) {
//       // replace whitespace with +
//       val = val.replace(/[ ]/g, "+");
//     }
//
//     if (multiline) {
//       // the string to be formatted is multi line, split by \n
//       // after format, concat it by , symbol
//       // may need to allow custom separator later
//       if (!val) return val;
//       if (typeof val === "number") val = '' + val;
//
//       let result = val;
//       try {
//         result = val.trim();
//         result = result.replace(/["']/g, "");
//         // result = result.split('\n').map((val)=>{return val.trim()}).join('\n');
//         result = result.split('\n').map((val)=>{return val.trim();}).join(',');
//         result = result.split(',').filter((v)=>v!=='').join(',');
//       }
//       catch (err) {
//         console.log(err);
//       }
//       return result;
//     } else {
//       if (!val) return val;
//       if (typeof val === "number") val = '' + val;
//
//       let result = val;
//       try {
//         result = val.trim();
//         result = result.replace(/["']/g, "");
//       }
//       catch (err) {
//         console.log(err);
//       }
//       return result;
//     }
//   },
//   validateRequiredInput: (value, key)=> {
//     let err = "";
//     console.log(value);
//     if (!value) err += (key ? key : "This field") + " is required. \n";
//     else if (!formatString(value)) err += key + " is invalid. \n";
//     return err;
//   },
//   playSound: (audioContext) => {
//     let oscillator = audioContext ? audioContext.createOscillator() : null;
//     if (!audioContext || !oscillator) {
//       if (!window.AudioContext) window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
//       let audioContext = null;
//       try {
//         audioContext = new window.AudioContext();
//         oscillator = audioContext ? audioContext.createOscillator() : null;
//       } catch (e) {
//         console.log('!Your browser does not support AudioContext');
//         return;
//       }
//     }
//     // 创建一个GainNode,它可以控制音频的总音量
//     let gainNode = audioContext.createGain();
//     // 把音量，音调和终节点进行关联
//     oscillator.connect(gainNode);
//     // audioContext.destination返回AudioDestinationNode对象，表示当前audio context中所有节点的最终节点，一般表示音频渲染设备
//     gainNode.connect(audioContext.destination);
//     // 指定音调的类型，其他还有square|triangle|sawtooth
//     oscillator.type = 'sine';
//     // 设置当前播放声音的频率，也就是最终播放声音的调调
//     oscillator.frequency.value = 493.88;
//     // 当前时间设置音量为0
//     gainNode.gain.setValueAtTime(0, audioContext.currentTime);
//     // 0.01秒后音量为1
//     gainNode.gain.linearRampToValueAtTime(40, audioContext.currentTime + 0.01);
//     // 音调从当前时间开始播放
//     oscillator.start(audioContext.currentTime);
//     // 1秒内声音慢慢降低，是个不错的停止声音的方法
//     // gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 2);
//     // 1秒后完全停止声音
//     oscillator.stop(audioContext.currentTime + 1.4);
//   },
//   convertBooleanToString: (value) => {
//     switch (true) {
//       case value === 1:
//       case value === "1":
//       case value === true:
//       case value === "true":
//       case value === "true":
//         return "YES";
//       default:
//         return "NO"
//     }
//   },
//   convertStatus: (code) => {
//     code = parseInt(code);
//     let codeMap = {
//       2:"Received",
//       3:"Deleted",
//       5: "On Hold",
//       6:"Cancelled",
//       7:"Picking",
//       8:"Picked",
//       9:"Shipped",
//       12:"Verified",
//       13:"Returned",
//       14: "Inbound",
//     };
//
//     return codeMap[code] ? codeMap[code] : code';
//   },
//   convertShortShip: (code) => {
//     let code_map = {
//       back_order: 'Short Ship',
//       out_of_stock: 'Back Order',
//     };
//     return code_map[code] ? code_map[code] : code;
//   },
//   convertPOStatus: (code) => {
//     code = parseInt(code);
//     let codeMap = {
//       2:"Pending Received",
//       4:"Received",
//       7:"Receiving",
//     };
//
//     return codeMap[code] ? codeMap[code] : '';
//   },
//   convertShippingMethod: (code) => {
//     let convertMethod = {
//       "BXZ.PKP": "Pack and Hold",
//       "BXZ.USA.1": "Boxzooka 1-Day",
//       "BXZ.USA.2": "Boxzooka 2-Day",
//       "BXZ.USA.3": "Boxzooka 3-Day",
//       "BXZ.USA.5": "Boxzooka 5-Day",
//       "BXZ.USA.7": "Boxzooka 7-Day",
//       "BXZ.SAMEDAY.NYC": "BXZ Same Day",
//       "UPS.DOM.1": "UPS Next Day Air",
//       "UPS.DOM.2": "UPS Second Day Air",
//       "UPS.DOM.3": "UPS 3-Day Air",
//       "UPS.EXP.1" : 'UPS Next Day Air Early',
//       "UPS.GRD.RESI": "UPS Ground",
//       "DHLEC.MAX": "DHL 3-Day Priority",
//       "DHLEC.STD.GRD": "DHL Standard Ground Shipping",
//       "DHLEC.PLT": "International Standard (4-12 Days)",
//       "DHLEC.SAMEDAY": "DHL Same Day",
//       "DHLEC.NEXTDAY": "DHL Next Day",
//       "FDX.DOM.1": "FedEx Next Day Air",
//       "FDX.DOM.2": "FedEx Second Day Air",
//       "FDX.DOM.3": "FedEx 3-Day Air",
//       "FDX.GRD": "FedEx Ground",
//       "FDX.HOME": "FedEx Home",
//       "SMARTPOST": "FedEx SmartPost",
//       "FDX.EXP.1": 'FedEx Next Day Air Early',
//       // right now using FedEx international
//       "UPS.INTL.EXP": "UPS International Expedited (2-5 Days)",
//       "FDX.INTL.ECO": "FedEx International Expedited (2-5 Days)",
//       "USPS.PRIORITY": 'USPS Priority',
//       "USPS.PARCEL": 'USPS ParcelSelect',
//       "USPS.FIRST": 'USPS First',
//       "USPS.EXPRESS": "USPS Express",
//     };
//     let lbl = convertMethod[code];
//     if (lbl === undefined) {
//       // if code doesn't match any label, just display code
//       return code;
//       // lbl = "Method not matched";
//     }
//     return lbl;
//   },
//   convertAddress: (order) => {
//     let addr = "";
//
//     let name = '';
//     if (order.fullname) {
//       name = order.fullname;
//     } else {
//       name = order.firstname;
//       if (order.lastname) name += ' ' + order.firstname;
//     }
//
//     if (name) {
//       addr += name;
//       addr += ", ";
//     }
//     if (order.address) {
//       addr += order.address;
//       addr += ", ";
//     }
//     if (order.city) {
//       addr += order.city;
//       addr += ", ";
//     }
//     if (order.province) {
//       addr += order.province;
//       addr += ", ";
//     }
//     if (order.postalcode) {
//       addr += order.postalcode;
//     }
//     if (order.countrycode) {
//       addr += order.countrycode;
//     }
//     if (addr[addr.length-1] === " ") addr = addr.slice(0, addr.length-2);
//     return addr;
//   },
//   // Table cell on display
//   // Cap max height, convert falsy value to ''
//   tableCellOnDisplay: (val, key, rowData) => {
//     if (val !== '0' && val !== 0) {
//       val = val ? val : "";
//     }
//
//     return (<div style={{maxHeight: "80px", overflow: "auto"}}>{val}</div>);
//   },
//   printBarcode: (content) => {
//     let base = window.location.origin;
//     if (base === "http://localhost:3000") base = 'https://sandboxdemo.boxzooka.com';
//
//     // let url = base + '/packingid/' + content;
//     let url = base + '/api/customer/v1/packingid/' + content;
//     let w = window.open(url);
//     if (!w) {
//       alert('To enable print, please allow pop up window');
//     }
//   },
//   printPage: (pageText, same_page_print) => {
//     let w = window.open();
//     // let w = window.open('', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
//     if (!w) {
//       alert("To enable print, please allow pop up window");
//       return;
//     }
//     w.document.write(pageText);
//
//     // if (same_page_print) {
//     //     $('#iframe-print').remove();
//     //     $('body').append(
//     //         `<iframe src="" style='display: none' id="iframe-print"></iframe>`
//     //     );
//     //     document.getElementById('iframe-print').contentWindow.document.write(pageText);
//     //
//     // } else {
//     //     let w = window.open();
//     //     // let w = window.open('', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
//     //     if (!w) {
//     //         alert("To enable print, please allow pop up window");
//     //         return;
//     //     }
//     //     w.document.write(pageText);
//     // }
//   },
//   // wrap incoming text in html, apply the style comes in, and auto print it
//   printText: (printText, style, autoPrint) => {
//     if (style === undefined) style = "";
//     var w = window.open();
//     // style = "body {margin: 0;padding: 0;text-align: center} img {transform:rotate(90deg);position: fixed;top: 3.2cm;left: -2.9cm;width: 16cm;height: 9.9cm;}";
//     var pageText = "<html><head><title></title><style>" + style + "</style></head><body>";
//     pageText += printText;
//     pageText += "</body>";
//     w.document.write(pageText);
//     if (autoPrint) {
//       w.document.write("<scr" + "ipt>");
//       w.document.write("function printPage(){window.print();window.close();}");
//       w.document.write("setTimeout(printPage, 500)");
//       w.document.write("</scr" + "ipt>");
//     }
//   },
//   // scroll to top
//   scrollToTop: ()=>{
//     window.location = '#';
//     document.getElementById('page-content-container').scrollTop = 0;
//   },
//   // export to csv
//   // todo, this can only export current table page. need to fix when it has multiple pages and filtered
//   export_table_to_csv: (table_id, file_name, header_only)=>{
//     let csv = [];
//     // default table id is '#table-export'
//     // current not allowed to directly pass in the table text to be exported
//     if (!table_id) table_id = 'table-export';
//     // set default file name
//     if (!file_name) file_name = 'Exported_File';
//
//     // if given file name doesn't contain extension .csv append
//     if (file_name.indexOf('.csv') === -1) {
//       file_name += '.csv';
//     }
//
//     let rows = document.querySelectorAll("table#" + table_id + " tr");
// //        var rows = document.querySelectorAll("table tr");
//     for (let i = 0; i < rows.length; i++) {
//       let selector = "td, th";
//       if (header_only) selector = 'th';
//       let row = [], cols = rows[i].querySelectorAll(selector);
//       for (let j = 0; j < cols.length; j++){
//         row.push(cols[j].innerText.replace('\n', '').replace(',', '').trim());
//       }
//       csv.push(row.join(","));
//     }
//     // Download CSV
//     // this.download_csv(csv.join("\n"), filename);
//     csv = csv.join("\n");
//
//     // console.log(csv);
//
//     let csvFile;
//     let downloadLink;
//     // CSV FILE
//     csvFile = new Blob([csv], {type: "text/csv"});
//     // Download link
//     downloadLink = document.createElement("a");
//     // File name
//     downloadLink.download = file_name;
//     // We have to create a link to the file
//     downloadLink.href = window.URL.createObjectURL(csvFile);
//     // Make sure that the link is not displayed
//     downloadLink.style.display = "none";
//     // Add the link to your DOM
//     document.body.appendChild(downloadLink);
//     // Lanzamos
//     downloadLink.click();
//   },
//   export_array_to_csv: (array, headers, file_name)=>{
//     // each array element should be an object
//     // each object should be a simple object, that means each property value should be a string/number
//     // the headers is the property list, need to follow the order of header to access the object value
//     let csv = [];
//     // set default file name
//     if (!file_name) file_name = 'Exported_File';
//
//     // if given file name doesn't contain extension .csv append
//     if (file_name.indexOf('.csv') === -1) {
//       file_name += '.csv';
//     }
//
//     if (!array || !headers || headers.length === 0) {
//       csv = [];
//     } else {
//       csv.push(headers.map(elem=>{return elem.label}).join(','));
//       for (let elem of array) {
//         let text_array = [];
//         for (let header of headers) {
//           // let val = (''+elem[header.key]).replace('\n', '').replace(',', ' ').trim();
//           let val = elem[header.key];
//           if (header.onDisplay) val = header.onDisplay(val, header.key, elem);
//           else if (header.render) val = header.render(val, header.key, elem);
//           val = (''+val).replace('\n', '').replace(/,/g, '').trim();
//           text_array.push(val);
//         }
//         csv.push(text_array.join(','));
//       }
//     }
//     // Download CSV
//     // this.download_csv(csv.join("\n"), filename);
//     csv = csv.join("\n");
//
//     // console.log(csv);
//
//     let csvFile;
//     let downloadLink;
//     // CSV FILE
//     csvFile = new Blob([csv], {type: "text/csv"});
//     // Download link
//     downloadLink = document.createElement("a");
//     // File name
//     downloadLink.download = file_name;
//     // We have to create a link to the file
//     downloadLink.href = window.URL.createObjectURL(csvFile);
//     // Make sure that the link is not displayed
//     downloadLink.style.display = "none";
//     // Add the link to your DOM
//     document.body.appendChild(downloadLink);
//     // Lanzamos
//     downloadLink.click();
//   },
//   getGeneralStyles: (theme)=>({
//     root: {
//       display: 'flex',
//       flexWrap: 'wrap',
//     },
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//     },
//     selectInput: {
//       marginTop: theme.spacing(1),
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//     table: {
//       minWidth: 700,
//     },
//     tableWrapper: {
//       overflowX: 'auto',
//     },
//     customInput: {
//       margin: theme.spacing(1, 0),
//     },
//     button: {
//       marginTop: theme.spacing(1)
//     },
//     btnGray: {
//       marginTop: theme.spacing(1),
//       backgroundColor: "#999999",
//       color: "white",
//       "&:hover": {
//         backgroundColor: "#999999",
//         color: "white",
//       }
//     },
//     btnGreen: {
//       marginTop: theme.spacing(1),
//       backgroundColor: "#4caf50",
//       color: "white",
//       "&:hover": {
//         backgroundColor: "#4caf50",
//         color: "white",
//       }
//     },
//     btnLightBlue: {
//       marginTop: theme.spacing(1),
//       backgroundColor: "#00e5ff",
//       color: "white",
//       "&:hover": {
//         backgroundColor: "#00e5ff",
//         color: "white",
//       }
//     }
//   }),
//   //
//   //
//   // Useful Objects or Variables
// };

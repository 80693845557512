import React from "react";
import { Grow, TextField, IconButton, withStyles } from "@material-ui/core";
import {
    Search,
    Clear
} from "@material-ui/icons";

const defaultSearchStyles = {
    main: {
        display: "flex",
        alignItems: "center",
        flex: "1 0 auto",
    },
    searchIcon: {
        marginTop: "10px",
        marginRight: "8px",
    },
    searchText: {
        flex: "0.8 0",
    },
    clearIcon: {
        "&:hover": {
            color: "#FF0000",
        },
    },
};

class TableSearch extends React.Component {
    handleTextChange = event => {
        const { onSearchChange } = this.props;

        if (onSearchChange) {
            onSearchChange(event.target.value);
        }

        this.props.onSearch(event.target.value);
    };

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    onKeyDown = event => {
        if (event.keyCode === 27) {
            this.props.onHide();
        }
    };

    render() {
        const { classes, onHide, searchText } = this.props;

        return (
            <Grow appear in={true} timeout={300}>
                <div className={classes.main} ref={el => (this.rootRef = el)}>
                    <Search className={classes.searchIcon} />
                    <TextField
                        className={classes.searchText}
                        autoFocus={true}
                        onChange={this.handleTextChange}
                        value={searchText}
                        fullWidth={true}
                        inputRef={el => (this.searchField = el)}
                    />
                    <IconButton className={classes.clearIcon} onClick={onHide}>
                        <Clear />
                    </IconButton>
                </div>
            </Grow>
        );
    }
}

export default withStyles(defaultSearchStyles, { name: "TableSearch" })(TableSearch);

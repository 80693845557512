// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import $ from 'jquery'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Paper,
    Grid,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import { withAlert } from 'hoc'
import moment from "moment-timezone";
// Our view components

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
   // custom style
})};

class ImportForecast extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };
    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            errors: [],
        };

        document.title = "Import Forecast";
    }

    render() {
        const { classes } = this.props;
        const { loading, errors } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let errorsTable = null;
        if (errors && errors.length > 0) {
            let rows = errors.map((error, index)=>{
                return (
                    <TableRow hover key={index}><TableCell>
                        {error}
                    </TableCell></TableRow>
                );
            });
            errorsTable = (
                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{height: '3rem'}}>
                                <Typography variant="h4">
                                    Result In the File You Uploaded:
                                </Typography>
                            </div>

                            <Table className='with-border'>
                                <TableBody>
                                    {rows}
                                </TableBody>
                            </Table>

                        </div>
                    </CardContainer>
                </GridItem>
            );
        }

        return (
          <Grid container spacing={3}>
                {loadingBar}

                {errorsTable}

                <GridItem xs={12}>
                    <CardContainer>
                        <div className={classes.title}>
                            <Typography variant="h4">
                                Forecast Format Instruction:
                            </Typography>
                        </div>

                        <div style={{fontSize: "1.25rem", marginTop: ".5rem"}}> The yellow highlight field is required. All these forecast numbers should be integers greater than or equal to 0. The forecast should be done at least one week ahead. Any date_forecast within one week will Not be allowed.</div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div className={classes.title} style={{height: '3rem'}}>
                            <Typography variant="h4">
                                Sample Table:

                                <Button style={{float: 'right'}} onClick={()=>{
                                    let file_name = "Forecast_SAMPLE";

                                    let customer = localStorage.getItem('customer_id');
                                    let warehouse = localStorage.getItem('warehouse_id');

                                    file_name += '_' + warehouse + '_' + customer + '_';

                                    let now = moment();
                                    file_name += now.format('YYYY_MM_DD');
                                    utils.export_table_to_csv('table-export', file_name, true);
                                }}>Export to CSV</Button>
                            </Typography>
                        </div>

                        {this.renderSampleTable()}
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title}>
                                <Typography variant="h4">
                                    Forecast Upload Instruction:
                                </Typography>
                            </div>
                            <div>
                                <form
                                  // style={{width: 'calc(100% + 16px)', margin: '-8px'}}
                                  onSubmit={this.submit}
                                  className={classes.root}
                                  ref={(elem)=>{this.input_form = elem}}
                                >
                                    <div style={{fontSize: "1.25rem", margin: ".5rem 0"}}>Use the file input below to upload the Forecast file you created and click "Upload Forecast" button to upload it. Error messages will appear on the screen if there are errors in the file you uploaded</div>
                                    <CustomInput
                                      labelText='Upload Forecast'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          type: 'file',
                                          name: 'forecast',
                                          inputRef: (elem)=>{this.file_input = elem}
                                      }}
                                    />
                                    <Button className={classes.button} type='submit'>Upload Forecast</Button>
                                </form>
                            </div>
                        </div>
                    </CardContainer>
                </GridItem>

            </Grid>
        );
    }

    // api call
    submit = (e) => {
        e.preventDefault();

        // Check customer id and local storage customer id
        if (parseInt(this.context.data.getCustomer()) !== parseInt(localStorage.getItem('customer_id'))) {
            this.props.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
            return;
        }

        let customer_name = localStorage.getItem('customer_name');
        let warehouse_name = localStorage.getItem('warehouse_name');

        // todo decide whether to add confirmation
        // let msg = "Are you sure to import Forecast for Warehouse: "+ warehouse_name +", Customer: "+ customer_name +"?";
        // let cfm = window.confirm(msg);
        // if (!cfm) return;

        let form_data = new FormData(this.input_form);

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/import/forecast';

        let req = axios({
            method: 'post',
            dataType: 'json',
            processData: false,
            contentType: false,
            cache: false,
            data: form_data,
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true, errors: []});
        req.then(this.submitSuccess).catch(this.submitError);
    }
    submitSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;

        if (resp.Error) {
            // display error table and scroll to top
            this.setState({errors: resp.Error});
            utils.scrollToTop();
            //alert(resp.Error);
            return;
        }
    }
    submitError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

    // render func
    renderSampleTable = () => {
        let colSettings = [
            {
                key: 'date_forecast',
                label: 'date_forecast',
                render: utils.tableCellOnDisplay,
                header_class: 'yellow'
            },
            {
                key: 'order_forecast',
                label: 'order_forecast',
                render: utils.tableCellOnDisplay,
                header_class: 'yellow'
            },
            {
                key: 'order_unit_forecast',
                label: 'order_unit_forecast',
                render: utils.tableCellOnDisplay,
                header_class: 'yellow'
            },
            {
                key: 'b2b_order_forecast',
                label: 'b2b_order_forecast',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'b2b_order_unit_forecast',
                label: 'b2b_order_unit_forecast',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'po_forecast',
                label: 'po_forecast',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'po_unit_forecast',
                label: 'po_unit_forecast',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'return_forecast',
                label: 'return_forecast',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'return_unit_forecast',
                label: 'return_unit_forecast',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'notes',
                label: 'notes',
                render: utils.tableCellOnDisplay,
            },
        ];

        let rows = [
            {
                date_forecast: '12/03/2019',
                order_forecast: '15',
                order_unit_forecast: '45',
                b2b_order_forecast: '5',
                b2b_order_unit_forecast: '125',
                po_forecast: '5',
                po_unit_forecast: '87',
                return_forecast: '1',
                return_unit_forecast: '3',
                notes: "",
            },
            {
                date_forecast: '12/04/2019',
                order_forecast: '18',
                order_unit_forecast: '64',
                b2b_order_forecast: '2',
                b2b_order_unit_forecast: '34',
                po_forecast: '2',
                po_unit_forecast: '31',
                return_forecast: '0',
                return_unit_forecast: '0',
                notes: "",
            },
            {
                date_forecast: '12/05/2019',
                order_forecast: '24',
                order_unit_forecast: '103',
                b2b_order_forecast: '10',
                b2b_order_unit_forecast: '265',
                po_forecast: '0',
                po_unit_forecast: '0',
                return_forecast: '0',
                return_unit_forecast: '0',
                notes: "",
            },
            {
                date_forecast: '12/06/2019',
                order_forecast: '16',
                order_unit_forecast: '38',
                b2b_order_forecast: '1',
                b2b_order_unit_forecast: '23',
                po_forecast: '1',
                po_unit_forecast: '25',
                return_forecast: '2',
                return_unit_forecast: '7',
                notes: "",
            },
        ];

        return (
            <DataTable
                data={rows}
                withoutPaper
                tableSettings={{
                    tableProps: {
                        className: "with-border",
                        id: 'table-export'
                    }
                }}
                colSettings={colSettings}
            />
        );
    }
}
export default withAlert(withStyles(styles)(ImportForecast));

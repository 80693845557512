import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Button, BasicDialog } from 'components'
import {
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  FormControl,
} from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectInput: {
    width: '100%',
    margin: '0 0 .625rem 0',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: '1rem',
  },
});

class SwitchWarehouse extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    enableClose: PropTypes.bool,
  };

  static defaultProps = {
    enableClose: false
  }

  constructor(props, context) {
    super(props,context);

    let warehouse_detail = JSON.parse(localStorage.getItem('warehouse_detail'));
    let customer_detail = JSON.parse(localStorage.getItem('customer_detail'));
    let default_warehouse = Object.keys(warehouse_detail)[0];
    let warehouse_id = localStorage.getItem('warehouse_id') ? parseInt(localStorage.getItem('warehouse_id')) : default_warehouse;
    // default customer to Boxzooka if customer_detail is empty
    let default_customer = 114;
    if (customer_detail) {
      if (customer_detail[warehouse_id] && customer_detail[warehouse_id][0]) default_customer = customer_detail[warehouse_id][0]['customer_id'];
    }
    let customer_id = localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : default_customer;

    this.warehouse_detail = warehouse_detail;
    this.customer_detail = customer_detail;

    this.state = {
      warehouse: warehouse_id,
      customer: parseInt(customer_id)
    };
  }

  render() {
    const { classes, display, closeDialog, enableClose } = this.props;
    const { warehouse, customer } = this.state;

    let warehouse_detail = JSON.parse(localStorage.getItem('warehouse_detail'));
    let options = [];
    for (let w_id in warehouse_detail) {
      options.push(<MenuItem key={w_id} value={w_id}>{warehouse_detail[w_id]}</MenuItem>);
    }
    let customer_select = null;
    if (this.customer_detail) {
      customer_select = (
        <FormControl className={classes.formControl} disabled>
          <InputLabel>Customer</InputLabel>
          <Select
            value=''
          >
            <MenuItem value="">Select...</MenuItem>
          </Select>
        </FormControl>
      );
      if ( warehouse && this.customer_detail[warehouse]) {

        customer_select = (
          <FormControl className={classes.formControl}>
            <InputLabel>Customer</InputLabel>
            <Select
              value={customer}
              onChange={(e)=>{this.setState({customer: e.target.value})}}
            >
              <MenuItem value="">Select...</MenuItem>
              {this.customer_detail[warehouse].map((val)=><MenuItem key={val.customer_id} value={val.customer_id}>{val.shortname}</MenuItem>)}
            </Select>
          </FormControl>
        );
      }
    }

    let content = (
      <form className={classes.root}>
        <FormControl className={classes.formControl} required>
          <InputLabel shrink>Warehouse</InputLabel>
          <Select
            value={warehouse}
            onChange={(e)=>{
              if (e.target.value !== this.state.warehouse) this.setState({warehouse: e.target.value, customer: ''});
              else this.setState({warehouse: e.target.value})
            }}
          >
            <MenuItem value="">Select...</MenuItem>
            {options}
          </Select>
        </FormControl>
        {customer_select}
      </form>
    );

    let actions = [];
    if (enableClose) {
      actions.push(<Button key='default' onClick={closeDialog}>Cancel</Button>);
    }
    actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

    return (
      <BasicDialog
        disableBackdropClick={!enableClose}
        disableEscapeKeyDown={!enableClose}
        open={display}
        onClose={closeDialog}
        header={'Please Select Warehouse'}
        content={content}
        actions={actions}
      />
    );
  }

  handleSubmit = () => {
    const { warehouse, customer } = this.state;

    let err = "";
    if (!warehouse) {
      err += "Please Select warehouse! \n";
    }
    // only check customer if selecting customers enabled
    if (this.customer_detail) {
      if (!customer) {
        err += "Please Select customer!";
      }
    }

    if (err) {
      alert(err);
      return;
    }

    // save the selected customer_name and warehouse_name
    let warehouse_detail = JSON.parse(localStorage.getItem('warehouse_detail'));
    let warehouse_name = warehouse_detail[warehouse];
    if (this.customer_detail && this.customer_detail[warehouse]) {
      let i = 0;
      let customer_list = this.customer_detail[warehouse];
      let customer_name = '';
      do {
        let cust = customer_list[i];
        if (cust.customer_id === parseInt(customer)) {
          // found the currently selected customer
          customer_name = cust.shortname;
        }
        i++;
      } while (i < customer_list.length && !customer_name);

      localStorage.setItem('customer_id', customer);
      localStorage.setItem('customer_name', customer_name);
    }

    this.props.closeDialog();

    // Save the selected customer and use the selected id to update dashboard
    localStorage.setItem('warehouse_id', warehouse);
    localStorage.setItem('warehouse_name', warehouse_name);

    window.location.reload();

    if (this.props.onSubmit) {
      this.props.onSubmit({warehouse: warehouse, customer: customer});
    }
  }
}

export default withStyles(styles)(SwitchWarehouse)

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import _ from 'lodash'
import moment from 'moment-timezone'
import $ from 'jquery';
import EditOrderDialog from 'views/Dialogs/EditOrderDialog'
import EditItemDialog from 'views/Dialogs/EditItemDialog'
import NewLabelDialog from 'views/Dialogs/NewLabelDialog'
import EditOrderListDialog from 'views/Dialogs/EditOrderListDialog'
import { Add } from '@material-ui/icons'
import { Checkbox, IconButton, FormControlLabel, Switch, Typography, Paper, Table, TableBody, TableCell, TableHead, TableRow, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
    BasicDialog,
    EnhancedTable,
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import { withAlert } from 'hoc'
import utils from 'utils/utils'

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    // custom styles
    button: {
        marginTop: theme.spacing(2)
    },
})};

class ManageOrders extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.tracking_number_input = null;
        this.orderList = null;
        this.select_all_input = null;
        this.default_orders = false;
        this.filter_type = "default_orders";
        this.filter_keyword = "none";

        this.state = {
            selected_all: false,
            selected_order: {},
            comment_input: "",
            loading: false,
            date_from: '',
            date_to: '',
            dialog: '',
            orders: null,
            order: null,
            keyword: '',
            type: 'order',
            displayType: '',
            editItem: false,
            display_filters: {
                received: true,
                processing: true,
                onhold: true,
                shipped: true,
                cancelled: true,
                returned: true,
            },
            display_filter: 'None',
        };

        document.title = "Manage Order";
    }

    render() {
        const { classes } = this.props;
        const { loading, keyword, type, order, displayType, date_from, date_to } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let searchResult = this.renderResult();
        let orderControl = null;

        if (order && displayType === 'single') {
            let btnEdit = null;
            let btnReturn = null;
            let btnPackingSlip = null;
            let order_status = parseInt(order.order.status_id);
            switch (order_status) {
                case 9:
                case 13:
                    // if order shipped or returned, show get return label button
                    btnReturn = (
                      <Button style={{marginRight: '2rem'}} className={classes.button + " " +classes.btnGreen} onClick={()=>{this.getReturnLabel("NO")}}>Get Return Label</Button>
                    );
                    break;
                default:
                    btnEdit = (
                      <Button style={{marginRight: '2rem'}} className={classes.button} onClick={()=>{this.setState({dialog: 'edit_order'})}}>Edit Order</Button>
                    );
                    break;
            }
            // Always show edit button
            // btnEdit = (
            //   <Button style={{marginRight: '2rem'}} className={classes.button} onClick={()=>{this.setState({dialog: 'edit_order'})}}>Edit Order</Button>
            // );

            if (order_status === 7 || order_status === 8 || order_status === 9 || order_status === 12 || order_status === 13) {
                btnPackingSlip = (
                    <Button  color="primary" className={classes.button} style={{marginRight: '2rem'}} onClick={this.getPackingSlip}>PackingSlip</Button>
                );
            }

            let btnInvoice = null;
            if (order.shipment) {
                btnInvoice = (
                    <Button className={classes.button + " " + classes.btnLightBlue} onClick={()=>{
                        // popup window with url
                       this.getInvoice();
                    }}>Invoice</Button>
                );
            }

            orderControl = (
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{marginLeft: '-15px'}}>
                        {btnEdit}
                        {btnReturn}
                        {btnPackingSlip}
                        {btnInvoice}
                    </div>
                </GridItem>
            );
        }

        let dialog = this.renderDialog();

        return (
          <Grid container spacing={3}>
                {loadingBar}

                <GridItem xs={12} sm={6} md={6}>
                    <CardContainer>
                        <form onSubmit={this.searchOrder}>
                            <FormControl required fullWidth className={classes.selectInput}>
                                <InputLabel shrink htmlFor="search_type">Search Type</InputLabel>
                                <Select
                                  value={type}
                                  onChange={(e)=>{this.setState({type: e.target.value})}}
                                  inputProps={{
                                      name: 'search_type',
                                      id: 'search_type',
                                  }}
                                >
                                    <MenuItem value='order'>Order</MenuItem>
                                    <MenuItem value='barcode'>Barcode</MenuItem>
                                    <MenuItem value='tracking'>Tracking</MenuItem>
                                    {/*<MenuItem value='email'>Email</MenuItem>*/}
                                    <MenuItem value='all'>Other</MenuItem>
                                    {/*<MenuItem value='sku'>SKU</MenuItem>*/}
                                </Select>
                            </FormControl>

                            <CustomInput
                              labelText='Search Keyword'
                              labelProps={{shrink: true}}
                              formControlProps={{
                                  fullWidth: true,
                                  required: true,
                                  className: this.props.classes.customInput
                              }}
                              inputProps={{
                                  onChange: (e)=>{this.setState({keyword: e.target.value})},
                                  value: keyword,
                                  placeholder: 'Use Linebreak to separate multiple keywords',
                                  multiline: true,
                                  rows: 3
                              }}
                            />

                            <Button type='submit'>Search</Button>

                            {orderControl}
                        </form>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                    <CardContainer>
                        <form onSubmit={this.searchByDate}>
                            <CustomInput
                              labelText='Date From'
                              formControlProps={{
                                  fullWidth: true,
                                  required: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  type: 'date',
                                  value: date_from,
                                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                              }}
                            />

                            <CustomInput
                              labelText='Date To'
                              formControlProps={{
                                  fullWidth: true,
                                  required: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  type: 'date',
                                  value: date_to,
                                  onChange: (e)=>{this.setState({date_to: e.target.value})}
                              }}
                            />

                            <Button type='submit'>Search</Button>
                        </form>
                    </CardContainer>
                </GridItem>

                {dialog}

                {searchResult}
            </Grid>
        );
    }

    componentDidMount() {
        let order_id = localStorage.getItem("dbOrder");
        if (!order_id) {
            this.loadDefaultOrders();
        } else {
            this.loadDBOrder();
        }
    }

    // api call
        // user may come from dashboard, load order detail with given order_id
    loadDBOrder = () => {
        let order_id = localStorage.getItem("dbOrder");
        if (!order_id) return;

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/manageorder';

        // console.log(API_URL);

        let req = axios({
            method: 'post',
            url: API_URL,
            data: {
                type: "barcode",
                data: order_id
            },
            headers: headers
        });

        this.setState({loading: true, orders: null, order: null, displayType: ''});
        localStorage.removeItem("dbOrder");
        req.then(this.loadDBOrderSuccess).catch(this.loadDBOrderError);
    }
    loadDBOrderSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
             this.props.alert(resp.data.Error);
            return;
        }

        let result = resp.data;
        let type = result.type;
        // console.log(type);
        if (!result || result.length === 0) {
             this.props.alert("Order not found");
            return;
        }

        localStorage.removeItem("dbOrder");
        switch (type) {
            case "single":
                let shipment  = result.data.shipment;
                let tracking = result.data.tracking;

                if (Array.isArray(shipment) && shipment.length === 0) shipment = null;
                if (Array.isArray(tracking) && tracking.length === 0) tracking = null;

                result.data.shipment = shipment;
                result.data.tracking = tracking;

                if (utils.convertShippingMethod(result.data.order.method) === 'Method not matched') {
                    result.data.order.method = "";
                }

                this.setState({order: result.data, displayType: type});
                break;
            case "list":
                // remove order list from state, avoid re-rendering when other state value changes
                this.orderList = result.data;
                this.setState({orders: result.data, displayType: type});
                break;
        }
    }
    loadDBOrderError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // load default orders
    loadDefaultOrders = () => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/order/recent';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadDefaultOrdersSuccess).catch(this.loadDefaultOrdersError);
    }
    loadDefaultOrdersSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.Error) {
             this.props.alert(resp.Error);
            return;
        }
        let result = resp.data;
        let type = result.type;

        if (!result || result.length === 0) {
             this.props.alert("Order not found");
            return;
        }

        switch (type) {
            case "single":
                let shipment  = result.data.shipment;
                let tracking = result.data.tracking;

                if (Array.isArray(shipment) && shipment.length === 0) shipment = null;
                if (Array.isArray(tracking) && tracking.length === 0) tracking = null;

                result.data.shipment = shipment;
                result.data.tracking = tracking;

                if (utils.convertShippingMethod(result.data.order.method) === 'Method not matched') {
                    result.data.order.method = "";
                }

                this.setState({order: result.data, displayType: type});
                break;
            case "list":
                // remove order list from state, avoid re-rendering when other state value changes
                this.orderList = result.data;
                this.setState({orders: result.data, displayType: type, display_filter: 'None'});
                break;
        }
        this.filter_type = "default_orders";
        this.filter_keyword = "none";
        this.default_orders = true;
    }
    loadDefaultOrdersError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // search order by id, tracking# or barcode
    searchOrder = (e) => {
        e.preventDefault();
        const { type, keyword } = this.state;

        // replace whitespace
        let keywordFormat = utils.formatString(keyword, true, true);
        // console.log(keywordFormat);
        if (keywordFormat === '') {
             this.props.alert("Please check the format of search keyword");
            return;
        }

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/manageorder';

        let req = axios({
            method: 'post',
            data: {
                type,
                data: keywordFormat,
            },
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true, orders: null, order: null, displayType: ''});
        req.then(this.searchOrderSuccess.bind(this, { type,keyword: keywordFormat })).catch(this.searchOrderError);
    }
    searchOrderSuccess = (filter, resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
             this.props.alert(resp.data.Error);

            // clear wrong input
            this.setState({keyword: ""});
            return;
        }

        let result = resp.data;
        let type = result.type;

        if (!result || result.length === 0) {
             this.props.alert("Order not found");
            return;
        }

        switch (type) {
            case "single":
                let shipment  = result.data.shipment;
                let tracking = result.data.tracking;

                if (Array.isArray(shipment) && shipment.length === 0) shipment = null;
                if (Array.isArray(tracking) && tracking.length === 0) tracking = null;

                result.data.shipment = shipment;
                result.data.tracking = tracking;
                result.data.comment = result.data.comment ? result.data.comment : [];

                if (utils.convertShippingMethod(result.data.order.method) === 'Method not matched') {
                    result.data.order.method = "";
                }

                this.setState({order: result.data, displayType: type});
                break;
            case "list":
                // remove order list from state, avoid re-rendering when other state value changes
                this.orderList = result.data;
                this.setState({orders: result.data, displayType: type, display_filter: 'None'});
                break;
        }
        this.default_orders = false;
        this.filter_type = filter.type;
        this.filter_keyword = filter.keyword;
    }
    searchOrderError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // search by date
    searchByDate = (e) => {
        e.preventDefault();
        const { date_from, date_to } = this.state;

        let from = moment(date_from);
        let to = moment(date_to);
        if (to.isBefore(from)) {
            this.props.alert("Date To should be after Date From");
            // console.log("Date To should be after Date From");
            return;
        }
        from = from.format('YYYY-MM-DD');
        to = to.format('YYYY-MM-DD');

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : ''),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '')
        };
        const API_URL = base + '/api/customer/v1/manageorder';

        let req = axios({
            method: 'post',
            data: {
                type: 'date',
                data: from + '+' + to,
            },
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true, orders: null, order: null, displayType: ''});
        req.then(this.searchByDateSuccess).catch(this.searchByDateError);
    }
    searchByDateSuccess = (resp) => {
        this.setState({loading: false});
        let result = resp.data;
        //console.log(result);

        if (result.Error) {
             this.props.alert(result.Error);
            return;
        }
        if (!result || result.length === 0) {
             this.props.alert("Order not found");
            return;
        }
        let type = result.type;
        switch (type) {
            case "single":
                let shipment  = result.data.shipment;
                let tracking = result.data.tracking;

                if (Array.isArray(shipment) && shipment.length === 0) shipment = null;
                if (Array.isArray(tracking) && tracking.length === 0) tracking = null;

                result.data.shipment = shipment;
                result.data.tracking = tracking;
                result.data.comment = result.data.comment ? result.data.comment : [];

                if (utils.convertShippingMethod(result.data.order.method) === 'Method not matched') {
                    result.data.order.method = "";
                }

                this.setState({order: result.data, displayType: type});
                break;
            case "list":
                // remove order list from state, avoid re-rendering when other state value changes
                this.orderList = result.data;
                this.setState({orders: result.data, displayType: type, display_filter: 'None'});
                break;
        }
    }
    searchByDateError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // update order
    updateOrder = (order) => {
        // check required field
        let err = "";
        if (!order.method) err += "Shipping method is required. \n";
        err += utils.validateRequiredInput(order.firstname, "First Name");
        err += utils.validateRequiredInput(order.lastname, "Last Name");
        err += utils.validateRequiredInput(order.addressline1, "Address line 1");
        err += utils.validateRequiredInput(order.city, "City");
        err += utils.validateRequiredInput(order.countrycode, "Country");
        if (order.email) {
            let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            if (!email_valid.test(order.email)) err += "Email address is invalid. \n";
        }
        if (err) {
            this.props.alert(err);
            return;
        }

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/order';

        let newOrder = Object.assign({}, order);

        let req = axios({
            method: 'put',
            data: newOrder,
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.updateOrderSuccess.bind(this, newOrder)).catch(this.updateOrderError);
    }
    updateOrderSuccess = (newOrder, resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);

            // clear wrong input
            this.setState({keyword: ""});
            return;
        }

        let result = resp.data;
        //console.log(result);

        if (result) {
            let newOrderInfo = Object.assign({}, this.state.order);
            newOrderInfo.order = newOrder;
            // close dialog
            this.setState({dialog: '', order: newOrderInfo});
        } else {
            this.props.alert("Edit order error");
        }

        // do we need to update order list info?


    }
    updateOrderError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
    getReturnLabel = (type) => {
        type = type === "YES" ? "YES" : "NO";
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };

        let customer = (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25');
        let order_id = this.state.order.order.order_id;
        const API_URL = base + '/api/customer/v1/returnLabel/'+ customer +'/'+ order_id + "/" + type;

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.getReturnLabelSuccess.bind(this, type)).catch(this.getReturnLabelError);
    }
    getReturnLabelSuccess = (type, resp) => {
        this.setState({loading: false});
        let data = resp.data;

        if (data.Error) {
            this.props.alert(data.Error);
            return;
        }
        // console.log(type);
        let return_label_dialog_description = "Please send this link to the customer: ";
        if (type === "YES") return_label_dialog_description = "This is the your new return label, please send this link to the customer: ";
        this.setState({dialog: 'get_return_label', return_label_dialog_description: return_label_dialog_description, return_label_link: data});
    }
    getReturnLabelError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // change order status
    changeStatus = (e) => {
        let newStat = parseInt(e.target.value);
        let oldStat = parseInt(this.state.order.order.status_id);

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/order/' + this.state.order.order.order_id;

        let req = axios({
            method: 'put',
            url: API_URL,
            data: {
                previous_status: ''+oldStat,
                new_status: ''+newStat,
            },
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.changeStatusSuccess.bind(this, newStat)).catch(this.changeStatusError);
    }
    changeStatusSuccess = (newStat, resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        //console.log(resp.data);

        if (resp.data) {
            let newOrder = Object.assign({}, this.state.order);
            newOrder.order.status_id = newStat;

            this.setState({order: newOrder });
        } else {
            this.props.alert("Change order status error");
        }
    }
    changeStatusError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // get single order when order row is clicked
    loadSingleOrder = (order, index) => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/manageorder';

        let req = axios({
            method: 'post',
            data: {
                type: 'barcode',
                data: order.order_id
            },
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true, order: null});
        req.then(this.loadSingleOrderSuccess).catch(this.loadSingleOrderError);
    }
    loadSingleOrderSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);

            // clear wrong input
            this.setState({keyword: ""});
            return;
        }

        let result = resp.data;
        let type = result.type;

        // scroll to top
        utils.scrollToTop();
        switch (type) {
            case "single":
                let shipment  = result.data.shipment;
                let tracking = result.data.tracking;
                let action_log = result.data.action_log;

                if (Array.isArray(shipment) && shipment.length === 0) shipment = null;
                if (Array.isArray(tracking) && tracking.length === 0) tracking = null;
                if (Array.isArray(action_log) && action_log.length === 0) action_log = null;

                result.data.action_log = action_log;
                result.data.shipment = shipment;
                result.data.tracking = tracking;
                result.data.comment = result.data.comment ? result.data.comment : [];

                this.setState({order: result.data, displayType: type});
                break;
            case "list":
                this.setState({orders: result.data, displayType: type});
                break;
        }
    }
    loadSingleOrderError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // update order items
    updateItems = (newItems) => {
        for (let item of newItems) {
            item.quantity = item.quantity_input;
        }

        const { order } = this.state.order;
        let order_id = order.order_id;

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/order/' + order_id;

        let req = axios({
            method: 'post',
            url: API_URL,
            data: {
                items: newItems,
                order_type: order.package_type
            },
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.updateItemsSuccess).catch(this.updateItemsError);
    }
    updateItemsSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        //console.log(resp.data);

        if (!resp.data) {
            this.props.alert("Update order items error");
            return;
        }

        let newItems = resp.data;
        let newOrder = Object.assign({}, this.state.order);
        newOrder.item = newItems;
        this.setState({order: newOrder, editItem: false});
    }
    updateItemsError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // cancel item
    cancelItem = (item_id, order_id) => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/order/' + order_id + '/' + item_id;

        // console.log(API_URL);

        let req = axios({
            method: 'delete',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.cancelItemSuccess.bind(this, item_id)).catch(this.cancelItemError);
    }
    cancelItemSuccess = (item_id, resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        let items = [];
        for (let item of this.state.order.item) {
            if (item.item_id === item_id) {
                item.status_id = '6';
            }
            items.push(item);
        }

        let newOrder = Object.assign({}, this.state.order);

        newOrder.item = items;

        this.setState({order: newOrder });
    }
    cancelItemError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // get packing sliip
    getPackingSlip = () => {
        const { order } = this.state.order;
        let order_id = order.order_id;

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : ''),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '')
        };
        // Todo Calling Sandbox WMS now. Need to update to Live WMS later.
        const API_URL = base + '/api/customer/v1/packsingslip/' + order_id;

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.getPackingSlipSuccess).catch(this.getPackingSlipError);
    }
    getPackingSlipSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.props.alert(resp.Error);
            return;
        }

        if (!resp) {
            this.props.alert("Get packing slip error");
            return;
        }
        utils.printPage(resp);
    }
    getPackingSlipError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // get commercial invoice
    getInvoice = () => {
        const { order } = this.state.order;
        let order_id = order.order_id;

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        // Todo Calling Sandbox WMS now. Need to update to Live WMS later.
        // switch (base) {
        //     case 'https://customer.boxzooka.com':
        //         base = 'https://sandboxtest.boxzooka.com';
        //         break;
        //     case 'https://developer.boxzooka.com':
        //         base = 'https://sandboxtest.boxzooka.com';
        //         break;
        //     case 'https://client.boxzooka.com':
        //         base = 'https://wms.boxzooka.com';
        //         break;
        //     case 'https://imedia.boxzooka.com':
        //         base = 'https://shophq.boxzooka.com';
        //         break;
        //     default:
        //         base = 'https://sandboxtest.boxzooka.com';
        // }
        // const API_URL = base + '/commercialinvoice/' + order_id;
        const API_URL = base + '/api/customer/v1/commercialinvoice/' + order_id;

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.getInvoiceSuccess).catch(this.getInvoiceError);
    }
    getInvoiceSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.props.alert(resp.Error);
            return;
        }

        if (!resp) {
            this.props.alert("Get invoice error");
            return;
        }
        utils.printPage(resp);
    }
    getInvoiceError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // Add order comment
    addComment = () => {
        const { comment_input, order } = this.state;
        let order_id = order.order.order_id;
        if (!comment_input) {
            this.props.alert("Comment input is empty!");
            return;
        }
        this.addCommentOnce({
            target_id: order_id,
            type: "order",
            comment: comment_input
        });
    }
    addCommentAjax = (data) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/comment';
        let req = axios({
            method: 'POST',
            data: data,
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.addCommentSuccess.bind(this, data)).catch(this.addCommentError);
    }
    addCommentOnce = _.once(this.addCommentAjax);
    addCommentSuccess = (data, resp) => {
        this.setState({loading: false});
        this.addCommentOnce = _.once(this.addCommentAjax);
        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        let result = resp.data;
        if (result) {
            let comments = this.state.order.comment;
            let new_comment = {
                comment: data.comment,
                date: moment.utc(),
                username: localStorage.getItem("username"),
                fullname: "",
            };
            comments.push(new_comment);
            this.setState({comment_input: ""});
        } else {
            this.props.alert("Add order comment error!");
        }

        // do we need to update order list info?
    }
    addCommentError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // cancel multiple orders
    cancelOrders = (id_list) => {
        this.cancelOrdersOnce({
            order_id: id_list,
        });
    }
    cancelOrdersAjax = (data) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/cancelorderlist';
        let req = axios({
            method: 'post',
            url: API_URL,
            data: data,
            headers: headers
        });
        this.setState({loading: true});
        req.then(this.cancelOrdersSuccess.bind(this, {order_id: this.state.selected_order})).catch(this.cancelOrdersError);
    }
    cancelOrdersOnce = _.once(this.cancelOrdersAjax)
    cancelOrdersSuccess = (request_data, resp) => {
        this.setState({loading: false});
        this.cancelOrdersOnce = _.once(this.cancelOrdersAjax);
        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        let result = resp.data;
        if (!result) {
            this.props.alert("Cancel order error");
            return;
        }

        this.props.alert("Cancel orders success!");

        let selected_order = request_data.order_id;

        // When updated success, need to update Order Detail
        let new_order_list = Array.from(this.state.orders);
        if (!new_order_list) new_order_list = [];
        for (let order of new_order_list) {
            if (!selected_order[(""+order.order_id)]) continue;
            order.status_id = 6;
        }
        this.setState({orders: new_order_list, dialog: ''});
    }
    cancelOrdersError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // Update multiple orders
    updateMultipleOrders = (newOrderDetail) => {
        let id_list = [];
        for (let order_id in this.state.selected_order) {
            if (this.state.selected_order[order_id]) id_list.push(order_id);
        }

        if (id_list.length === 0) {
            this.props.alert("No order selected!");
            return;
        }

        this.updateMultipleOrdersOnce({
            order_id: id_list,
            edit_message: newOrderDetail
        });
    }
    updateMultipleOrdersAjax = (data) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/editorderlist';
        let req = axios({
            method: 'post',
            url: API_URL,
            data: data,
            headers: headers
        });
        this.setState({loading: true});
        req.then(this.updateMultipleOrdersSuccess.bind(this, {order_id: this.state.selected_order, edit_message: data.edit_message})).catch(this.updateMultipleOrdersError);
    }
    updateMultipleOrdersOnce = _.once(this.updateMultipleOrdersAjax)
    updateMultipleOrdersSuccess = (request_data, resp) => {
        this.setState({loading: false});
        this.updateMultipleOrdersOnce = _.once(this.updateMultipleOrdersAjax);
        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        let result = resp.data;

        if (!result) {
            this.props.alert("Edit order error");
            return;
        }

        this.props.alert("Edit orders success!");

        let selected_order = request_data.order_id;
        let new_order_detail = request_data.edit_message;

        // When updated success, need to update Order Detail
        let new_order_list = Array.from(this.state.orders);
        if (!new_order_list) new_order_list = [];
        for (let order of new_order_list) {
            if (!selected_order[(""+order.order_id)]) continue;
            order.method =  new_order_detail.method;
        }
        this.setState({orders: new_order_list, dialog: ''});
    }
    updateMultipleOrdersError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // Export Selected Order report
    // Call backend to export report for selected orders
    exportReport = () => {
        let id_list = [];
        // only selected
        // for (let order_id in this.state.selected_order) {
        //     if (this.state.selected_order[order_id]) {
        //        id_list.push(order_id);
        //     }
        // }
        let orders = this.orderList;
        for (let order of orders) {
            id_list.push(order.order_id);
        }

        if (id_list.length === 0) {
            alert("No order selected!");
            return;
        }

        let export_type = this.default_orders ? "orders_default" : "orders";

        let warning = "";
        let base = this.context.data.getBase();
        let download_url = base + '/api/customer/v1/report/' + localStorage.getItem('customer_id') + "/"+ export_type +"/";
        for (let i = 0; i < id_list.length; i++) {
            let order_id = id_list[i];
            let separate = ",";
            if (i === 0) separate = "";
            let new_url = download_url + separate + order_id;
            if (new_url.length > 1000) {
                warning += "Too many order selected, request url is too long, capped to 1000.";
                console.log(warning);
                break;
            }
            download_url = new_url;
        }

        // new api under test
        // let download_url = base + '/api/customer/v1/reportByFilter/' + localStorage.getItem('customer_id') + "/order/"+ this.filter_type +"/" + this.filter_keyword;

        let w = window.open(download_url);
        if (!w) {
            alert("To enable print, please allow pop up window");
            return;
        }
    }
        // update shipment
    updateShipment = (newShipment) => {
        // trigger update
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/ordershipment';

        //console.log(newShipment);

        let req = axios({
            method: 'put',
            data: {
                order_id: newShipment.order_id,
                carrier: newShipment.carrier,
                tracking: newShipment.tracking,
            },
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.updateShipmentSuccess.bind(this, newShipment)).catch(this.updateShipmentError);
    }
    updateShipmentSuccess = (newVal, resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.props.alert(resp.Error);
            return;
        }

        if (!resp) {
            this.props.alert("Update shipment error");
            return;
        }

        let newOrder = Object.assign({}, this.state.order);
        let newShipment = Object.assign({}, newOrder.shipment);
        newShipment.carrier = newVal.carrier;
        newShipment.tracking = newVal.tracking;
        newOrder.shipment = newShipment;
        this.setState({order: newOrder});
        //console.log(resp);
    }
    updateShipmentError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // create new shipping label
    createNewLabel = (shipping_info) => {
        let order_id = this.state.order.order.order_id;

        const { method, length, width, height, weight, saturday_delivery, signature_required } = shipping_info;
        let err = '';
        if (!weight) err += "Weight is required. \n";
        if (!length) err += "Length is required. \n";
        if (!width) err += "Width is required. \n";
        if (!height) err += "Height is required. \n";
        if (!weight) err += "Weight is required. \n";
        if (!weight) err += "Weight is required";
        if (err) {
            this.props.alert(err);
            return;
        }

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/newlabel';

        let req = axios({
            method: 'post',
            url: API_URL,
            headers: headers,
            data: {
                method,
                weight,
                length,
                width,
                height,
                saturday_delivery,
                signature_required,
                order_id,
            }
        });

        this.setState({loading: true});
        req.then(this.createNewLabelSuccess).catch(this.createNewLabelError);
    }
    createNewLabelSuccess = (resp) => {
        this.setState({loading: false});

        if (!resp.data) {
            this.props.alert("Internal server error");
            return;
        }
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        if (typeof resp.data === "string") {
            utils.printPage(resp.data);
        }
        this.setState({dialog: ''})
    }
    createNewLabelError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

    renderResult = () => {
        switch (this.state.displayType) {
            case "single":
                return this.renderSingle();
                break;
            case "list":
                return this.renderList();
                break;
        }
    }
    renderSingle = () => {
        const { classes } = this.props;

        if (!this.state.order) return null;
        // back to list ?

        let backToList = null;
        if (this.state.orders) {
            backToList = <Button style={{marginLeft: "15px"}} onClick={this.backToList}>Back to List</Button>;
        }

        let tables = [];
        let orderInfo = this.state.order;
        let order_id = orderInfo.order.order_id;

        if (orderInfo.order) {
            let hold_reason = '';
            if (parseInt(orderInfo.order.status_id) === 5) hold_reason = "Out of stock";

            let status_field = utils.convertStatus(orderInfo.order.status_id);
            switch (parseInt(orderInfo.order.status_id)) {
                case 2:
                case 5:
                case 6:
                    status_field = (
                        <FormControl required fullWidth className={classes.selectInput}>
                            <Select
                                value={(orderInfo.order.status_id ? (''+orderInfo.order.status_id) : '')}
                                onChange={(e)=>{
                                    let newStat = parseInt(e.target.value);
                                    let oldStat = parseInt(this.state.order.order.status_id);

                                    if (oldStat === newStat) return;
                                    if (oldStat === 2 && (newStat === 7 || newStat === 12)) {
                                        this.props.alert("Please batch the order");
                                        return;
                                    }

                                    this.props.confirm(
                                      "Do you want to change the order status to " + utils.convertStatus(newStat),
                                      this.changeStatus.bind(this, e)
                                    );
                                }}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}
                            >
                                <MenuItem value='2'>Received</MenuItem>
                                <MenuItem value='5'>On Hold</MenuItem>
                                <MenuItem value='6'>Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    );
                default:
                    break;
            }
            let boxed = '';
            switch (orderInfo.order.package_type) {
                case 'whole-sale':
                case 'edi':
                    boxed = orderInfo.order.split_id ? 'Carton' : 'Main';
                    break;
                default:
                    // boxed for retail order is '';
            }
            tables.push(
                <GridItem xs={12} sm={12} md={6} key={"order_detail"}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{paddingBottom: "1rem"}}>
                                <Typography variant="h4">
                                    Order Detail
                                </Typography>
                                <br/>
                                <div style={{fontSize: "1.25rem"}}>
                                    For "Boxed" Column: "Main" orders are just for reference and will not be shipped. "Carton" orders are those actually being shipped.
                                </div>
                            </div>

                            <Paper className={classes.root}>
                                <div className={classes.tableWrapper} style={{width: "100%"}}>
                                    <Table className='with-border'>
                                        <TableHead>
                                            <TableRow className='cls-table-header-text'>
                                                <TableCell>Property</TableCell>
                                                <TableCell>Value</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow hover>
                                                <TableCell>Order Id</TableCell>
                                                <TableCell>{orderInfo.order.order_key ? orderInfo.order.order_key : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Barcode</TableCell>
                                                <TableCell>{orderInfo.order.order_id ? orderInfo.order.order_id : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Package Id</TableCell>
                                                <TableCell>{orderInfo.order.package_id ? orderInfo.order.package_id : ''}</TableCell>
                                            </TableRow>
                                            {/*<TableRow hover>*/}
                                            {/*    <TableCell>Received Date</TableCell>*/}
                                            {/*    <TableCell>{orderInfo.order.created_dt ? utils.formatDate(orderInfo.order.created_dt) : ''}</TableCell>*/}
                                            {/*</TableRow>*/}
                                            <TableRow hover>
                                                <TableCell>Status</TableCell>
                                                <TableCell>
                                                    {status_field}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Hold Reason</TableCell>
                                                <TableCell>{hold_reason}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Value</TableCell>
                                                <TableCell>{orderInfo.order.value ? ('$' + orderInfo.order.value) : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Shipping Method</TableCell>
                                                <TableCell>{orderInfo.order.method ? utils.convertShippingMethod(orderInfo.order.method) : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Saturday Delivery</TableCell>
                                                <TableCell>{orderInfo.order.saturday_delivery ? utils.convertBooleanToString(orderInfo.order.saturday_delivery) : "NO"}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Signature Required</TableCell>
                                                <TableCell>{orderInfo.order.signature_required ? utils.convertBooleanToString(orderInfo.order.signature_required) : 'NO'}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Payment Term</TableCell>
                                                <TableCell>{orderInfo.order.payment_term ? orderInfo.order.payment_term : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Carrier Account</TableCell>
                                                <TableCell>{orderInfo.order.carrier_account ? orderInfo.order.carrier_account : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Start Date</TableCell>
                                                <TableCell>{orderInfo.order.start_date ? utils.formatDateTime(orderInfo.order.start_date, "YYYY-MM-DD") : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Cancel Date</TableCell>
                                                <TableCell>{orderInfo.order.cancel_date ? utils.formatDateTime(orderInfo.order.cancel_date, "YYYY-MM-DD") : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Ship Date</TableCell>
                                                <TableCell>{orderInfo.order.ship_date ? utils.formatDateTime(orderInfo.order.ship_date, "YYYY-MM-DD") : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Slip Note</TableCell>
                                                <TableCell>{orderInfo.order.slip_note ? orderInfo.order.slip_note : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Return Tracking</TableCell>
                                                <TableCell>{orderInfo.order.return_tracking ? orderInfo.order.return_tracking : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Package Type</TableCell>
                                                <TableCell>{orderInfo.order.package_type ? orderInfo.order.package_type : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Boxed</TableCell>
                                                <TableCell>{boxed}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Batch Id</TableCell>
                                                <TableCell>{orderInfo.order.batch_id ? orderInfo.order.batch_id : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Incoterms</TableCell>
                                                <TableCell>{orderInfo.order.incoterms ? orderInfo.order.incoterms : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Custom Description</TableCell>
                                                <TableCell>{orderInfo.order.custom_description ? orderInfo.order.custom_description : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Warehouse</TableCell>
                                                <TableCell>{orderInfo.order.warehouse ? orderInfo.order.warehouse : ''}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </div>
                    </CardContainer>
                </GridItem>
            );

            tables.push(
                <GridItem xs={12} sm={12} md={6} key={"address_detail"}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{paddingBottom: "1rem"}}>
                                <Typography variant="h4">
                                    Address
                                </Typography>
                            </div>

                            <Paper className={classes.root}>
                                <div className={classes.tableWrapper} style={{width: "100%"}}>
                                    <Table className='with-border'>
                                        <TableHead>
                                            <TableRow className='cls-table-header-text'>
                                                <TableCell>Property</TableCell>
                                                <TableCell>Value</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow hover>
                                                <TableCell>Recipient Name</TableCell>
                                                <TableCell>{(orderInfo.order.firstname + ' ' + orderInfo.order.lastname)}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Company</TableCell>
                                                <TableCell>{orderInfo.order.company ? orderInfo.order.company : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Address 1</TableCell>
                                                <TableCell>{orderInfo.order.addressline1 ? orderInfo.order.addressline1 : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Address 2</TableCell>
                                                <TableCell>{orderInfo.order.addressline2 ? orderInfo.order.addressline2 : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>City</TableCell>
                                                <TableCell>{orderInfo.order.city ? orderInfo.order.city : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Province/State</TableCell>
                                                <TableCell>{orderInfo.order.province ? orderInfo.order.province : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Postal Code</TableCell>
                                                <TableCell>{orderInfo.order.postalcode ? orderInfo.order.postalcode : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Country</TableCell>
                                                <TableCell>{orderInfo.order.countrycode ? orderInfo.order.countrycode : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Email</TableCell>
                                                <TableCell>{orderInfo.order.email ? orderInfo.order.email : ''}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell>Phone</TableCell>
                                                <TableCell>{orderInfo.order.phonenumber ? orderInfo.order.phonenumber : ''}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </div>
                    </CardContainer>
                </GridItem>
            );
        }

        if (orderInfo.item) {
            let itemColSettings = [
                {
                    key: 'sku',
                    label: 'SKU',
                    render: utils.tableCellOnDisplay,
                }, {
                    key: 'description',
                    label: 'Description',
                    render: utils.tableCellOnDisplay
                }, {
                    key: 'monogram',
                    label: 'Monogram',
                    render: utils.tableCellOnDisplay
                }, {
                    key: 'gift_notes',
                    label: 'Gift Notes',
                    render: utils.tableCellOnDisplay
                }, {
                    key: 'quantity',
                    label: 'Ordered Quantity',
                    render: utils.tableCellOnDisplay,
                },{
                    key: 'returned_quantity',
                    label: 'Returned Quantity',
                    render: utils.tableCellOnDisplay,
                },{
                    key: 'sale',
                    label: 'Value',
                    render: utils.tableCellOnDisplay,
                },{
                    key: 'status_id',
                    label: 'Status',
                    render: utils.convertStatus,
                },
                // {
                //     key: 'hold_reason',
                //     label: 'Onhold Reason',
                //     render: (val, key, row) => {
                //         if (row['status_id'] === 5) val = "Out of stock";
                //         if (!val) val = '';
                //         return val;
                //     },
                // },
                {
                    key: 'modified_dt',
                    label: 'Modified Date',
                    render: utils.formatDate
                },{
                    key: 'cancel',
                    label: 'Cancel',
                    render: (val, key, row) => {
                        val = '';

                        // if order status is not 2 or 5,6, can't cancel item
                        // if (parseInt(orderInfo.order.status_id) !== 9 && parseInt(orderInfo.order.status_id) !== 13) {
                        //     // if item status is 6, which means cancelled, we can't cancel it
                        //     if (parseInt(row['status_id']) !== 6) {
                        //         val = (<Button onClick={this.cancelItem.bind(this, row.item_id, orderInfo.order.order_id)}>Cancel</Button>);
                        //     }
                        // }
                        switch (parseInt(orderInfo.order.status_id)) {
                            case 2:
                            case 5:
                                // if order status is not 2 or 5,6, can't cancel item
                                // if item status is 6, which means cancelled, we can't cancel it
                                if (parseInt(row['status_id']) !== 6) {
                                    val = (<Button color="secondary" onClick={()=>{
                                        this.props.confirm("Are you sure you want to cancel this item?", this.cancelItem.bind(this, row.item_id, orderInfo.order.order_id));
                                    }}>Cancel</Button>);
                                }
                                break;
                        }
                        if (parseInt(orderInfo.order.status_id) === 9 && parseInt(orderInfo.order.status_id) !== 13) {

                        }
                        return val;
                    },
                }
            ];

            let btnEditItems = null;
            if (parseInt(orderInfo.order.status_id) === 2 || parseInt(orderInfo.order.status_id) === 5) {
                // can edit items only when order is on hold or received
                btnEditItems = (
                    <Button style={{martinLeft: "1rem"}} className={classes.button} onClick={()=>{this.setState({editItem: true})}}>Edit Items</Button>
                );
            }

            tables.push(
                <GridItem xs={12} sm={12} md={12} key={"item_detail"}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{paddingBottom: "1rem"}}>
                                <Typography variant="h4">
                                    Order Items
                                </Typography>
                                {btnEditItems}
                            </div>

                            <DataTable
                              data={orderInfo.item}
                              tableSettings={{
                                  tableProps: {
                                      className: "with-border"
                                  },
                                  containerStyle: {
                                      marginTop: "0"
                                  }
                              }}
                              colSettings={itemColSettings}
                            />
                        </div>
                    </CardContainer>
                </GridItem>
            );
        }

        let log_shipment = [];

        if (orderInfo.action_log) {
            let actionLogColSettings = [
                 {
                    key: 'name',
                    label: 'Name',
                    render: utils.tableCellOnDisplay,
                },
                {
                    key: 'action',
                    label: 'Action',
                    render: utils.tableCellOnDisplay,
                },
                {
                    key: 'date',
                    label: 'Date/Time',
                    render: utils.formatDate,
                }
            ];

            log_shipment.push(
              // Todo move this back when comments is removed
            // tables.push(
              //   <GridItem xs={12} sm={12} md={6} key="action_log">
                  <CardContainer key="action_log">
                      <div>
                          <div className={classes.title} style={{paddingBottom: "1rem"}}>
                              <Typography variant="h4">
                                  Action Log
                              </Typography>
                          </div>

                          <DataTable
                            data={orderInfo.action_log}
                            tableSettings={{
                                tableProps: {
                                    className: "with-border"
                                },
                                containerStyle: {
                                    marginTop: "0"
                                }
                            }}
                            colSettings={actionLogColSettings}
                          />
                      </div>
                  </CardContainer>
              // </GridItem>
            );
        }

        // Hide Comments Field
        // if (orderInfo.comment) {
        //     let commentColSettings = [
        //         {
        //             key: 'username',
        //             label: 'Username',
        //             render: utils.tableCellOnDisplay,
        //         },
        //         {
        //             key: 'fullname',
        //             label: 'Fullname',
        //             render: utils.tableCellOnDisplay,
        //         },
        //         {
        //             key: 'comment',
        //             label: 'Comment',
        //             render: (val, key, row) => {
        //                 if (row.add_new) {
        //                     return (
        //                       <div style={{width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        //                           <CustomInput
        //                             labelText=''
        //                             formControlProps={{
        //                                 fullWidth: false,
        //                                 className: classes.customInput
        //                             }}
        //                             labelProps={{
        //                                 shrink: false,
        //                             }}
        //                             inputProps={{
        //                                 style:{marginTop: '0'},
        //                                 onChange: (e)=>{this.setState({comment_input: e.target.value})},
        //                                 value: this.state.comment_input
        //                             }}
        //                           />
        //                           <IconButton style={{marginLeft: "1rem", backgroundColor: "#3f51b5"}} onClick={
        //                               ()=>{
        //                                   this.props.confirm(
        //                                     "Are you sure to add a new comment?",
        //                                     this.addComment
        //                                   );
        //                               }} variant="fab" aria-label="Add">
        //                               <Add style={{color: "white"}} />
        //                           </IconButton >
        //                       </div>
        //                     );
        //                 } else {
        //                     return val ? val : '';
        //                 }
        //             }
        //         },
        //         {
        //             key: 'date',
        //             label: 'Date/Time',
        //             render: utils.formatDate,
        //         }
        //     ];
        //     let comments_data = orderInfo.comment.concat([{
        //         username: localStorage.getItem('username'),
        //         add_new: true,
        //         comment: this.state.comment_input,
        //     }]);
        //
        //     tables.push(
        //       <GridItem xs={12} sm={12} md={6} key="comment">
        //           <CardContainer key='comment'>
        //               <div>
        //                   <div className={classes.title} style={{padding: "1rem"}}>
        //                       <Typography variant="h4">
        //                           Comment
        //                       </Typography>
        //                   </div>
        //
        //                   <DataTable
        //                     data={comments_data}
        //                     tableSettings={{
        //                         tableProps: {
        //                             className: "with-border"
        //                         },
        //                         containerStyle: {
        //                             marginTop: "0"
        //                         }
        //                     }}
        //                     colSettings={commentColSettings}
        //                   />
        //               </div>
        //           </CardContainer>
        //       </GridItem>
        //     );
        // }

        if (orderInfo.shipment) {
            let carrier_field = orderInfo.shipment.carrier ? orderInfo.shipment.carrier : '';
            let tracking_content = orderInfo.shipment.tracking ? orderInfo.shipment.tracking : '';

            let url = '';
            if (tracking_content) {
                // if has tracking number, try to track it
                let carrier_code = (orderInfo.shipment.carrier ? orderInfo.shipment.carrier : '').toUpperCase();
                switch (true) {
                    case carrier_code === 'DHLEXPRESS':
                        // DHL Express
                        // https://www.dhl.com/en/express/tracking.html?AWB=1818010596&brand=DHL
                        url = `https://www.dhl.com/en/express/tracking.html?AWB=${tracking_content}&brand=DHL`;
                        // url = `http://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber=${tracking_content}`;

                        break;
                    case carrier_code === 'DHLGLOBALMAIL':
                        // DHL global
                        url = `http://webtrack.dhlglobalmail.com/?trackingnumber=${tracking_content}`;
                        break;
                    case carrier_code === 'UPS':
                        url = `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${tracking_content}`;
                        break;
                    case carrier_code === 'FEDEX':
                        url = `https://www.fedex.com/apps/fedextrack/?action=track&action=track&tracknumbers=${tracking_content}`;
                        break;
                    case carrier_code === 'USPS':
                        url = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_content}`;
                        break;
                    default:
                    // do nothing to the content
                    // Don't track DHLSAMEDAY, because it is delivered by Uber
                    // Don't track Boxzooka
                }
            }

            if (url) {
                tracking_content = <Button onClick={()=>{
                    let w = window.open(url);
                    if (!w) {
                        this.props.alert('Please allow pop up window');
                    }
                }}>{tracking_content}</Button>;
            }

            let btn_new_label = <Button style={{marginBottom: "1rem"}} onClick={()=>{this.setState({dialog: 'new_label'})}}>New Label</Button>;
            if (orderInfo.order.status_id == 9) btn_new_label = null;

            log_shipment.push(
            // tables.push(
              // <GridItem xs={12} sm={12} md={6} key="shipment">
                  <CardContainer key="shipment">
                      <div>
                          <div className={classes.title} style={{paddingBottom: "1rem"}}>
                              <Typography variant="h4">
                                  Shipment Detail
                              </Typography>
                          </div>

                          {btn_new_label}

                          <Paper className={classes.root}>
                              <div className={classes.tableWrapper} style={{width: "100%"}}>
                                  <Table className='with-border'>
                                      <TableHead>
                                          <TableRow className='cls-table-header-text'>
                                              <TableCell>Property</TableCell>
                                              <TableCell>Value</TableCell>
                                          </TableRow>
                                      </TableHead>

                                      <TableBody>
                                          <TableRow>
                                              <TableCell>Carrier</TableCell>
                                              <TableCell>{carrier_field}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell>Tracking No.</TableCell>
                                              <TableCell>{tracking_content}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell>Shipped Date</TableCell>
                                              <TableCell>{orderInfo.shipment.created_dt ? utils.formatDate(orderInfo.shipment.created_dt) : ''}</TableCell>
                                          </TableRow>
                                      </TableBody>
                                  </Table>
                              </div>
                          </Paper>
                      </div>
                  </CardContainer>
              // </GridItem>
            );
        }
        // action log and shipment
        if (log_shipment.length > 0) {
            tables.push(
              <GridItem xs={12} sm={12} md={6} key={"log_shipment"}>
                  {log_shipment}
              </GridItem>
            );
        }

        // tracking
        if (orderInfo.tracking) {
            let trackingColSettings = [
                {
                    key: 'event_dt',
                    label: 'Date/Time',
                    render: utils.tableCellOnDisplay,
                }, {
                    key: 'eventdescription',
                    label: 'Status',
                    render: utils.tableCellOnDisplay,
                }, {
                    key: 'eventlocation',
                    label: 'Location',
                    render: utils.tableCellOnDisplay,
                }
            ];

            tables.push(
                <GridItem xs={12} sm={12} md={6} key={"tracking_detail"}>
                    <CardContainer><div>
                        <div className={classes.title} style={{paddingBottom: "1rem"}}>
                            <Typography variant="h4">
                                Tracking Detail
                            </Typography>
                        </div>

                        <DataTable
                          data={orderInfo.tracking}
                          tableSettings={{
                              tableProps: {
                                  className: "with-border"
                              },
                              containerStyle: {
                                  marginTop: "0"
                              }
                          }}
                          colSettings={trackingColSettings}
                        />
                    </div></CardContainer>
                </GridItem>
            );
        }

        return (
            <div className={classes.root} style={{width:"100%"}}>
                {backToList}

                <div className={classes.root} style={{width:"100%"}}>
                    {tables}
                </div>
            </div>
        );

    }
    renderList = () => {
        const { classes} = this.props;
        // const { orders, display_filter } = this.state;
        const { display_filter, display_filters } = this.state;
        // moved orders out of state, avoid re-rendering order list table when updating other part of state
        // improve performance
        let orders = this.orderList;

        if (!orders) return null;

        // List From WMS
        let colSettings = [
            {
                key: 'selected',
                label:<Checkbox
                  checked={this.state.selected_all}
                  onChange={(e)=>{
                      let new_val = e.target.checked;
                      let new_selected_order = {};
                      if (new_val) {
                          // select all orders
                          let orders = this.state.orders ? this.state.orders : [];
                          for (let order of orders) {
                              new_selected_order[order.order_id] = true;
                          }
                      }
                      this.setState({
                          selected_order: new_selected_order,
                          selected_all: new_val
                      });
                  }}
                  inputRef={(elem)=>{this.select_all_input = elem}}
                  color="primary"
                />,
                render: (val, key, rowData)=>{
                    let checked = this.state.selected_order[rowData.order_id] ? this.state.selected_order[rowData.order_id] : false;
                    return  <Checkbox
                      checked={checked}
                      onChange={(e)=>{
                          let new_val = e.target.checked;
                          let new_list = Object.assign({}, this.state.selected_order);
                          new_list[rowData.order_id] = new_val;
                          let new_state = {selected_order: new_list};
                          if (!new_val) new_state.selected_all  = false;
                          this.setState(new_state)}
                      }
                      color="primary"
                    />;
                },
            },
            {
                key: 'order_key',
                label: 'OrderId',
                render: (val, key, rowData, index)=> {
                    return (<Button onClick={this.loadSingleOrder.bind(this, rowData, index)}
                      // className={this.props.classes.unselectable}
                    >{val}</Button>);
                },
            }, {
                key: 'order_id',
                label: 'Barcode',
                render: utils.tableCellOnDisplay,
            }, {
                key: 'address',
                label: 'Address',
                render: (val, key, rowData)=>{return utils.convertAddress(rowData)},
            }, {
                key: 'email',
                label: 'Email',
                render: utils.tableCellOnDisplay,
            }, {
                key: 'status_id',
                label: 'Status',
                render: utils.convertStatus,
            }, {
                key: 'method',
                label: 'Method',
                render: utils.convertShippingMethod,
            }, {
                key: 'package_type',
                label: 'OrderType',
            }, {
                key: 'order_type',
                label: 'Boxed',
                render: (val, key, row)=>{
                    switch (row.package_type) {
                        case 'whole-sale':
                        case 'edi':
                            return row.split_id ? 'Carton' : 'Main';
                            break;
                        default:
                            return '';
                    }
                },
            },{
                key: 'tracking',
                label: 'Tracking#',
                render: utils.convertShippingMethod,
            }
        ];

        let export_headers = [
            {
                key: 'order_key',
                label: 'OrderId',
            }, {
                key: 'order_id',
                label: 'Barcode',
            }, {
                key: 'address',
                label: 'Address',
                onDisplay: (val, key, rowData)=>{return utils.convertAddress(rowData)},
            }, {
                key: 'email',
                label: 'Email',
            },{
                key: 'status_id',
                label: 'Status',
                onDisplay: utils.convertStatus,
            }, {
                key: 'method',
                label: 'Method',
                // onDisplay: utils.convertShippingMethod,
            },{
                key: 'package_type',
                label: 'OrderType',
            }, {
                key: 'order_type',
                label: 'Boxed',
                onDisplay: (val, key, row)=>{
                    switch (row.package_type) {
                        case 'whole-sale':
                        case 'edi':
                            return row.split_id ? 'Carton' : 'Main';
                            break;
                        default:
                            return '';
                    }
                },
            }, {
                key: 'tracking',
                label: 'Tracking#',
                // onDisplay: utils.convertShippingMethod,
            }
        ];

        let rows = Array.from(orders);
        // switch filters
        rows = rows.filter((elem)=>{
            switch (parseInt(elem.status_id)) {
                case 2:
                    // only show received orders
                    return display_filters['received'] === true;
                case 5:
                    // only show onhold orders
                    return display_filters['onhold'] === true;
                case 7:
                case 8:
                case 12:
                    // only show processing orders
                    return display_filters['processing'] === true;
                case 9:
                    // only show shipped orders
                    return display_filters['shipped'] === true;
                case 6:
                    // only show cancelled orders
                    return display_filters['cancelled'] === true;
                case 13:
                    return display_filters['returned'] === true;
                default:
                    // don't filter
                    return false;
            }
        });

        return (
            <div className={classes.root} style={{width:"100%"}}>
                <GridItem xs={12} sm={12} md={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title}>
                                <Typography variant="h4">
                                    Orders
                                </Typography>

                                <br/>

                                <div style={{fontSize: "1.25rem"}}>
                                    For "Boxed" Column: "Main" orders are just for reference and will not be shipped. "Carton" orders are those actually being shipped.
                                </div>
                            </div>

                            <Grid item xs={12}>
                                <br/>
                                {/*Front-end export*/}
                                {/*<Button onClick={utils.export_array_to_csv.bind(this, rows, export_headers, 'exported_orders')}>Export</Button>*/}
                                {/*Back-end export*/}
                                <Button onClick={this.exportReport}>Export</Button>

                                <Button style={{float: "right", marginRight: "2rem"}} color="secondary" onClick={()=>{
                                        let id_list = [];
                                        for (let order_id in this.state.selected_order) {
                                            if (this.state.selected_order[order_id]) id_list.push(order_id);
                                        }

                                        if (id_list.length === 0) {
                                            this.props.alert("No order selected!");
                                            return;
                                        }

                                        this.props.confirm(
                                          "Are you sure to cancel the "+ id_list.length + " selected orders?",
                                          this.cancelOrders.bind(this, id_list)
                                        );
                                    }}>Cancel Orders</Button>
                                {/*<Button style={{float: "right", marginRight: "4rem"}} color="primary" onClick={()=>{*/}
                                {/*    let id_list = [];*/}
                                {/*    for (let order_id in this.state.selected_order) {*/}
                                {/*        if (this.state.selected_order[order_id]) id_list.push(order_id);*/}
                                {/*    }*/}

                                {/*    if (id_list.length === 0) {*/}
                                {/*        this.props.alert("No order selected!");*/}
                                {/*        return;*/}
                                {/*    }*/}
                                {/*    this.setState({dialog: "edit_order_list"})*/}
                                {/*}}>Edit Orders</Button>*/}
                            </Grid>

                            <div>
                                {/*<Grid item xs={12}>*/}
                                {/*    <br/>*/}
                                {/*    <FormControl component="fieldset">*/}
                                {/*        <FormLabel>Display Filter</FormLabel>*/}
                                {/*        <RadioGroup*/}
                                {/*            row*/}
                                {/*            value={display_filter}*/}
                                {/*            onChange={(e)=>{this.setState({display_filter: e.target.value})}}*/}
                                {/*        >*/}
                                {/*            <FormControlLabel value="None" control={<Radio />} label="None" />*/}
                                {/*            <FormControlLabel value="Received" control={<Radio />} label="Received" />*/}
                                {/*            <FormControlLabel value="Processing" control={<Radio />} label="Processing" />*/}
                                {/*            <FormControlLabel value="Onhold" control={<Radio />} label="Onhold" />*/}
                                {/*            <FormControlLabel value="Shipped" control={<Radio />} label="Shipped" />*/}
                                {/*            <FormControlLabel value="Cancelled" control={<Radio />} label="Cancelled" />*/}
                                {/*        </RadioGroup>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}

                                <Grid item xs={12}>
                                    <br/>
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            checked={display_filters.received ? display_filters.received : false}
                                            onChange={this.handleFiltersChange.bind(this,'received')}
                                            color="primary"
                                          />
                                      }
                                      label="Received"
                                    />
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            checked={display_filters.processing ? display_filters.processing : false}
                                            onChange={this.handleFiltersChange.bind(this,'processing')}
                                            color="primary"
                                          />
                                      }
                                      label="Processing"
                                    />
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            checked={display_filters.onhold ? display_filters.onhold : false}
                                            onChange={this.handleFiltersChange.bind(this,'onhold')}
                                            color="primary"
                                          />
                                      }
                                      label="Onhold"
                                    />
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            checked={display_filters.shipped ? display_filters.shipped : false}
                                            onChange={this.handleFiltersChange.bind(this,'shipped')}
                                            color="primary"
                                          />
                                      }
                                      label="Shipped"
                                    />
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            checked={display_filters.cancelled ? display_filters.cancelled : false}
                                            onChange={this.handleFiltersChange.bind(this,'cancelled')}
                                            color="primary"
                                          />
                                      }
                                      label="Cancelled"
                                    />
                                    <FormControlLabel
                                      control={
                                          <Switch
                                            checked={display_filters.returned ? display_filters.returned : false}
                                            onChange={this.handleFiltersChange.bind(this,'returned')}
                                            color="primary"
                                          />
                                      }
                                      label="Returned"
                                    />
                                </Grid>
                            </div>

                            <EnhancedTable
                              data={rows}
                              tableSettings={{
                                  tableProps: {
                                      className: "with-border"
                                  }
                              }}
                              defaultRowsPerPage={25}
                              // rowSettings={{
                              //     rowProps: {
                              //         onClick: this.loadSingleOrder,
                              //         style: {
                              //             cursor: "pointer"
                              //         }
                              //     },
                              // }}
                              colSettings={colSettings}
                            />
                        </div>
                    </CardContainer>
                </GridItem>
            </div>
        );
    }
    backToList = () => {
        this.setState({displayType: "list"});
        utils.scrollToTop();
        $(window).scrollTop(0);
    }
    renderDialog = () => {
        if (this.state.editItem) {
            // console.log(this.state.order.item);
            let items = this.state.order.item.filter((item)=>{
                return parseInt(item.status_id) !== 6;
            });

            return (
                <EditItemDialog
                    display={this.state.editItem}
                    closeDialog={()=>{this.setState({editItem: false})}}
                    onSubmit={this.updateItems}
                    items={items}
                />
            );
        }
        if (this.state.dialog === "edit_order_list") {
            return (
              <EditOrderListDialog
                display={true}
                closeDialog={()=>{this.setState({dialog: ''})}}
                onSubmit={this.updateMultipleOrders}
                orderIdList={this.state.selected_order}
              />
            );
        }
        if (!this.state.order || this.state.displayType !== 'single') return null;

        switch (this.state.dialog) {
            case "edit_order":
                return (
                    <EditOrderDialog
                        display={true}
                        closeDialog={()=>{this.setState({dialog: ''})}}
                        onSubmit={this.updateOrder}
                        order={this.state.order.order}
                    />
                );
            case 'get_return_label':
                return ( <BasicDialog
                    open={true}
                    onClose={()=>{this.setState({dialog: ''})}}
                    header={'Return Label Link'}
                    content={<div>
                        <p>{this.state.return_label_dialog_description}</p>
                        <a target="_blank" href={this.state.return_label_link}>{this.state.return_label_link}</a>
                    </div>}
                    actions={[<Button key='NewLabel' onClick={()=>{this.getReturnLabel("YES")}}>Get A New One</Button>, <Button color="primary" key='OK' onClick={()=>{this.setState({dialog: ''})}}>OK</Button>]}
                />);
            case "new_label":
                return (
                  <NewLabelDialog
                    display={true}
                    closeDialog={()=>{this.setState({dialog: ''})}}
                    onSubmit={this.createNewLabel}
                  />
                );
        }
    }

    handleFiltersChange = (key, e) => {
        let val = e.target.checked;
        let new_filters = Object.assign({}, this.state.display_filters);
        new_filters[key] = val;
        this.setState({display_filters: new_filters});
    }
}
export default withAlert(withStyles(styles)(ManageOrders));

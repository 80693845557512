import React from "react";
import classNames from "classnames";
import SelectCustWareDialog from 'views/Dialogs/SwitchWarehouse'
// import {
//   drawerWidth,
//   transition,
//   boxShadow,
//   defaultFont,
//   primaryColor,
//   primaryBoxShadow,
//   infoColor,
//   successColor,
//   warningColor,
//   dangerColor
// } from "variables/styles";
import { Person } from "@material-ui/icons";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Button,
} from "components";

const styles = theme => ({
  linkButton: {
    textTransform: "none",
    fontSize: "18px",
    // backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(255, 255, 255, 0.8)"
  },
  itemText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em",
    margin: "0",
    // lineHeight: "30px",
    fontSize: "14px",
    color: "#FFFFFF"
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
  },
  itemLink: {
    width: 'auto',
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em",
  },
});

class HeaderLinks extends React.Component {
  state = {
    display: false
  }

  render() {
    const { classes, asLink } = this.props;

    let warehouse_detail = JSON.parse(localStorage.getItem('warehouse_detail'));
    let warehouse_id = localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '';
    let btnName = warehouse_detail[warehouse_id] ? warehouse_detail[warehouse_id] : 'Switch Warehouse';
    if (typeof btnName !== 'string') btnName = btnName[0]['name'];

    if (asLink) {
      return (
        <List className={classes.list}>
          <ListItem onClick={this.openDialog} button className={classes.itemLink}>
            <ListItemIcon className={classes.itemIcon}>
              <Person/>
            </ListItemIcon>
            <ListItemText
              primary={btnName}
              className={classes.itemText}
              disableTypography={true}
            />
          </ListItem>
          <SelectCustWareDialog
            enableClose={true}
            display={this.state.display}
            closeDialog={this.closeDialog}
          />
        </List>
      );
    }

    return (
      <React.Fragment>
        <SelectCustWareDialog
          key="dialog"
          enableClose={true}
          display={this.state.display}
          closeDialog={this.closeDialog}
        />
        <Button key="button" onClick={this.openDialog} className={classes.linkButton}>{btnName}</Button>
      </React.Fragment>
    );
  }

  openDialog = () => {
    this.setState({display: true});
  }

  closeDialog = () => {
    this.setState({display: false});
  }
}

export default withStyles(styles)(HeaderLinks);

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Button, CustomInput, GridItem, BasicDialog } from 'components'
import {
  Grid,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: '1rem',
  },
  customInput: {
    margin: theme.spacing(1),
  },
});

class EditProductDialog extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    product: PropTypes.object,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: "Edit Product"
  }

  constructor(props, context) {
    super(props, context);

    // new product has not item_id
    let product = {
      item_id: '',
      sku: '',
      upc: '',
      name: '',
      retail_value: '',
      wholesale_value: '',
      cost: '',
      ldp_cost: '',
      hs_code: '',
      length: '',
      width: '',
      height: '',
      weight: '',
      category: '',
      brand: '',
      size: '',
      color: '',
      style: '',
      description: '',
      short_description: '',
      image: '',
      material: '',
      country: '',
    };

    let init = props.product;
    // if the product init data is passed in
    // it is product editing

    if (init && init['item_id']) {
      this.editting = true;
      for (let key in init) {
        if (init[key] || init[key] === 0) {
          product[key] = init[key];
        }
      }
    }

    this.state = {
      product: product,
    };
  }

  render() {
    const { display, closeDialog, title } = this.props;

    let actions = [];
    actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
    actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

    return (
      <BasicDialog
        open={display}
        onClose={closeDialog}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullWidth={true}
        maxWidth="xl"
        header={title}
        content={this.renderProductForm()}
        actions={actions}
      />
    );
  }

  renderProductForm = () => {
    const { classes, onSubmit } = this.props;
    const { product } = this.state;

    let sku_input =
      <GridItem xs={12} md={6} lg={4}>
        <CustomInput
          labelText='SKU'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: this.props.classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            disabled: this.editting,
            placeholder: 'length <= 15, only number, letter, dash and underscore allowed',
            onChange: this.handleProductChange.bind(this, 'sku'),
            value: product.sku,
          }}
        />
      </GridItem>;
    // if (this.editting) {
    //   // if this dialog if for edit product, hide sku input
    //   sku_input = null;
    // }

    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          {sku_input}

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='UPC'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'length <= 15, only number, letter, dash and underscore allowed',
                onChange: this.handleProductChange.bind(this, 'upc'),
                value: product.upc,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Product Name'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'name'),
                value: product.name,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Retail Value'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Minimum value is 0',
                onChange: this.handleProductChange.bind(this, 'retail_value'),
                value: product.retail_value,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Wholesale Value'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Minimum value is 0',
                onChange: this.handleProductChange.bind(this, 'wholesale_value'),
                value: product.wholesale_value,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Cost'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Minimum value is 0',
                onChange: this.handleProductChange.bind(this, 'cost'),
                value: product.cost,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='LDP Cost'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Minimum value is 0',
                onChange: this.handleProductChange.bind(this, 'ldp_cost'),
                value: product.ldp_cost,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='HSCode'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'HSCode is used for Customs international orders',
                onChange: this.handleProductChange.bind(this, 'hs_code'),
                value: product.hs_code,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Length (Inch)'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Should be positive integer',
                onChange: this.handleProductChange.bind(this, 'length'),
                value: product.length,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Width (Inch)'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Should be positive integer',
                onChange: this.handleProductChange.bind(this, 'width'),
                value: product.width,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Height (Inch)'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Should be positive integer',
                onChange: this.handleProductChange.bind(this, 'height'),
                value: product.height,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Weight'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'weight'),
                value: product.weight,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Category'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'category'),
                value: product.category,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Vendor Name'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'brand'),
                value: product.brand,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Size'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'size'),
                value: product.size,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Color'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'color'),
                value: product.color,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Style'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'style'),
                value: product.style,
              }}
            />
          </GridItem>

          {/*<GridItem xs={12} md={6} lg={4}>*/}
          {/*  <CustomInput*/}
          {/*    labelText='Short Description'*/}
          {/*    formControlProps={{*/}
          {/*      fullWidth: true,*/}
          {/*      className: this.props.classes.customInput*/}
          {/*    }}*/}
          {/*    labelProps={{*/}
          {/*      shrink: true,*/}
          {/*    }}*/}
          {/*    inputProps={{*/}
          {/*      onChange: this.handleProductChange.bind(this, 'short_description'),*/}
          {/*      value: product.short_description,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</GridItem>*/}

          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Image'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'image'),
                value: product.image,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Material'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'material'),
                value: product.material,
              }}
            />
          </GridItem>


          <GridItem xs={12} md={6} lg={4}>
            <CustomInput
              labelText='Country of origin'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'country'),
                value: product.country,
              }}
            />
          </GridItem>

          <GridItem xs={12} md={12} lg={4}>
            <CustomInput
              labelText='Description'
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: this.handleProductChange.bind(this, 'description'),
                value: product.description,
              }}
            />
          </GridItem>
        </Grid>
      </form>
    );
  }

  handleProductChange = (key, e) => {
    let val = e.target.value;

    // remove quote
    val = val.replace(/['"]+/g, '');

    let product = Object.assign({}, this.state.product);
    product[key] = val;
    this.setState({product: product});
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.product);
  }
}

export default withStyles(styles)(EditProductDialog)

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import EditUserDialog from '../Dialogs/EditUserDialog'
import axios from 'axios';
import { Grid, IconButton, withStyles } from '@material-ui/core';
import {
    CustomIcon,
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
    InputWithSuggestion
} from "components";
import { withAlert } from 'hoc'
import utils from 'utils/utils'
import _ from "lodash";

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    // custom styles
})};

class ManageUsers extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            dialog: '',
            users: null,
            active_user: null
        };

        document.title = 'Manage User';
    }

    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let dialog = this.renderDialog();

        return (
          <Grid container spacing={3}>
                {loadingBar}

                {dialog}

                <GridItem xs={12} sm={12}>
                    <CardContainer>
                        <div>
                            <Button onClick={()=>{this.setState({dialog: 'create', active_user: null})}}>New User</Button>
                            {this.renderUserTable()}
                        </div>
                    </CardContainer>
                </GridItem>
            </Grid>
        );
    }

    componentWillMount() {
        this.loadUsers();
    }

    // API call
        // load user list
    loadUsers = () => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : ''),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '')
        };
        const API_URL = base + '/api/customer/v1/user';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadUsersSuccess).catch(this.loadUsersError);
    }
    loadUsersSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        this.setState({users: resp.data});
    }
    loadUsersError = (err)=>{utils.generalAjaxErrorHandler(this, err, "Load user list error")}
        // delete user
    deleteUser = (user_id) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/user/' + user_id;

        let req = axios({
            method: 'delete',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.deleteUserSuccess).catch(this.deleteUserError);
    }
    deleteUserSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        if (resp.data) {
            window.location.reload();
        }
    }
    deleteUserError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // Create User
    createUser = (user) => {
        let error = "";
        if (!user.username)  error += "Username is required. \n";
        else if (!utils.formatString(user.username)) error += "Username is invalid. \n";
        // Do not set password, use email instead, password will be generated by system and sent to email
        // if (!user.password) error += "Password is required.\n";
        if (!user.email) error += "Email is required. \n";
        else {
            let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            if (!email_valid.test(user.email)) error += "Email address is invalid. \n";
        }

        if (error) {
            this.props.alert(error);
            return;
        }

        this.createUserOnce( {
            user_role: user.user_role,
            username: user.username,
            email: user.email,
            // Do not set password, use email instead, password will be generated by system and sent to email
            // password: user.password,
        });
    }
    createUserAjax = (data) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/user';

        let req = axios({
            method: 'post',
            url: API_URL,
            headers: headers,
            data
        });

        this.setState({loading: true});
        req.then(this.createUserSuccess).catch(this.createUserError);
    }
    createUserOnce = _.once(this.createUserAjax)
    createUserSuccess = (resp) => {
        this.createUserOnce = _.once(this.createUserAjax);
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        if (resp.data) {
            window.location.reload();
        }
    }
    createUserError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // Edit User
    // editUser = (user) => {
    //     if (user.type === 'customer') {
    //         // user.firstname = '';
    //         // user.lastname = '';
    //         // user.email = '';
    //         user.rate = '';
    //     }
    //
    //     if (!user.username) {
    //         this.props.alert("Username is required");
    //         return;
    //     }
    //     if (!user.password) {
    //         this.props.alert("Password is required");
    //         return;
    //     }
    //
    //     console.log(user);
    //
    //     let base = this.context.data.getBase();
    //     const headers = {
    //         'token': localStorage.getItem('token'),
    //         'user': localStorage.getItem('user_id'),
    //         'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
    //         'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    //     };
    //     const API_URL = base + '/api/editbyadmin';
    //
    //     let req = axios({
    //         method: 'post',
    //         url: API_URL,
    //         headers: headers,
    //         data: {
    //             user_id: user.user_id,
    //             user_type: user.type,
    //             user_role: user.user_role,
    //             // customer: user.customer,
    //             username: user.username,
    //             password: user.password,
    //             firstname: user.firstname,
    //             lastname: user.lastname,
    //             email: user.email,
    //             rate: user.rate,
    //             agent: user.agent
    //         }
    //     });
    //
    //     this.setState({loading: true});
    //     req.then(this.editUserSuccess).catch(this.editUserError);
    // }
    // editUserSuccess = (resp) => {
    //     this.setState({loading: false});
    //     resp = resp.data;
    //     if (resp.Error) {
    //         this.props.alert(resp.Error);
    //         return;
    //     }
    //
    //     if (resp) {
    //         window.location.reload();
    //     }
    //
    //     this.setState({dialog: ''});
    // }
    // editUserError = (err) => {
    //     this.setState({loading: false});
    //     if (!err || !err.response) {
    //         this.props.alert("Internal server error");
    //         return;
    //     }
    //
    //     let resp = err.response;
    //     if (resp.status === 401) {
    //          this.props.alert('Session expired, please login again');
    //          this.context.data.logout();
    //     }
    //
    //     let msg = "Edit user error";
    //     if (resp.data.Error) {
    //         this.props.alert(resp.data.Error);
    //         return;
    //     }
    //     this.props.alert(msg);
    // }
    renderUserTable = () => {
        const {classes} = this.props;

        let colSettings = [
            {
                key: 'user_id',
                label: 'User Id',
                // render: (val, key, row)=>{
                //     return (<Button onClick={()=>{
                //         this.setState({
                //             dialog: 'edit',
                //             active_user: row
                //         });
                //     }}>{val}</Button>);
                // },
            },
            {
                key: 'username',
                label: 'Username',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'firstname',
                label: 'Fullname',
                render: (val, key, row)=>{
                    let fullname = val ? val : '';
                    if (fullname) fullname += ' ';

                    fullname += row.lastname ? row.lastname : '';
                    return fullname;
                },
            },
            {
                key: 'delete',
                label: 'DeleteUser',
                render: (val, key, rowData)=>{
                    return (<Button color="secondary" onClick={()=>{this.props.confirm(
                      "Are you sure you want to delete this user?",
                      this.deleteUser.bind(this, rowData.user_id)
                    )}}>Delete</Button>);

                    // return ( <IconButton onClick={()=>{this.props.confirm("Are you sure you want to delete this user?", this.deleteUser.bind(this, rowData.user_id))}} variant="fab" aria-label="Delete" className={classes.button}>
                    //     <CustomIcon iconName='remove'/>
                    // </IconButton>);
                },
            }
        ];

        let rows = this.state.users ? this.state.users : [];

        return (
            <DataTable
                data={rows}
                withoutPaper
                tableSettings={{
                    tableProps: {
                      className: 'with-border'
                    },
                }}
                colSettings={colSettings}
            />
        );
    }

    renderDialog = () => {
        switch (this.state.dialog) {
            case 'create':
                return (
                    <EditUserDialog
                        display={true}
                        closeDialog={()=>{this.setState({dialog: '', active_user: null})}}
                        title='Create User'
                        onSubmit={this.createUser}
                    />
                );
            case 'edit':
                return (
                    <EditUserDialog
                        display={true}
                        closeDialog={()=>{this.setState({dialog: '', active_user: null})}}
                        title='Edit User'
                        user={this.state.active_user}
                        onSubmit={this.editUser}
                    />
                );
        }
    }
}
export default withAlert(withStyles(styles)(ManageUsers));

import React from 'react';
import { makeStyles, Stepper, Step, StepLabel, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalNonLinearStepperWithError() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  // const steps = getSteps();

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };
  //
  // const isStepFailed = (step) => {
  //   return step === 1;
  // };
  //
  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };
  //
  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(skipped.values());
  //     newSkipped.delete(activeStep);
  //   }
  //
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };
  //
  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  //
  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }
  //
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleReset = () => {
    setActiveStep(0);
  };
  // Todo, finish this later
  return null;
  // return (
  //   <div className={classes.root}>
  //     <Stepper activeStep={activeStep}>
  //       {steps.map((label, index) => {
  //         const stepProps = {};
  //         const labelProps = {};
  //         if (isStepOptional(index)) {
  //           labelProps.optional = (
  //             <Typography variant="caption" color="error">
  //               Alert message
  //             </Typography>
  //           );
  //         }
  //         if (isStepFailed(index)) {
  //           labelProps.error = true;
  //         }
  //         if (isStepSkipped(index)) {
  //           stepProps.completed = false;
  //         }
  //         return (
  //           <Step key={label} {...stepProps}>
  //             <StepLabel {...labelProps}>{label}</StepLabel>
  //           </Step>
  //         );
  //       })}
  //     </Stepper>
  //     <div>
  //       {activeStep === steps.length ? (
  //         <div>
  //           <Typography className={classes.instructions}>
  //             All steps completed - you&apos;re finished
  //           </Typography>
  //           <Button onClick={handleReset} className={classes.button}>
  //             Reset
  //           </Button>
  //         </div>
  //       ) : (
  //         <div>
  //           <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
  //           <div>
  //             <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
  //               Back
  //             </Button>
  //             {isStepOptional(activeStep) && (
  //               <Button
  //                 variant="contained"
  //                 color="primary"
  //                 onClick={handleSkip}
  //                 className={classes.button}
  //               >
  //                 Skip
  //               </Button>
  //             )}
  //
  //             <Button
  //               variant="contained"
  //               color="primary"
  //               onClick={handleNext}
  //               className={classes.button}
  //             >
  //               {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
  //             </Button>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
}

import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles, Paper, Table, TableBody, TableRow, TableCell, TablePagination, Checkbox  } from '@material-ui/core'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    // table: {
    //     minWidth: 1020,
    // },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class EnhancedTable extends React.Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        colSettings: PropTypes.array.isRequired,
        tableSettings: PropTypes.object,
        tableTitle: PropTypes.string,
        rowSettings: PropTypes.object,
        sortingFuncs: PropTypes.object,
        filterable: PropTypes.bool,
        emptyDescription: PropTypes.any,
        fixHeight: PropTypes.bool,
        headerActions: PropTypes.array,
        enableSelect: PropTypes.bool,
        // specify the unique property name which can be used to identify each data row object
        uniqueId: PropTypes.string,
        customFilter: PropTypes.func,
        defaultRowsPerPage: PropTypes.number,
        rppOptions: PropTypes.array,
        withoutPaper: PropTypes.bool,
    };

    static defaultProps = {
        rowSettings: {},
        tableSettings: {},
        sortingFuncs: {},
        filterable: false,
        fixHeight: false,
        headerActions: [],
        enableSelect: false
    };

    constructor(props, context) {
        super(props, context);

        let orderBy = props.orderBy ? props.orderBy : '';
        let order = props.order ? props.order : 'asc';

        let rowsPerPage = props.data.length;
        if (props.rppOptions && props.rppOptions.length > 0) {
            rowsPerPage = props.rppOptions[0];
        }
        if (props.defaultRowsPerPage) rowsPerPage = props.defaultRowsPerPage;
        if (rowsPerPage < 5) rowsPerPage = 5;

        this.state = {
            order: order,
            orderBy: orderBy,
            page: 0,
            rowsPerPage: rowsPerPage,
            searchText: '',
            selected: new Map()
        };
    }

    render() {
        const { data, classes, tableTitle, tableSettings, colSettings, filterable, onSearch, headerActions,enableSelect, uniqueId, rppOptions, withoutPaper  } = this.props;
        const { order, orderBy, rowsPerPage, page, selected } = this.state;

        let dataToDisplay = this.getFilteredData(data);

        let emptyRows = rowsPerPage - Math.min(rowsPerPage, dataToDisplay.length - page * rowsPerPage);
        // if (data.length === 0) {
        //     emptyRows = 0;
        // }
        // todo decide how to deal with empty rows
        if (dataToDisplay.length === 0) emptyRows = 0;
        // todo, default height is 49. Our row height is 159.2
        let emptyRowHeight = 159.2;

        // if not table title, don't show toolbar
        // let title = tableTitle ? tableTitle : '';
        let toolbar = null;

        let toolbarProps = {
            headerActions,
            tableTitle: tableTitle,
            filterable: filterable,
            searchText: this.state.searchText,
            searchTextUpdate: this.searchTextUpdate,
            selected: this.state.selected,
        };
        if (onSearch) {
            toolbarProps.onSearchChange = onSearch;
        }

        if (tableTitle || filterable) {
            toolbar = (
                <EnhancedTableToolbar {...toolbarProps}/>
            );
        }

        let tableProps = {
            className: classes.table,
        };
        if (tableSettings.tableProps) {
            tableProps = Object.assign(tableProps,tableSettings.tableProps);
        }

        let rowPerPageOptions = [5, 25, 50, 100];
        if (rppOptions && rppOptions.length > 0) {
            rowPerPageOptions = rppOptions;
        } else {
            if (dataToDisplay.length > 100) {
                rowPerPageOptions.push(data.length);
            }
        }

        let containerStyle = {};
        if (tableSettings.containerStyle) {
            containerStyle = tableSettings.containerStyle;
        }

        let rowStyle = {};
        if (this.props.fixHeight) {
            rowStyle = {
                height: emptyRowHeight * emptyRows
            };
        } else {
            emptyRows = 0;
        }

        // if row select is enabled, render select all button in table header
        let selectSettings = {};
        if (enableSelect) {
            if (uniqueId) {
                selectSettings.handleSelectAll = this.handleSelectAll;
                selectSettings.numSelected = selected.size;
                selectSettings.rowCount = dataToDisplay.length;
            } else {
                console.log("EnhancedTableComponent: In order to use row select feature, please specify a unique id to identify each row");
            }
        }

        if (withoutPaper) {
            return (
                <div className={classes.root} style={containerStyle}>
                    {toolbar}
                    <div className={classes.tableWrapper}>
                        <Table {...tableProps}>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                colSettings={colSettings}
                                selectSettings={selectSettings}
                            />

                            <TableBody>
                                {this.renderRows(dataToDisplay)}
                                {emptyRows > 0 && (
                                    <TableRow  className='empty-row' style={rowStyle}>
                                        <TableCell colSpan={colSettings.length} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={dataToDisplay.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        rowsPerPageOptions={rowPerPageOptions}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            );
        } else {
            return (
                <Paper className={classes.root} style={containerStyle}>
                    {toolbar}
                    <div className={classes.tableWrapper}>
                        <Table {...tableProps}>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                colSettings={colSettings}
                                selectSettings={selectSettings}
                            />

                            <TableBody>
                                {this.renderRows(dataToDisplay)}
                                {emptyRows > 0 && (
                                    <TableRow  className='empty-row' style={rowStyle}>
                                        <TableCell colSpan={colSettings.length} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={dataToDisplay.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        rowsPerPageOptions={rowPerPageOptions}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );
        }


    }

    // handle search
    searchTextUpdate = text => {
        let newSelected = new Map(this.state.selected.entries());
        // clear selected only when search text changed
        if (!text) text = '';
        if (this.state.searchText !== text) newSelected.clear();

        this.setState(prevState => ({
            searchText: text && text.length ? text : '',
            // when updating search text, clear selected
            selected: newSelected,
        }));
    };

    formatDataRow = (rowData, index) => {
        const { colSettings, rowSettings, enableSelect, uniqueId } = this.props;

        let cells = [];

        // if row select is enabled, render row selector
        let rowSelector = null;
        if (enableSelect) {
            if (uniqueId) {
                let isSelected = this.isSelected(rowData[uniqueId]);
                rowSelector = (
                    <TableCell style={{width: "50px"}} padding="checkbox">
                        <Checkbox checked={isSelected} onClick={this.handleSelect.bind(this, rowData[uniqueId], rowData)} />
                    </TableCell>
                );
            } else {
                console.log("EnhancedTableComponent: In order to use row select feature, please specify a unique id to identify each row");
            }
        }

        for (let cellData of colSettings) {
            let cellProps = {
                key: rowData.id + '_' + cellData.key
            };
            if (cellData.cellProps) {
                cellProps = Object.assign(cellProps, cellData.cellProps);
            }
            // pass data to click handler
            if (cellProps.onClick) {
                let oldOnClick = cellProps.onClick;
                let newOnClick = ()=>{
                    oldOnClick(rowData[cellData.key], cellData.key, rowData, index);
                };
                cellProps.onClick = newOnClick;
            }
            if (cellProps.onDoubleClick) {
                let oldOnDoubleClick = cellProps.onDoubleClick;
                let newOnDoubleClick = ()=>{
                    oldOnDoubleClick(rowData[cellData.key], cellData.key, rowData, index);
                };
                cellProps.onDoubleClick = newOnDoubleClick;
            }

            // cellData.key is the property name to access the cell data from the rowData object
            let cellContent = rowData[cellData.key];
            if (cellData.render) {
                // Render func params: cell Value, cell key, row data object
                cellContent = cellData.render(rowData[cellData.key], cellData.key, rowData, index);
            }

            // if cellPropsOnDisplay is passed in, use it to alter cellProps
            if (_.isFunction(cellData.cellPropsOnDisplay)) {
                cellProps = cellData.cellPropsOnDisplay(cellProps, rowData[cellData.key], cellData.key, rowData, index)
            }

            // if the content is undefined, don't render it
            // if (_.isUndefined(cellContent)) {
            //     continue;
            // }
            // still have to render it
            if (_.isUndefined(cellContent)) {
                cellContent = '';
            }

            cells.push(
                <TableCell {...cellProps}>{cellContent}</TableCell>
            );
        }

        let rowProps = {
            hover: true,
            tabIndex: -1,
            key: rowData.id ? rowData.id : index
        };

        if (rowSettings) {
            rowProps = Object.assign(rowProps, rowSettings.rowProps);

            // this classNameOnDisplay may give row color based on the row data
            if (rowSettings.classNameOnDisplay) {
                rowProps = rowSettings.classNameOnDisplay(rowData, rowProps);
            }
        }

        if (rowProps.onClick) {
            rowProps.onClick = rowProps.onClick.bind(this, rowData, index);
        }

        return (
            <TableRow {...rowProps}>
                {rowSelector}
                {cells}
            </TableRow>
        );
    }
    renderRows = (rows) => {
        const { order, orderBy, rowsPerPage, page } = this.state;
        const { colSettings, emptyDescription, enableSelect, uniqueId } = this.props;

        // let rows = Array.from(this.props.data);
        // rows = this.getFilteredData(rows);

        // if orderBy is set, sort the data
        if (orderBy) {
            if (order === 'desc') {
                rows = rows.sort((a, b) => {
                    let a_val = a[orderBy] ? a[orderBy] : '';
                    let b_val = b[orderBy] ? b[orderBy] : '';
                    return (b_val < a_val ? -1 : 1);
                });
            } else {
                rows = rows.sort((a, b) => {
                    let a_val = a[orderBy] ? a[orderBy] : '';
                    let b_val = b[orderBy] ? b[orderBy] : '';
                    return (a_val < b_val ? -1 : 1);
                });
            }
        }

        let colCount = colSettings.length;
        if (enableSelect && uniqueId) colCount++;

        if (rows.length === 0) {
            let empty = (<TableRow><TableCell colSpan={colCount}><div>No Data</div></TableCell></TableRow>);
            if (emptyDescription) empty = emptyDescription;
            return empty;
        }

        // display current page
        return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, index) => {
            // use formatDataRow to format the data element
            return this.formatDataRow(n, index);
        })
    }

    // filter data to display
    getFilteredData = (data) => {
        const { searchText } = this.state;
        const { colSettings, filterable } = this.props;

        if (filterable && searchText) {
            // search on certain field of the row data object
            let fields = colSettings.filter((col)=>{
                return !col.filterable;
            });
            // if doesn't specify which field to search on, search on all of them
            if (fields.length === 0) fields = colSettings;

            return data.filter((row)=>{return this.filterRowData(row, searchText, fields)});
        } else {
            return data;
        }
    }
    // row filter
    defaultFilterFunc = (filter, data) => {
        return data.toUpperCase().indexOf(filter.toUpperCase()) > -1;
    }
    filterRowData = (row, filter, fields) => {
        const { customFilter } = this.props;

        // if custom filter is provided, use it, otherwise, use default
        let filterFunc = customFilter ? customFilter : this.defaultFilterFunc;

        // fixed row will always be displayed
        if (row.fixed) return true;

        for (let field of fields) {
            let val = row[field['key']];
            // convert number value to string
            if (typeof val === "number") val = '' + val;
            // if value is not string or number, it is not searchable, continue checking next field
            if (typeof val !== "string") continue;

            // filter match the value
            // use filter matching function to match on each table field
            if (filterFunc(filter, val)) return true;
            // todo, keyword match is not case sensitive
            // if (val.toUpperCase().indexOf(filter.toUpperCase()) > -1) return true;
            // if (val.indexOf(filter) > -1) return true;
        }

        // if there is no match
        return false;
    }

    // col sort
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };
    // row select
    isSelected = (row_id) => {
        const { selected } = this.state;
        if (_.isUndefined(selected.get(row_id))) {
            return false;
        } else return true;
    }
    handleSelect = (row_id, row) => {
        const { selected } = this.state;
        const selectedRow = selected.get(row_id);
        const newSelected = new Map(selected.entries());
        if (selectedRow) {
            // if row is currently selected, deselect it
            newSelected.delete(row_id);
        } else {
            // if row is not currently selected, select it
            newSelected.set(row_id, row);
        }
        this.setState({ selected: newSelected});
    }
    handleSelectAll = (e) => {
        // const { selected } = this.state;
        const newSelected = new Map();
        const { data, uniqueId } = this.props;
        let filteredData = this.getFilteredData(data);
        if (e.target.checked) {
            for (let row of filteredData) {
                newSelected.set(row[uniqueId], row);
            }
        }
        this.setState({selected: newSelected});
    }

    // page control
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
}

export default withStyles(styles)(EnhancedTable);

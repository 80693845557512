import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import utils from 'utils/utils'
import _ from 'lodash'
import {
  Typography,
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  withStyles,
  TextField
} from '@material-ui/core';
import {
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from "components";
import { withAlert } from 'hoc'
import ItemCart from './ItemCart'
import moment from "moment-timezone";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectInput: {
        marginTop: '.5rem'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    customInput: {
        margin: theme.spacing(1, 0),
    },
    button: {
        marginTop: theme.spacing(1)
    },
});

class NewOrder extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.google_map_ref = React.createRef();
        let autocomplete = null;

        this.state = {
            loading: false,
            cart: [],
            orderSettings: {
                po_number: '',
                order_type: 'retail',
                start_date: '',
                cancel_date: '',
                ship_date: '',
                payment_terms: '',
                carrier_account: '',
                shipping_type: '',
                method:'',
                slip_note:'',
                customs_description: '',
            },
            countries: null,
            address: {
                firstname: '',
                lastname:'',
                company:'',
                address_line1:'',
                address_line2:'',
                city:'',
                province:'',
                country:'',
                postal_code:'',
                email:'',
                phone_number:''
            }
        };
        document.title = "New Order";
    }

    render() {
        const { classes } = this.props;
        const { loading, cart, orderSettings, address, countries } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let methodOptions = [];
        let customs_description = null;
        let country = null;
        if (orderSettings.shipping_type === 'international') {
            methodOptions.push(<MenuItem key='1' value='BXZ.PKP'>Pack and Hold</MenuItem>);
            // only provide UPS INTL to 131 The Laundress
            // let customer_id = localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '';
          methodOptions.push(<MenuItem key='FDX.INTL.ECO' value='FDX.INTL.ECO'>FedEx International Expedited (2-5 Days)</MenuItem>);
          methodOptions.push(<MenuItem key='UPS.INTL.EXP' value='UPS.INTL.EXP'>UPS International Expedited (2-5 Days)</MenuItem>);
          methodOptions.push(<MenuItem key='DHLEC.PLT' value='DHLEC.PLT'>International Standard (4-12 Days)</MenuItem>);


          // show customs_description input for international orders
            customs_description = (
                <CustomInput
                    labelText='Customs Description'
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.customInput,
                    }}
                    labelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        value: orderSettings.customs_description,
                        onChange: this.handleOrderSettingsChange.bind(this, 'customs_description')
                    }}
                />
            );

            // show country input for international orders
            let country_options = [];
            for (let country of countries) {
                country_options.push(<MenuItem key={country.country_code} value={country.country_code}>{country.name}</MenuItem>);
            }
            country = (
                <FormControl required fullWidth className={classes.selectInput}>
                    <InputLabel htmlFor="country" shrink>Country</InputLabel>
                    <Select
                        value={address.country}
                        onChange={this.handleAddressChange.bind(this, 'country')}
                        inputProps={{
                            name: 'country',
                            id: 'country',
                        }}
                    >
                        {country_options}
                    </Select>
                </FormControl>
            );
        } else {
          methodOptions.push(<MenuItem key='BXZ.PKP' value='BXZ.PKP'>Pack and Hold</MenuItem>);
          methodOptions.push(<MenuItem key='BXZ.USA.1' value='BXZ.USA.1'>Boxzooka 1-Day</MenuItem>);
          methodOptions.push(<MenuItem key='BXZ.USA.2' value='BXZ.USA.2'>Boxzooka 2-Day</MenuItem>);
          methodOptions.push(<MenuItem key='BXZ.USA.3' value='BXZ.USA.3'>Boxzooka 3-Day</MenuItem>);
          methodOptions.push(<MenuItem key='BXZ.USA.5' value='BXZ.USA.5'>Boxzooka 5-Day</MenuItem>);
          methodOptions.push(<MenuItem key='BXZ.USA.7' value='BXZ.USA.7'>Boxzooka 7-Day</MenuItem>);
          methodOptions.push(<MenuItem key='BXZ.SAMEDAY.NYC' value='BXZ.SAMEDAY.NYC'>BXZ Same Day</MenuItem>);
          methodOptions.push(<MenuItem key='UPS.EXP.1' value='UPS.EXP.1'>UPS Next Day Air Early(Next Business Day by 10AM)</MenuItem>);
          methodOptions.push(<MenuItem key='UPS.DOM.1' value='UPS.DOM.1'>UPS Next Day Air(Next Business Day by 2pm)</MenuItem>);
          methodOptions.push(<MenuItem key='UPS.DOM.2' value='UPS.DOM.2'>UPS Second Day Air(Second Business Day by 2pm)</MenuItem>);
          methodOptions.push(<MenuItem key='UPS.DOM.3' value='UPS.DOM.3'>UPS 3-Day Air (Third Business Day by 2pm)</MenuItem>);
          methodOptions.push(<MenuItem key='UPS.GRD.RESI' value='UPS.GRD.RESI'>UPS Ground (1 - 5 Business Day)</MenuItem>);
          methodOptions.push(<MenuItem key='SUREPOST' value='SUREPOST'>UPS Surepost</MenuItem>);
          methodOptions.push(<MenuItem key='FDX.EXP.1' value='FDX.EXP.1'>FedEx Next Day Air Early(Next Business Day by 10AM)</MenuItem>);
          methodOptions.push(<MenuItem key='FDX.DOM.1' value='FDX.DOM.1'>FedEx Next Day Air(Next Business Day by 2pm)</MenuItem>);
          methodOptions.push(<MenuItem key='FDX.DOM.2' value='FDX.DOM.2'>FedEx Second Day Air(Second Business Day by 2pm)</MenuItem>);
          methodOptions.push(<MenuItem key='FDX.DOM.3' value='FDX.DOM.3'>FedEx 3-Day Air (Third Business Day by 2pm)</MenuItem>);
          methodOptions.push(<MenuItem key='FDX.GRD' value='FDX.GRD'>FedEx Ground (1 - 5 Business Day)</MenuItem>);
          methodOptions.push(<MenuItem key='FDX.HOME' value='FDX.HOME'>FedEx Home</MenuItem>);
          methodOptions.push(<MenuItem key='SMARTPOST' value='SMARTPOST'>FedEx SmartPost</MenuItem>);
          methodOptions.push(<MenuItem key='DHLEC.BPM' value='DHLEC.BPM'>DHL Bound Printed Matter Service</MenuItem>);
          // methodOptions.push(<MenuItem key='DHLEC.SAMEDAY' value='DHLEC.SAMEDAY'>DHL Same Day</MenuItem>);
          methodOptions.push(<MenuItem key='DHLEC.MAX' value='DHLEC.MAX'>DHL 3-Day Priority (Usually Delivered W/IN 3 Calendar Days)</MenuItem>);
          methodOptions.push(<MenuItem key='DHLEC.STD.GRD' value='DHLEC.STD.GRD'>DHL Standard Ground Shipping (2- 7 Calendar Days)</MenuItem>);
          methodOptions.push(<MenuItem key='USPS.PRIORITY' value='USPS.PRIORITY' >USPS Priority</MenuItem>);
          methodOptions.push(<MenuItem key='USPS.PARCEL' value='USPS.PARCEL' >USPS ParcelSelect</MenuItem>);
          methodOptions.push(<MenuItem key='USPS.FIRST' value='USPS.FIRST' >USPS First</MenuItem>);
          methodOptions.push(<MenuItem key='USPS.EXPRESS' value='USPS.EXPRESS' >USPS Express</MenuItem>);
        }

        let wholesaleInputGroup = [];
        switch (orderSettings.order_type) {
            case 'whole-sale':
            case 'edi':
                // for edi and wholesale orders, show extra input fields
                // payment terms
                wholesaleInputGroup.push(
                    <FormControl key='payment_terms' fullWidth className={classes.selectInput}>
                        <InputLabel shrink>Payment Terms</InputLabel>
                        <Select
                            value={orderSettings.payment_terms}
                            onChange={this.handleOrderSettingsChange.bind(this, 'payment_terms')}
                            inputProps={{
                                name: 'payment_terms',
                            }}
                        >
                            <MenuItem value='Due Upon Received'>Due Upon Received</MenuItem>
                            <MenuItem value='NET 7'>NET 7</MenuItem>
                            <MenuItem value='NET 14'>NET 14</MenuItem>
                            <MenuItem value='NET 21'>NET 21</MenuItem>
                            <MenuItem value='NET 28'>NET 28</MenuItem>
                            <MenuItem value='NET 30'>NET 30</MenuItem>
                        </Select>
                    </FormControl>
                );
                // start date
                wholesaleInputGroup.push(
                    <CustomInput
                        key='start_date'
                        labelText='Start Date'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customInput
                        }}
                        labelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            type: 'date',
                            inputProps: {
                                min: moment().format("YYYY-MM-DD"),
                            },
                            value: orderSettings.start_date,
                            onChange: this.handleOrderSettingsChange.bind(this, 'start_date')
                        }}
                    />
                );
                // cancel date
                wholesaleInputGroup.push(
                    <CustomInput
                        key='cancel_date'
                        labelText='Cancel Date'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customInput
                        }}
                        labelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            type: 'date',
                            inputProps: {
                                min: moment().format("YYYY-MM-DD"),
                            },
                            value: orderSettings.cancel_date,
                            onChange: this.handleOrderSettingsChange.bind(this, 'cancel_date')
                        }}
                    />
                );
                // ship date
                wholesaleInputGroup.push(
                    <CustomInput
                        key='ship_date'
                        labelText='Ship Date'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customInput
                        }}
                        labelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            type: 'date',
                            inputProps: {
                                min: moment().format("YYYY-MM-DD"),
                            },
                            value: orderSettings.ship_date,
                            onChange: this.handleOrderSettingsChange.bind(this, 'ship_date')
                        }}
                    />
                );
                break;
        }

        return (
          <Grid container spacing={3}>
                {loadingBar}

                <ItemCart
                    cart={cart}
                    onCartChange={(v)=>{this.setState({cart: v})}}
                />

                <form onSubmit={this.newOrder} className={classes.root}>
                {/*<form  style={{width: 'calc(100% + 16px)', margin: '-8px'}} onSubmit={this.newOrder} className={classes.root}>*/}
                    <GridItem xs={12} sm={10} md={6}>
                        <CardContainer>
                            <div>
                                <div className={classes.title}>
                                    <Typography variant="h4">
                                        Step 3: Specify the Order Settings
                                    </Typography>
                                </div>

                                <br/>

                                <GridItem noPadding xs={12} sm={12} md={12}>
                                    <CustomInput
                                      key='po_number'
                                      labelText='Order Id'
                                      formControlProps={{
                                          fullWidth: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          placeholder: 'length <= 18, only number, letter, dash and underscore allowed (Whitespace is not allowed)',
                                          value: orderSettings.po_number,
                                          onChange: this.handleOrderSettingsChange.bind(this, 'po_number')
                                      }}
                                    />

                                    <FormControl required fullWidth className={classes.selectInput}>
                                        <InputLabel htmlFor="order_type" shrink>Order Type</InputLabel>
                                        <Select
                                          value={orderSettings.order_type}
                                          onChange={this.handleOrderSettingsChange.bind(this, 'order_type')}
                                          inputProps={{
                                              name: 'order_type',
                                              id: 'order_type',
                                          }}
                                        >
                                            <MenuItem value='retail'>Retail Order</MenuItem>
                                            <MenuItem value='dropship'>Dropship Order</MenuItem>
                                            <MenuItem value='whole-sale'>Wholesale Order</MenuItem>
                                            <MenuItem value='edi'>EDI Order</MenuItem>
                                            <MenuItem value='gift'>Gift Order</MenuItem>
                                            <MenuItem value='monogram'>Monogram Order</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl required fullWidth className={classes.selectInput}>
                                        <InputLabel shrink htmlFor="shipping_type">Shipping Type</InputLabel>
                                        <Select
                                          value={orderSettings.shipping_type}
                                          onChange={this.handleOrderSettingsChange.bind(this, 'shipping_type')}
                                          inputProps={{
                                              name: 'shipping_type',
                                              id: 'shipping_type',
                                          }}
                                        >
                                            <MenuItem value='domestic'>Domestic</MenuItem>
                                            <MenuItem value='international'>International</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl required fullWidth className={classes.selectInput}>
                                        <InputLabel shrink htmlFor="method">Shipping Method</InputLabel>
                                        <Select
                                          value={orderSettings.method}
                                          onChange={this.handleOrderSettingsChange.bind(this, 'method')}
                                          inputProps={{
                                              name: 'method',
                                              id: 'method',
                                          }}
                                        >
                                            {methodOptions}
                                        </Select>
                                    </FormControl>

                                    <CustomInput
                                      key='carrier_account'
                                      labelText='Carrier Account#'
                                      formControlProps={{
                                          fullWidth: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: orderSettings.carrier_account,
                                          onChange: this.handleOrderSettingsChange.bind(this, 'carrier_account')
                                      }}
                                    />

                                    {wholesaleInputGroup}

                                    {customs_description}

                                    <CustomInput
                                      labelText='Slip Note'
                                      formControlProps={{
                                          fullWidth: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: orderSettings.slip_note,
                                          onChange: this.handleOrderSettingsChange.bind(this, 'slip_note')
                                      }}
                                    />
                                </GridItem>
                            </div>
                        </CardContainer>
                    </GridItem>

                    <GridItem xs={12} sm={10} md={6}>
                        <CardContainer>
                            <div>
                                <div className={classes.title}>
                                    <Typography variant="h4">
                                        Step 4: Set Shipping Address and Submit Order
                                    </Typography>
                                </div>

                                <br/>

                                <GridItem noPadding xs={12} sm={12} md={12}>
                                    {/*<TextField*/}
                                    {/*  variant="outlined"*/}
                                    {/*  margin="normal"*/}
                                    {/*  fullWidth*/}
                                    {/*  InputLabelProps={{className: classes.inputLabel}}*/}
                                    {/*  label="Address Autocomplete"*/}
                                    {/*  placeholder='Enter your address to auto complete the address field'*/}
                                    {/*  onFocus={this.geoLocate}*/}
                                    {/*  inputProps={{*/}
                                    {/*    ref: this.google_map_ref,*/}
                                    {/*    autocomplete: "donot-touch-my-autocomplete",*/}
                                    {/*  }}*/}
                                    {/*/>*/}

                                    {/*<CustomInput*/}
                                    {/*  labelText='Address Autocomplete'*/}
                                    {/*  formControlProps={{*/}
                                    {/*    fullWidth: true,*/}
                                    {/*    variant: "outlined",*/}
                                    {/*    className: classes.customInput,*/}
                                    {/*  }}*/}
                                    {/*  labelProps={{*/}
                                    {/*    shrink: true,*/}
                                    {/*  }}*/}
                                    {/*  inputProps={{*/}
                                    {/*    placeholder: 'Enter your address to auto complete the address field',*/}
                                    {/*    ref: this.google_map_ref,*/}
                                    {/*    inputProps: {*/}
                                    {/*      autocomplete: "off"*/}
                                    {/*    }*/}
                                    {/*  }}*/}
                                    {/*/>*/}

                                    <CustomInput
                                      labelText='First Name'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          placeholder: 'length <= 15',
                                          value: address.firstname,
                                          onChange: this.handleAddressChange.bind(this, 'firstname')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Last Name'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          placeholder: 'length <= 15',
                                          value: address.lastname,
                                          onChange: this.handleAddressChange.bind(this, 'lastname')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Company'
                                      formControlProps={{
                                          fullWidth: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          placeholder: 'length <= 25',
                                          value: address.company,
                                          onChange: this.handleAddressChange.bind(this, 'company')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Address Line 1'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          onFocus: this.geoLocate,
                                          inputProps: {
                                            ref: this.google_map_ref,
                                            autocomplete: "donot-touch-my-autocomplete",
                                          },
                                          placeholder: 'length <= 30',
                                          value: address.address_line1,
                                          onChange: this.handleAddressChange.bind(this, 'address_line1')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Address Line 2'
                                      formControlProps={{
                                          fullWidth: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          placeholder: 'length <= 30',
                                          value: address.address_line2,
                                          onChange: this.handleAddressChange.bind(this, 'address_line2')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='City'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: address.city,
                                          inputProps: {
                                            autocomplete: "donot-touch-my-autocomplete",
                                          },
                                          onChange: this.handleAddressChange.bind(this, 'city')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Province/State'
                                      formControlProps={{
                                          fullWidth: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: address.province,
                                          onChange: this.handleAddressChange.bind(this, 'province')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Postal Code'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: address.postal_code,
                                          onChange: this.handleAddressChange.bind(this, 'postal_code')
                                      }}
                                    />

                                    {country}

                                    <CustomInput
                                      labelText='Email'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: (orderSettings.shipping_type === 'international'),
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: address.email,
                                          onChange: this.handleAddressChange.bind(this, 'email')
                                      }}
                                    />

                                    <CustomInput
                                      labelText='Phone'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: (orderSettings.shipping_type === 'international'),
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          value: address.phone_number,
                                          onChange: this.handleAddressChange.bind(this, 'phone_number')
                                      }}
                                    />

                                    <Button type='submit'>Create Order</Button>

                                </GridItem>
                            </div>
                        </CardContainer>
                    </GridItem>
                </form>
            </Grid>
        );
    }

    componentWillMount() {
        this.loadCountries();
    }
    componentDidMount() {
      if(!window.google) {
        utils.initGoogleMap(this.initAutoComplete);
      } else {
        this.initAutoComplete();
      }
    }

    initAutoComplete = () => {
      if (this.google_map_ref && window.google) {
          let autocomplete = new window.google.maps.places.Autocomplete(
          this.google_map_ref.current, {types: ['geocode']});

          // Avoid paying for data that you don't need by restricting the set of
          // place fields that are returned to just the address components.
          autocomplete.setFields(['address_component']);

          // When the user selects an address from the drop-down, populate the
          // address fields in the form.
          autocomplete.addListener('place_changed', this.onAddressSelected);
          this.autocomplete = autocomplete;
      }
    }
    geoLocate = () => {
      this.google_map_ref.current.setAttribute("autocomplete", "donot-touch-my-autocomplete");

      if (!window.google) {
        console.log("google map api not available!");
        // this.props.alert("google map api not available!");
        return;
      }
      let autoComplete = this.autocomplete;
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            let circle = new window.google.maps.Circle(
              {center: geolocation, radius: position.coords.accuracy});
            autoComplete.setBounds(circle.getBounds());
          });
      }
    }
    onAddressSelected = () => {
      if (!this.autocomplete) return;
      let place = this.autocomplete.getPlace();

      let componentForm = {
        street_number: {
          type: 'short_name',
          key: "address_line1"
        },
        route: {
          type: 'long_name',
          key: "address_line1"
        },
        locality: {
          type: 'long_name',
          key: "city"
        },
        administrative_area_level_1: {
          type: 'short_name',
          key: "province"
        },
        // Todo the country is country code, can't set
        // country: {
        //   type: 'long_name',
        //   key: "country"
        // },
        postal_code: {
          type: 'short_name',
          key: "postal_code"
        }
      };

      let new_address = {};
      for (let address_component of place.address_components) {
        let address_type = address_component.types[0];
        if (componentForm[address_type]) {
          let val = address_component[componentForm[address_type]['type']];
          new_address[componentForm[address_type]['key']] = new_address[componentForm[address_type]['key']] ? (new_address[componentForm[address_type]['key']] + " " + val) : val;
        }
      }

      this.setState({address: Object.assign(this.state.address, new_address)});
    }

    // api call
    loadCountries = () => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/country';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadCountriesSuccess).catch(this.loadCountriesError);
    }
    loadCountriesSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            alert(resp.Error);
            return;
        }

        this.setState({countries: resp});
    }
    loadCountriesError = (err) => {
        this.setState({loading: false});
        let resp = err.response;
        if (!resp) return;
        if (resp.status === 401) {
             alert('Session expired, please login again');
             this.context.data.logout();
        }

        this.props.alert("Get country code error");
    }
    newOrder = (e) => {
        e.preventDefault();
        const { cart, address, orderSettings } = this.state;

        let order = Object.assign({}, orderSettings);
        // if domestic, set country to US
        if (order.shipping_type === 'domestic') {
            address.country = 'US';
        }

        let err = '';
        // validate Order Id (order Id)
        if (order.po_number.length > 18) {
            err += 'Order Id is too long. \n';
        }
        let regex = /[^A-Za-z0-9-_]+/g;
        if (regex.test(order.po_number)) {
            err += 'Order Id only accepts number, letter, dash and underscore (whitespace is not allowed). \n';
        }
        order.po_number = order.po_number.trim();
        if (!order.shipping_type) {
            err += 'Shipping type is required. \n';
        }
        if (!order.method) {
            err += 'Shipping method is required. \n';
        }
        if (!address.country) {
            err += 'Country is required for international order. \n';
        }
        err += utils.validateRequiredInput(address.firstname, "First Name");
        err += utils.validateRequiredInput(address.lastname, "Last Name");
        err += utils.validateRequiredInput(address.address_line1, "Address Line 1");
        err += utils.validateRequiredInput(address.city, "City");
        err += utils.validateRequiredInput(address.postal_code, "Postal Code");
        if (address.firstname.length > 15) {
            err += 'First Name is too long. \n';
        }
        if (address.lastname.length > 15) {
            err += 'Last Name is too long. \n';
        }
        if (address.company.length > 25) {
            err += 'Company is too long. \n';
        }
        if (address.address_line1.length > 30) {
            err += 'Address line 1 is too long. \n';
        }
        if (address.address_line2.length > 30) {
            err += 'Address line 2 is too long. \n';
        }
        if (cart.length === 0) {
            err += 'The item cart is empty.';
        }
        if (address.email) {
          let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
          if (!email_valid.test(address.email)) err += "Email address is invalid. \n";
        }

        switch (order.order_type) {
            case 'whole-sale':
            case 'edi':
                break;
            default:
                // if order is not whole-sale or edi, don't pass those data
                order.payment_terms = '';
                order.start_date = '';
                order.cancel_date = '';
                order.ship_date = '';
                break;
        }

        if (err) {
            alert(err);
            return;
        }

        let total_value = cart.reduce((accumulator, item)=>{
            // use item retail value for retail orders
            let unit_price = parseFloat(item.sale) ? parseFloat(item.sale) : 0;
            if (order.order_type === "whole-sale" || order.order_type === "edi") {
                // use wholesale value for wholesale and edi orders, if it is not a number, use 1
                unit_price = parseFloat(item.wholesale) ? parseFloat(item.wholesale) : 1;
            }
            let item_val = unit_price * item.quantity_input;
            if (!item_val) item_val = 0;
            if (item_val < 0) item_val = 0;

            return accumulator + item_val;
        }, 0);
        // remember, quantity ini cart is quantity_input, quantity means quantity_in_stock
        order.total_value = total_value;

        if (parseInt(total_value) >= 700 && order.method === 'DHLEC.PLT') {
            // DHL International (4 - 10 Days)
            alert("Current shipping method can not be used for order values more than $700, please change it.");
            return;
        }

        order.custom_description = order.customs_description;

        this.newOrderOnce({
            items: cart,
            address: address,
            order: order,
        });
    }
    newOrderAjax = (data) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/order';

        let req = axios({
            method: 'post',
            url: API_URL,
            data,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.newOrderSuccess).catch(this.newOrderError);
    }
    newOrderOnce = _.once(this.newOrderAjax)
    newOrderSuccess = (resp) => {
        this.newOrderOnce = _.once(this.newOrderAjax);
        this.setState({loading: false});
        // alert error if any
        if (resp.data.Error) {
            alert(resp.data.Error);
            return;
        }

        alert("Create order success");
        window.location.reload();
    }
    newOrderError = (err) => {
        this.newOrderOnce = _.once(this.newOrderAjax);
        this.setState({loading: false});
        let resp = err.response;
        if (!resp) return;
        // logout if session expired
        if (resp.status === 401) {
            alert('Session expired, please login again');
            this.context.data.logout();
            return;
        }
        // alert error if any
        this.props.alert("Internal server error.");
    }

    handleOrderSettingsChange = (key, e) => {
        let val = e.target.value;
        let newSettings = Object.assign({}, this.state.orderSettings);
        let newAddress = Object.assign({}, this.state.address);
        // change input value of country, method and customs_description ???
        // if shipping_type toggled between domestic and international
        if (key === 'shipping_type' && newSettings[key] !== val) {
            switch (val) {
                case 'domestic':
                    newSettings.method = '';
                    newSettings.customs_description = '';

                    newAddress.country = "US";
                    break;
                case 'international':
                    newSettings.method = '';
                    let default_country = this.state.countries[0]['country_code'];
                    newAddress.country = default_country ? default_country : '';
                    break;
            }
        }

        newSettings[key] = val;
        this.setState({orderSettings: newSettings, address: newAddress});
    }
    handleAddressChange = (key, e) => {
        let val = e.target.value;
        let newAddress = Object.assign({}, this.state.address);
        newAddress[key] = val;
        this.setState({address: newAddress});
    }
}
export default withAlert(withStyles(styles)(NewOrder));

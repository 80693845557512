import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import utils from 'utils/utils'
import _ from 'lodash'
import {
  Typography,
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  withStyles,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons'
import {
  DataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  BasicDialog,
} from "components";
import NewLabelDialog from 'views/Dialogs/NewLabel.jsx'
import { withAlert } from 'hoc'
import moment from "moment-timezone";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: '.5rem'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
});

class CreateLabel extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };
  static propTypes = {
    alert: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      dialog: "",
      label_list: [],
      active_label_link: "",
    };
    document.title = "Create Label";
  }

  render() {
    const { loading } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }
    return (
      <Grid container spacing={3}>
        {loadingBar}

        {this.renderDialog()}
        {this.renderLabelTable()}
      </Grid>
    );
  }

  componentWillMount() {
    this.loadLabels();
  }

    // load label list
  loadLabels = () => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/label';

    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.loadLabelsSuccess).catch(this.loadLabelsError);
  }
  loadLabelsSuccess = (resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    this.setState({label_list: resp.data ? resp.data : []})
  }
  loadLabelsError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
    // create label
  createLabel = (data) => {
    this.createLabelOnce(data);
  }
  createLabelAjax = (data) => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/label';

    let req = axios({
      method: 'post',
      url: API_URL,
      data,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.createLabelSuccess).catch(this.createLabelError);
  }
  createLabelOnce = _.once(this.createLabelAjax)
  createLabelSuccess = (resp) => {
    this.createLabelOnce = _.once(this.createLabelAjax);
    this.setState({loading: false});
    // alert error if any
    console.log(resp.data);
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    // sometimes the result is wrapped one more layer
    if (resp.data.result) resp.data = resp.data.result;

    switch (true) {
      case typeof resp.data === "string":
        utils.printPage(resp.data);
        break;
      case Array.isArray(resp.data):
        resp.data.forEach((elem, index)=>{
          utils.printPage(elem);
        });
        break;
    }
    window.location.reload();
  }
  createLabelError =(err)=>{
    this.createLabelOnce = _.once(this.createLabelAjax);
    utils.generalAjaxErrorHandler(this, err)
  }

  renderLabelTable = () => {
    const {classes} = this.props;
    const { label_list } = this.state;

    let colSettings = [
      {
        key: 'type',
        label: 'Type',
        render: utils.tableCellOnDisplay
      },{
        key: 'method',
        label: 'Shipping Method',
        render: utils.convertShippingMethod
      }, {
        key: 'tracking',
        label: 'Tracking#',
        render: utils.tableCellOnDisplay
      }, {
        key: 'address',
        label: 'Address',
        render: utils.tableCellOnDisplay
      }, {
        key: 'label_date',
        label: 'Label Date',
        render: utils.formatDate
      }, {
        key: 'label',
        label: 'Label Link',
        render: (val, key, row) => {
          return val ? <Button style={{whiteSpace: "nowrap"}} onClick={()=>{
            let newTab = window.open('about:blank');
            if (newTab) newTab.location.href = val;
            else {
              this.props.alert("Unable to open new tab, please change browser settings to allow pop-up.");
            }
          }}>See Label</Button> : "Label Link Not Available"
          // return val ? <Button onClick={()=>{this.setState({dialog: "print_label", active_label_link: val})}}>{"Click To See"}</Button> : "Label Link Not Available"
        }
      }
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div className={classes.title} style={{height: '3rem'}}>
            <Typography variant="h4">
              Labels

              <Button style={{float: 'right'}} onClick={()=>{this.setState({dialog: "new_label"})}}>new label</Button>
            </Typography>
          </div>

          <DataTable
            data={label_list}
            withoutPaper
            colSettings={colSettings}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDialog = () => {
    switch (this.state.dialog) {
      case "print_label":
        return <BasicDialog
          open={true}
          onClose={()=>{this.setState({dialog: ""})}}
          header="Print Label"
          content={<p style={{whiteSpace: "wrap"}}>The label link is {this.state.active_label_link}</p>}
          actions={[
            <Button key='cancel' onClick={()=>{this.setState({dialog: ""})}}>Close</Button>,
          ]}
        />;
        break;
      case "new_label" :
        return <NewLabelDialog
            display={true}
            closeDialog={()=>{this.setState({dialog: ""})}}
            onSubmit={this.createLabel}
            title={"New Label"}
        />;
      default:
        return null;
    }
  }
}
export default withAlert(withStyles(styles)(CreateLabel));

// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import $ from 'jquery'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Paper,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
    CustomIcon,
    CardIcon,
    DataTable,
    CardContainer,
    Button,
    GridItem,
    PopupAlert
} from "components";
import {
    HourglassEmpty,
    PanTool,
    Done,
    ShoppingBasket,
    AssignmentTurnedIn, FlightTakeoff, Undo, FlightLand, Search
} from '@material-ui/icons';
// Our view components

const styles = theme => ({
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    customCardIcon: {
        height: "67px",
        width: "64px",
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        // fontSize: "1rem"
        fontSize: "1.25rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Neuton-Regular',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Neuton-Bold',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        fontWeight: "bold",
        flexGrow: "1"
    },
});

class InboundDashboard extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.customer = '';
        this.status_id = '';

        this.state = {
            loading: false,
            dashboardDetail: null,
            poList: null,
            po_status: '',
            // Pop up alert
            message_type: "",
            message_content: "",
            confirm_callback: null,
        };

        document.title = "Inbound Dashboard";
    }

    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
            <Grid container spacing={3}>
                {loadingBar}
                {this.renderAlert()}

                {this.renderDashboard()}

                {this.renderPOList()}

            </Grid>
        );
    }

    componentWillMount() {
        this.loadDashboard();
    }

    // api call
        // load dashboard
    loadDashboard = () => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
            'username': localStorage.getItem('username'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/inbounddashboard';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadDashboardSuccess).catch(this.loadDashboardError);
    }
    loadDashboardSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.alert(resp.Error);
            return;
        }

        this.setState({dashboardDetail: resp});
    }
    loadDashboardError = (err) => {
        this.setState({loading: false});
        let resp = err.response;
        if (!resp) return;
        if (resp.status === 401) {
             alert('Session expired, please login again');
             this.context.data.logout();
        }

        let msg = "Load dashbord error";
        if (resp.data.Error) {
            if (resp.data.Error.message) this.alert(resp.data.Error.message);
            else this.alert(resp.data.Error);
            return;
        }
        this.alert(msg);
    }
        // load single customer PO
    loadCustomerPO = (status_id) => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
            'username': localStorage.getItem('username'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/inbounddashboard';

        let req = axios({
            method: 'post',
            data: {
                status: status_id
            },
            url: API_URL,
            headers: headers
        });
        this.setState({loading: true, poList: null, po_status: status_id});
        req.then(this.loadCustomerPOSuccess).catch(this.loadCustomerPOError);
    }
    loadCustomerPOSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            if (resp.data.Error.message) this.alert(resp.data.Error.message);
            else this.alert(resp.data.Error);
            return;
        }
        this.setState({poList: resp.data});
    }
    loadCustomerPOError = (err) => {
        this.setState({loading: false});
        let resp = err.response;
        if (!resp) return;
        if (resp.status === 401) {
             alert('Session expired, please login again');
             this.context.data.logout();
        }

        let msg = "Load PO list error";
        if (resp.data.Error) {
            if (resp.data.Error.message) this.alert(resp.data.Error.message);
            else this.alert(resp.data.Error);
            return;
        }
        this.alert(msg);

    }

    // render functions
    renderDashboard = () => {
        let dashboardDetail = this.state.dashboardDetail ? this.state.dashboardDetail : {
            2: "N/A",
            4: "N/A",
            7: "N/A",
        };

        let pending = dashboardDetail[2] ? dashboardDetail[2] : 0;
        let receiving = dashboardDetail[7] ? dashboardDetail[7] : 0;
        let received = dashboardDetail[4] ? dashboardDetail[4] : 0;

        return <React.Fragment>
            {this.renderSingleStatCard(pending, "Pending Received")}
            {this.renderSingleStatCard(receiving, "Receiving")}
            {this.renderSingleStatCard(received, "Received")}
        </React.Fragment>;
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        let status_code = 2;
        if (!val || val == "N/A") {
            val = 0;
            click_disabled = true
        }

        let title = key;
        let icon = null;
        switch (key) {
            case "Pending Received":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B90"
                }} className={classes.statIconContainer}>
                    <HourglassEmpty className={classes.statIcon} />
                    {/*<CustomIcon iconName="pending" className={classes.customCardIcon} />*/}
                </CardIcon>;
                status_code = 2;
                break;
            case "Receiving":
                icon = <CardIcon style={{
                    backgroundColor: "#30C4EE"
                }} className={classes.statIconContainer}>
                    <PanTool className={classes.statIcon} />
                    {/*<CustomIcon iconName="receiving" className={classes.customCardIcon} />*/}
                </CardIcon>;
                status_code = 7;
                break;
            case "Received":
                icon = <CardIcon style={{
                    backgroundColor: "#71BF47"
                }} className={classes.statIconContainer}>
                    <Done className={classes.statIcon} />
                    {/*<CustomIcon iconName="received" className={classes.customCardIcon} />*/}
                </CardIcon>;
                status_code = 4;
                break;
        }

        return (
          <GridItem xs={12} md={4}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadCustomerPO(status_code);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                          // color: "white"
                                      }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{utils.numberWithComma(val)}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderPOList = () => {
        const { poList, po_status } = this.state;
        const { classes } = this.props;

        if (!poList) return null;

        let colSettings = [
            {
                key: 'po',
                label: 'PO',
                render: (val, key, row)=>{
                    return (
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <span style={{marginRight: '.5rem'}}>{val}</span>
                          <Search  style={{cursor: 'pointer'}} title='Click to see PO detail' onClick={()=>{
                              localStorage.setItem("dbPO", row.po_id);
                              localStorage.setItem("dbPOName", row.po);
                              this.props.history.push("/manageInbound");
                          }}>sss</Search>
                      </div>
                    );
                },
            },
            {
                key: 'name',
                label: 'ItemName',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'sku',
                label: 'SKU',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'upc',
                label: 'UPC',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'quantity',
                label: 'Quantity',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'received',
                label: 'Received',
            },
            {
                key: 'discrepancy',
                label: 'Discrepancy',
                render: (val, key, row)=>{
                    return (
                        parseInt(row['received']) - parseInt(row['quantity'])
                    );
                },
            },
            {
                key: 'estimated_date',
                label: 'EstimatedDate',
                render: utils.formatDate,
            },
            {
                key: 'status_id',
                label: 'Status',
                render: utils.convertPOStatus,
            },
            {
                key: 'tracking',
                label: 'Tracking',
            },
        ];

        let title = '';
        title += utils.convertPOStatus(po_status);

        return (
          <GridItem xs={12}>
              <CardContainer>
                  <div>
                      <div className={classes.title} style={{height: '3rem'}}>
                          <Typography variant="h4">
                              {title}

                              <Button
                                style={{float: 'right'}}
                                onClick={()=>{
                                    utils.export_table_to_csv('table-export', title);
                                }}>Export</Button>
                          </Typography>
                      </div>

                      <DataTable
                        data={poList}
                        colSettings={colSettings}
                        withoutPaper
                        tableSettings={{
                            maxHeight: '600px',
                            tableProps: {
                                id: 'table-export',
                                className: "with-border",
                            }
                        }}
                      />
                  </div>
              </CardContainer>
          </GridItem>
        );
    }

    renderAlert = () => {
        const { classes } = this.props;
        const { message_type, message_content, confirm_callback } = this.state;

        if (!message_content) return;

        return (
          <PopupAlert
            open={true}
            header={message_type}
            type={message_type}
            content={message_content}
            onSubmit={confirm_callback ? confirm_callback : this.closeAlert}
            onClose={this.closeAlert}
          />
        );
    }
    closeAlert = ()=>{
        this.setState({message_type: "", message_content: "", confirm_callback: null})
    }
    alert = (message, type) => {
        if (!type) type = "Error";
        this.setState({message_content: message, message_type: type});
    }
    confirm = (message, confirm_callback) => {
        this.setState({message_content: message, message_type: "Confirm", confirm_callback});
    }
}
export default withRouter(withStyles(styles)(InboundDashboard));

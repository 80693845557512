import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    IconButton
} from '@material-ui/core';
import { Error } from "@material-ui/icons";
import {
    DataTable,
    EnhancedTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
    InputWithSuggestion, CustomIcon
} from 'components';
import { withAlert } from 'hoc'
import utils from 'utils/utils'

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    // custom styles
})};

class EmailReport extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            email_list: [],
            report_types: [],
            report: '',
            email: '',
        };

        document.title = "Email Report";
    }

    render() {
        const { classes } = this.props;
        const { loading, email, report, report_types } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={3}>
                {loadingBar}
                <GridItem xs={12} sm={6}>
                    <CardContainer>
                        <form onSubmit={this.addEmail}>
                            <FormControl required fullWidth className={classes.selectInput}>
                                <InputLabel shrink>Report Type</InputLabel>
                                <Select
                                  value={report}
                                  onChange={(e)=>{this.setState({report: e.target.value})}}
                                >
                                    {report_types.map((val)=>(<MenuItem key={val.value} value={val.value}>{val.name}</MenuItem>))}
                                </Select>
                            </FormControl>

                            <CustomInput
                              labelText='Email'
                              labelProps={{shrink: true}}
                              formControlProps={{
                                  fullWidth: true,
                                  required: true,
                                  className: this.props.classes.customInput
                              }}
                              inputProps={{
                                  onChange: (e)=>{this.setState({email: e.target.value})},
                                  value: email,
                              }}
                            />

                            <Button className={classes.button} type='submit'>Add</Button>
                        </form>
                    </CardContainer>
                </GridItem>

                {this.renderTable()}
            </Grid>
        );
    }

    componentWillMount() {
        this.loadEmailList();
    }

    // api call
    // load email table and report type options on page load
    loadEmailList = () => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/emailReport';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadEmailListSuccess).catch(this.loadEmailListError);
    }
    loadEmailListSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.props.alert(data.Error);
            return;
        }

        let default_report = '';
        if (data.report_type && data.report_type.length > 0) default_report = data.report_type[0]['value'];

        this.setState({report_types: data.report_type, email_list: data.email_list, report: default_report})
    }
    loadEmailListError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
    // remove email
    removeEmail = (id, index) => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/emailReport/' + id;

        let req = axios({
            method: 'delete',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.removeEmailSuccess.bind(this, index)).catch(this.removeEmailError);
    }
    removeEmailSuccess = (index, resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.props.alert(data.Error);
            return;
        }

        let new_list = Array.from(this.state.email_list);
        new_list.splice(index, 1);

        this.setState({email_list: new_list});
    }
    removeEmailError =(err)=>{utils.generalAjaxErrorHandler(this, err)}
    // add a new report email address
    addEmail = (e) => {
        e.preventDefault();
        const { report, email } = this.state;

        let err = '';
        if (!report) {
            err += "Please select a report type. \n";
        }
        if (!email) {
            err += "Please enter the email address. \n";
        } else {
            let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            if (!email_valid.test(email)) err += "Email address is invalid. \n";
        }
        if (err) {
            this.props.alert(err);
            return;
        }

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : ''),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '')
        };
        const API_URL = base + '/api/customer/v1/emailReport';

        let req = axios({
            method: 'post',
            url: API_URL,
            data: {
                email,
                report
            },
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.addEmailSuccess).catch(this.addEmailError);
    }
    addEmailSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.props.alert(data.Error);
            return;
        }

        if (data) {
            window.location.reload();
        } else {
            this.props.alert("Internal server error");
        }

    }
    addEmailError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

    // render search result table
    renderTable = () => {
        const { classes } = this.props;
        let rows = Array.from(this.state.email_list ? this.state.email_list : []);

        let colSettings = [
            {
                key: 'email',
                label: 'Email',
            }, {
                key: 'report',
                label: 'Report Type',
            }, {
                key: 'remove',
                label: 'Remove',
                render: (val, key, row, index)=>{
                    return (<Button color="secondary" onClick={()=>{
                        if (row['id'] !== rows[index]['id']) {
                            for (let i = 0;i< rows.length;i++) {
                                if (row['id'] === rows[i]['id']) {
                                    index = i;
                                    break;
                                }
                            }
                        }
                        this.props.confirm(
                          "Are your sure to delete this email?",
                          this.removeEmail.bind(this, row.id, index)
                        );
                    }}>Remove</Button>);

                    // return (
                    //   <IconButton onClick={()=>{
                    //       if (row['id'] !== rows[index]['id']) {
                    //           for (let i = 0;i< rows.length;i++) {
                    //               if (row['id'] === rows[i]['id']) {
                    //                   index = i;
                    //                   break;
                    //               }
                    //           }
                    //       }
                    //       this.props.confirm(
                    //         "Are your sure to delete this email?",
                    //         this.removeEmail.bind(this, row.id, index)
                    //       );
                    //   }} variant="fab" aria-label="Delete">
                    //       {/*<DeleteForever style={{color: 'white'}}/>*/}
                    //       <CustomIcon iconName='remove'/>
                    //   </IconButton>
                    // );
                },
            }
        ];

        return (
            <GridItem sm={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title} style={{height: '3rem'}}>
                            <Typography variant="h4" id="tableTitle">
                                Email Report
                            </Typography>
                        </div>

                        <DataTable
                          data={rows}
                          // don't set max height
                          // tableSettings={{
                          //     maxHeight: '400px',
                          // }}
                          withoutPaper
                          colSettings={colSettings}
                        />
                    </div>
                </CardContainer>
            </GridItem>
        );
    }
}
export default withAlert(withStyles(styles)(EmailReport));

import React from 'react';
import PropTypes from 'prop-types'
// import Button from 'material-ui/Button';
import { Button } from "components";
import utils from 'utils/utils'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export default class PopupAlert extends React.Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    alertActions: PropTypes.array,
    content: PropTypes.any.isRequired,
    type: PropTypes.string,
    header: PropTypes.any,
  };

  static defaultProps = {
    type: "Error"
  }

  render() {
    const { open, onSubmit, onClose, alertActions, content, header, type, ...restProps } = this.props;

    let actions = [];
    let disableBackdropClick = false;
    let disableEscapeKeyDown = false;
    // if Dialog Actions passed in, use it
    if (Array.isArray(alertActions)) actions = alertActions;
    else {
      // other wise use default actions for different alert type
      switch (type) {
        case "Error":
          actions.push(<Button key='ok' onClick={this.closeDialog}>OK</Button>);
          break;
        case "Confirm":
          actions.push(<Button key='no' onClick={this.closeDialog}>No</Button>);
          actions.push(<Button key='yes' color="primary" onClick={()=>{
            onClose();
            if (onSubmit) onSubmit();
          }}>Yes</Button>);
          break;
        default:
          actions.push(<Button key='ok' onClick={this.closeDialog}>OK</Button>);
      }
    }

    let format_content = content;
    if (typeof format_content === "string") {
      format_content = content.split("\n");
      format_content = format_content.map((val,index)=><div key={index}>{val}</div>);
    }

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        maxWidth='sm'
        fullWidth
        {...restProps}
        aria-labelledby="basic-dialog-title"
      >
        <DialogTitle id="basic-dialog-title"><span>{header}</span></DialogTitle>
        <DialogContent>
          {format_content}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }

  componentDidMount() {
    let audioContext = this.context.data.getAudioContext();
    // utils.playSound(audioContext);
  }

  closeDialog = () => {
    const { onClose } = this.props;
    onClose();
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Paper, Table, TableBody, TableCell, TableHead, TableRow, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import { withAlert } from 'hoc'
import utils from 'utils/utils'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    customInput: {
        margin: theme.spacing(1, 0),
    },
    button: {
        margin: theme.spacing(1, 0),
    },
});

class ItemCart extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        cart: PropTypes.array.isRequired,
        onCartChange: PropTypes.func.isRequired,
        asDialog: PropTypes.bool,
    };

    static defaultProps = {
        asDialog: false
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            result: null,
            resultSingle: null,
            type: 'inventory',
            keyword: '',
            displayType: '',
        };
    }

    render() {
        const { classes, asDialog } = this.props;
        const { loading, keyword, type } = this.state;

        let loadingBar = null;
        if (loading) loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;

        // default don't display item cart in a dialog
        let content = (
          <React.Fragment>
              {loadingBar}
              <GridItem xs={12} sm={12}>
                  <CardContainer noPadding>
                      <div>
                          <div style={{padding: "1rem"}} className={classes.title}>
                              <Typography variant="h4">
                                  Step 1: Search and add item to cart
                              </Typography>
                          </div>

                          <div className={classes.root}>
                              <GridItem xs={12} sm={6}>
                                  <CardContainer>
                                      <form onSubmit={this.search}>
                                          <FormControl required fullWidth className={classes.selectInput}>
                                              <InputLabel htmlFor="search_type">Search Type</InputLabel>
                                              <Select
                                                value={type}
                                                onChange={(e)=>{this.setState({type: e.target.value})}}
                                                inputProps={{
                                                    name: 'search_type',
                                                    id: 'search_type',
                                                }}
                                              >
                                                  <MenuItem value='inventory'>SKU</MenuItem>
                                                  <MenuItem value='item_name'>Item Name</MenuItem>
                                              </Select>
                                          </FormControl>

                                          <CustomInput
                                            labelText='Search Keyword'
                                            formControlProps={{
                                                fullWidth: true,
                                                required: true,
                                                className: this.props.classes.customInput
                                            }}
                                            labelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                onChange: (e)=>{this.setState({keyword: e.target.value})},
                                                value: keyword,
                                            }}
                                          />

                                          <Button className={classes.button} type='submit'>Search</Button>
                                      </form>
                                  </CardContainer>
                              </GridItem>

                              <GridItem xs={12} sm={6}>
                                  {this.renderResult()}
                              </GridItem>
                          </div>
                      </div>
                  </CardContainer>
              </GridItem>

              <GridItem xs={12} sm={12}>
                  <CardContainer>
                      <div>
                          <div className={classes.title}>
                              <Typography variant="h4">
                                  Step 2: Manage the items in the cart
                              </Typography>
                          </div>

                          <br/>

                          <CardContainer noPadding>
                              {this.renderCart()}
                          </CardContainer>

                          <CardContainer noPadding>
                              {this.renderTotal()}
                          </CardContainer>
                      </div>
                  </CardContainer>
              </GridItem>
          </React.Fragment>
        );

        if (asDialog) content = (
          // display item cart in a dialog
          <Grid container spacing={3}>
              {loadingBar}

              <GridItem xs={12} sm={12}>
                  <CardContainer>
                      <form onSubmit={this.search}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <InputLabel htmlFor="search_type">Search Type</InputLabel>
                              <Select
                                value={type}
                                onChange={(e)=>{this.setState({type: e.target.value})}}
                                inputProps={{
                                    name: 'search_type',
                                    id: 'search_type',
                                }}
                              >
                                  <MenuItem value='inventory'>SKU</MenuItem>
                                  <MenuItem value='item_name'>Item Name</MenuItem>
                              </Select>
                          </FormControl>

                          <CustomInput
                            labelText='Search Keyword'
                            formControlProps={{
                                fullWidth: true,
                                required: true,
                                className: this.props.classes.customInput
                            }}
                            labelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                onChange: (e)=>{this.setState({keyword: e.target.value})},
                                value: keyword,
                            }}
                          />

                          <Button type='submit'>Search</Button>
                      </form>
                  </CardContainer>
              </GridItem>

              <GridItem xs={12} sm={12}>
                  {this.renderResult()}
              </GridItem>

              <GridItem xs={12} sm={12}>
                  <CardContainer noPadding>
                      {this.renderCart()}
                  </CardContainer>
              </GridItem>
          </Grid>
        );

        return content;
    }

    addToCart = () => {
        let newCart = Array.from(this.props.cart);
        let newItem = Object.assign({}, this.state.resultSingle);

        newItem.removable = true;

        if (!newItem.quantity_input  || newItem.quantity_input <= 0) {
            alert("Add cart quantity should be greater than 0");
            return;
        }

        for (let item of newCart) {
            if (item.sku === newItem.sku) {
                // if item already in the cart, update quantity
                item.quantity_input = parseInt(item.quantity_input) + parseInt(newItem.quantity_input);
                this.props.onCartChange(newCart);
                return;
            }
        }

        // if item does not exist in the cart, add it
        newCart.push(newItem);
        this.props.onCartChange(newCart);

        this.setState({ result: null, resultSingle: null, displayType: ''});
    }

    // search for items
    search = (e) => {
        e.preventDefault();

        const { type, keyword } = this.state;

        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
            'username': localStorage.getItem('username'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/inventory';

        let req = axios({
            method: 'post',
            data: {
                type,
                keyword: utils.formatString(keyword)
            },
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true,result: null, resultSingle: null, displayType: ''});
        req.then(this.searchSuccess).catch(this.searchError);
    }
    searchSuccess = (resp) => {
        this.setState({loading: false});
        if (!resp.data) {
            alert("Item not found!");
            return;
        }
        // alert error if any
        if (resp.data.Error) {
            alert(resp.data.Error);
            return;
        }

        if (resp.data.length === 0) {
            alert("Item not found");
            return;
        }

        let displayType = 'list';
        let result = resp.data;
        let resultSingle = null;
        if (!Array.isArray(resp.data)) {
            displayType = 'single';
            // resultSingle = result[0];
            let item_detail =result['item_detail'][0];
            resultSingle = Object.assign({}, item_detail);
            resultSingle['quantity'] = parseInt(result['in_stock']) - parseInt(result['allocation']);
        }

        this.setState({resultSingle: resultSingle,result: result, displayType: displayType, keyword: ''});
    }
    searchError = (err) => {
        this.setState({loading: false});
        let resp = err.response;
        if (!resp) return;
        // logout if session expired
        if (resp.status === 401) {
            alert('Session expired, please login again');
            this.context.data.logout();
            return;
        }
        this.props.alert("Internal server error.");
    }

    renderResult = () => {
        const { displayType } = this.state;
        switch (displayType) {
            case 'single':
                return this.renderResultSingle();
            case 'list':
                return this.renderResultList();
        }
    }
    renderResultSingle = () => {
        const { resultSingle } = this.state;
        const { classes } = this.props;
        if (!resultSingle) return null;

        return (
          <CardContainer>
              <div className={classes.tableWrapper} style={{width: "100%"}}>
                  <Table className='with-border'>
                      <TableHead>
                          <TableRow className='cls-table-header-text'>
                              <TableCell>Property</TableCell>
                              <TableCell>Value</TableCell>
                          </TableRow>
                      </TableHead>

                      <TableBody>
                          <TableRow hover>
                              <TableCell>Sku</TableCell>
                              <TableCell>{resultSingle.sku ? resultSingle.sku : ''}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Item Name</TableCell>
                              <TableCell>{resultSingle.description ? resultSingle.description : ''}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Style</TableCell>
                              <TableCell>{resultSingle.style ? resultSingle.style : ''}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>color</TableCell>
                              <TableCell>{resultSingle.color ? resultSingle.color : ''}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Size</TableCell>
                              <TableCell>{resultSingle.size ? resultSingle.size : ''}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Quantity In Stock</TableCell>
                              <TableCell>{resultSingle.quantity}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Retail Value</TableCell>
                              <TableCell>{resultSingle.sale ? resultSingle.sale : ''}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Wholesale Value</TableCell>
                              <TableCell>{resultSingle.wholesale_value}</TableCell>
                          </TableRow>
                          <TableRow hover>
                              <TableCell>Add Cart</TableCell>
                              <TableCell>
                                  <CustomInput
                                    labelText=''
                                    formControlProps={{
                                        fullWidth: true,
                                        className: this.props.classes.customInput
                                    }}
                                    inputProps={{
                                        onChange: (e)=>{
                                            let val = e.target.value;
                                            let newItem = Object.assign({}, resultSingle);
                                            newItem.quantity_input = val;
                                            if (val < 0) return;

                                            this.setState({resultSingle: newItem})
                                        },
                                        value: (resultSingle.quantity_input ? resultSingle.quantity_input : ''),
                                        min: "0",
                                        type: 'number'
                                    }}
                                  />
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </div>

              <Button className={classes.button} onClick={this.addToCart}>Add To Cart</Button>
          </CardContainer>
        );
    }
    renderResultList = () => {
        const { result } = this.state;
        if (!result) return null;

        let itemColSettings = [
            {
                key: 'sku',
                label: 'SKU',
                render: utils.tableCellOnDisplay,
            }, {
                key: 'description',
                label: 'Item Name',
                render: utils.tableCellOnDisplay,
            },
        ];

        return (
          <CardContainer>
              <DataTable
                withoutPaper
                data={result}
                tableSettings={{
                    tableProps: {
                        className: "with-border",
                    },
                    containerStyle: {
                        marginTop: "0",
                    },
                    maxHeight: '400px'
                }}
                rowSettings={{
                    rowProps: {
                        onClick: (item)=>{
                            this.setState({
                                resultSingle: item,
                                displayType: 'single'
                            });
                        },
                        style: {
                            cursor: "pointer"
                        }
                    },
                }}
                colSettings={itemColSettings}
              />
          </CardContainer>
        );
    }

    // render item cart
    renderCart = () => {
        const { cart, onCartChange } = this.props;

        let itemColSettings = [
            {
                key: 'sku',
                label: 'SKU',
                render: utils.tableCellOnDisplay,
            }, {
                key: 'description',
                label: 'Item Name',
                render: utils.tableCellOnDisplay,
            }, {
                key: 'image',
                label: 'Item Picture',
                cellProps: {
                    style: {
                        position: "relative"
                    }
                },
                render: (val, key ,row)=>{
                    if (val) {
                        return (<img className='table-image hover-grow' src={val}/>);
                    } else return "No Picture";
                },
            }, {
                key: 'quantity_input',
                label: 'Quantity',
                render: (val, key, row, index)=>{
                    return (
                        <CustomInput
                            labelText=''
                            formControlProps={{
                                fullWidth: true,
                                className: this.props.classes.customInput
                            }}
                            inputProps={{
                                onChange: (e)=>{
                                    let newQty = e.target.value;

                                    if (newQty <= 0) return;
                                    // if (!newQty || isNaN(parseInt(newQty))) return;

                                    let newRow = Object.assign({}, row);
                                    newRow.quantity_input = newQty;

                                    let newCart = Array.from(cart);

                                    if (newCart[index]['item_id'] === row.item_id) {
                                        newCart[index] = newRow;
                                        onCartChange(newCart);
                                    } else {
                                        for (let i = 0; i < newCart.length; i++) {
                                            if (newCart[i]['item_id'] === row['item_id']) {
                                                newCart[i] = newRow;
                                                onCartChange(newCart);
                                                return;
                                            }
                                        }
                                    }
                                },
                                type: 'number',
                                min: 1,
                                step: 1,
                                value: val,
                            }}
                        />
                    );
                },
            }, {
                key: 'Remove',
                label: 'Remove',
                render: (val, key, row, index)=>{
                    if (row.removable) {
                        return (<Button color="secondary" onClick={
                            ()=>{
                                let newCart = Array.from(cart);

                                if (newCart[index]['item_id'] === row.item_id) {
                                    newCart.splice(index, 1);
                                    onCartChange(newCart);
                                } else {
                                    for (let i = 0; i < newCart.length; i++) {
                                        if (newCart[i]['item_id'] === row['item_id']) {
                                            newCart.splice(i ,1);
                                            onCartChange(newCart);
                                            return;
                                        }
                                    }
                                }
                            }
                        }>Remove</Button>);
                    } else return null;
                },
            }

        ];

        return (
            <DataTable
                data={cart}
                tableSettings={{
                    tableProps: {
                        className: "with-border",
                        style: {
                            maxHeight: '500px'
                        }
                    },
                    containerStyle: {
                        marginTop: "0"
                    }
                }}
                colSettings={itemColSettings}
            />
        );
    }
    renderTotal = () => {
        const { cart } = this.props;
        let retail_total = 0;
        let wholesale_total = 0;

        for (let item of cart) {
            let retail_single = parseFloat(item.sale) ? parseFloat(item.sale) : 0;
            let wholesale_single = parseFloat(item.wholesale) ? parseFloat(item.wholesale) : 0;
            retail_total += item.quantity_input * retail_single;
            wholesale_total += item.quantity_input * wholesale_single;
        }

        let rows = [{
            retail_total: retail_total,
            wholesale_total: wholesale_total
        }];

        let colSettings = [
            {
                key: 'retail_total',
                label: 'Retail Total',
                render: utils.tableCellOnDisplay,
            }, {
                key: 'wholesale_total',
                label: 'Wholesale Total',
                render: utils.tableCellOnDisplay,
            }
        ];

        return (
            <DataTable
                data={rows}
                tableSettings={{
                    tableProps: {
                        className: "with-border",
                        style: {
                            maxHeight: '500px'
                        }
                    },
                    containerStyle: {
                        marginTop: "0"
                    }
                }}
                colSettings={colSettings}
            />
        );
    }
}
export default withAlert(withStyles(styles)(ItemCart));

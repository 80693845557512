import React, { Component } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Button, CustomInput, BasicDialog } from 'components'
import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    withStyles,
    FormControl, FormGroup, FormControlLabel, Checkbox, Tooltip,
} from '@material-ui/core'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: '1rem',
    },
    selectInput: {
        width: '100%',
        margin: '0 0 .625rem 0',
    },
    customInput: {
        margin: theme.spacing(1, 0),
    },
});

class EditOrderListDialog extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        display: PropTypes.bool.isRequired,
        closeDialog: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        orderIdList: PropTypes.object.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        title: "Edit Orders"
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            order: {
                method: "",
                saturday_delivery: "",
                signature_required: "",
                slip_note: "",
                package_type: "",
                carrier_account: "",
                payment_term : "",
                incoterms: "",
                start_date: "",
                cancel_date: "",
                ship_date: ""
            },
        };
    }

    render() {
        const { classes, display, closeDialog, onSubmit, title } = this.props;
        const { order } = this.state;

        let content = (
            <Grid>
                <form onSubmit={onSubmit}>
                    <FormControl fullWidth className={classes.selectInput}>
                        <InputLabel htmlFor="method" shrink>Shipping Method</InputLabel>
                        <Select
                            value={(order.method ? order.method : '')}
                            onChange={this.handleOrderChange.bind(this, 'method')}
                            inputProps={{
                                name: 'method',
                                id: 'method',
                            }}
                        >
                            <MenuItem value='BXZ.PKP'>Pack and Hold</MenuItem>
                            <MenuItem value='BXZ.USA.1'>Boxzooka 1-Day</MenuItem>,
                            <MenuItem value='BXZ.USA.2'>Boxzooka 2-Day</MenuItem>,
                            <MenuItem value='BXZ.USA.3'>Boxzooka 3-Day</MenuItem>,
                            <MenuItem value='BXZ.USA.5'>Boxzooka 5-Day</MenuItem>,
                            <MenuItem value='BXZ.USA.7'>Boxzooka 7-Day</MenuItem>,
                            <MenuItem value='BXZ.SAMEDAY.NYC'>BXZ Same Day</MenuItem>
                            <MenuItem value='UPS.EXP.1'>UPS Next Day Air Early(Next Business Day by 10AM)</MenuItem>
                            <MenuItem value='UPS.DOM.1'>UPS Next Day Air(Next Business Day by 2pm)</MenuItem>
                            <MenuItem value='UPS.DOM.2'>UPS Second Day Air(Second Business Day by 2pm)</MenuItem>
                            <MenuItem value='UPS.DOM.3'>UPS 3-Day Air (Third Business Day by 2pm)</MenuItem>
                            <MenuItem value='UPS.GRD.RESI'>UPS Ground (1 - 5 Business Day)</MenuItem>
                            <MenuItem value='SUREPOST'>UPS Surepost</MenuItem>
                            <MenuItem value='FDX.EXP.1'>FedEx Next Day Air Early(Next Business Day by 10AM)</MenuItem>
                            <MenuItem value='FDX.DOM.1'>FedEx Next Day Air(Next Business Day by 2pm)</MenuItem>
                            <MenuItem value='FDX.DOM.2'>FedEx Second Day Air(Second Business Day by 2pm)</MenuItem>
                            <MenuItem value='FDX.DOM.3'>FedEx 3-Day Air (Third Business Day by 2pm)</MenuItem>
                            <MenuItem value='FDX.GRD'>FedEx Ground (1 - 5 Business Day)</MenuItem>
                            <MenuItem value='FDX.HOME'>FedEx Home</MenuItem>
                            <MenuItem value='SMARTPOST'>FedEx SmartPost</MenuItem>
                            <MenuItem value='DHLEC.BPM'>DHL Bound Printed Matter Service</MenuItem>
                            {/*<MenuItem value='DHLEC.SAMEDAY'>DHL Same Day</MenuItem>*/}
                            <MenuItem value='DHLEC.MAX'>DHL 3-Day Priority (Usually Delivered W/IN 3 Calendar Days)</MenuItem>
                            <MenuItem value='DHLEC.STD.GRD'>DHL Standard Ground Shipping (2- 7 Calendar Days)</MenuItem>
                            <MenuItem value='USPS.PRIORITY' >USPS Priority</MenuItem>
                            <MenuItem value='USPS.PARCEL' >USPS ParcelSelect</MenuItem>
                            <MenuItem value='USPS.FIRST' >USPS First</MenuItem>
                            <MenuItem value='USPS.EXPRESS' >USPS Express</MenuItem>
                            <MenuItem value='FDX.INTL.ECO'>FedEx International Expedited (2-5 Days)</MenuItem>
                            <MenuItem value='UPS.INTL.EXP'>UPS International Expedited (2-5 Days)</MenuItem>
                            <MenuItem value='DHLEC.PLT'>International Standard (4-12 Days)</MenuItem>
                        </Select>
                    </FormControl>

                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={order.saturday_delivery ? order.saturday_delivery : false}
                                    onChange={this.handleOrderChange.bind(this, 'saturday_delivery')}
                                    color='primary'
                                />
                            }
                            label='Saturday Delivery'
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={order.signature_required ? order.signature_required : false}
                                    onChange={this.handleOrderChange.bind(this, 'signature_required')}
                                    color='primary'
                                />
                            }
                            label='Signature Required'
                        />
                    </FormGroup>

                    <CustomInput
                        labelText='Slip Note'
                        labelProps={{
                            shrink: true,
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: this.props.classes.customInput
                        }}
                        inputProps={{
                            onChange: this.handleOrderChange.bind(this, 'slip_note'),
                            value: (order.slip_note ? order.slip_note : ''),
                        }}
                    />

                    <FormControl fullWidth className={classes.selectInput}>
                        <InputLabel htmlFor="package_type" shrink>Package Type</InputLabel>
                        <Select
                            value={(order.package_type ? order.package_type : '')}
                            onChange={this.handleOrderChange.bind(this, 'package_type')}
                            inputProps={{
                                name: 'package_type',
                                id: 'package_type',
                            }}
                        >
                            <MenuItem value='retail'>Retail</MenuItem>
                            <MenuItem value='whole-sale'>Wholesale</MenuItem>
                            <MenuItem value='edi'>EDI</MenuItem>
                            <MenuItem value='monogram'>Monogram</MenuItem>
                            <MenuItem value='gift'>Gift Order</MenuItem>
                            <MenuItem value='gift-monogram'>Gift Monogram Order</MenuItem>
                        </Select>
                    </FormControl>

                    {/*<FormControl fullWidth className={classes.selectInput}>*/}
                    {/*    <InputLabel htmlFor="incoterms" shrink>Incoterms</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        value={(order.incoterms ? order.incoterms : '')}*/}
                    {/*        onChange={this.handleOrderChange.bind(this, 'incoterms')}*/}
                    {/*        inputProps={{*/}
                    {/*            name: 'incoterms',*/}
                    {/*            id: 'incoterms',*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <MenuItem value='DDU'>DDU</MenuItem>*/}
                    {/*        <MenuItem value='DDP'>DDP</MenuItem>*/}
                    {/*    </Select>*/}
                    {/*</FormControl>*/}

                    {/*<FormControl fullWidth className={classes.selectInput}>*/}
                    {/*    <InputLabel htmlFor="Payment Term" shrink>Payment Term</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        value={(order.payment_term ? order.payment_term : '')}*/}
                    {/*        onChange={this.handleOrderChange.bind(this, 'payment_term')}*/}
                    {/*        inputProps={{*/}
                    {/*            name: 'payment_term',*/}
                    {/*            id: 'payment_term',*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <MenuItem value='Due Upon Received'>Due Upon Received</MenuItem>*/}
                    {/*        <MenuItem value='NET 7'>NET 7</MenuItem>*/}
                    {/*        <MenuItem value='NET 14'>NET 14</MenuItem>*/}
                    {/*        <MenuItem value='NET 30'>NET 30</MenuItem>*/}
                    {/*    </Select>*/}
                    {/*</FormControl>*/}

                    <CustomInput
                        labelText='Carrier Account'
                        labelProps={{
                            shrink: true,
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: this.props.classes.customInput
                        }}
                        inputProps={{
                            onChange: this.handleOrderChange.bind(this, 'carrier_account'),
                            value: (order.carrier_account ? order.carrier_account : ''),
                        }}
                    />

                    <CustomInput
                        labelText='Start Date'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customInput
                        }}
                        labelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            type: 'date',
                            inputProps: {
                                min: moment().format("YYYY-MM-DD"),
                            },
                            value: order.start_date ? order.start_date : '',
                            onChange: this.handleOrderChange.bind(this, 'start_date'),
                        }}
                    />

                    <CustomInput
                        labelText='Cancel Date'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customInput
                        }}
                        labelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            type: 'date',
                            inputProps: {
                                min: moment().format("YYYY-MM-DD"),
                            },
                            value: order.cancel_date ? order.cancel_date : '',
                            onChange: this.handleOrderChange.bind(this, 'cancel_date'),
                        }}
                    />

                    <CustomInput
                        labelText='Ship Date'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customInput
                        }}
                        labelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            type: 'date',
                            inputProps: {
                                min: moment().format("YYYY-MM-DD"),
                            },
                            value: order.ship_date ? order.ship_date : '',
                            onChange: this.handleOrderChange.bind(this, 'ship_date'),
                        }}
                    />
                </form>
            </Grid>
        );

        let actions = [];
        actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
        actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

        return (
            <BasicDialog
                open={display}
                onClose={closeDialog}
                header={title}
                content={content}
                actions={actions}
                fullWidth={true}
                maxWidth={"sm"}
            />
        );
    }

    handleOrderChange = (key, e) => {
        let val = e.target.value;
        if (key === "saturday_delivery" || key === "signature_required") val = e.target.checked;

        // remove quote
        // val = this.formatInput(val);

        let order = Object.assign({}, this.state.order);
        order[key] = val;
        this.setState({order: order});
    }

    formatInput = (val) => {
        if (!val) return val;
        if (typeof val === "number") val = '' + val;

        let result = val;
        try {
            result = val.trim();
            result = result.replace(/["']/g, "");
        }
        catch (err) {
            console.log(err);
        }
        return result;
    }

    handleSubmit = () => {
        this.props.onSubmit(this.state.order);
    }
}

export default withStyles(styles)(EditOrderListDialog)

import React from 'react';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import cx from "classnames";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Collapse } from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore
} from "@material-ui/icons";

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class NestedList extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    listData: PropTypes.object.isRequired,
    sidebarClasses: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
  }

  state = { open: false };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, listData, sidebarClasses, color } = this.props;

    let submenu = [];

    for (let sub of listData.submenu) {
      let listItemClasses = cx({
        [" " + sidebarClasses[color]]: this.activeRoute(sub.path)
      });
      let whiteFontClasses = cx({
        [" " + sidebarClasses.whiteFont]: this.activeRoute(sub.path)
      });

      submenu.push(
        <NavLink
          to={sub.path}
          className={sidebarClasses.item}
          activeClassName="active"
          key={sub.path}
        >
          <ListItem button className={sidebarClasses.itemLink + listItemClasses}>
            <ListItemIcon className={sidebarClasses.itemIcon}>
              <sub.icon />
            </ListItemIcon>
            <ListItemText
              primary={sub.sidebarName}
              className={sidebarClasses.itemText + whiteFontClasses}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    }

    return [
      (
        <ListItem key={listData.path + 'main'} button onClick={this.handleClick} className={sidebarClasses.itemLink}>
          <ListItemIcon className={sidebarClasses.itemIcon}>
            <listData.icon />
          </ListItemIcon>
          <ListItemText
            primary={<div>{listData.sidebarName} <span style={{float: 'right'}} className={sidebarClasses.itemIcon}>{this.state.open ? <ExpandLess /> : <ExpandMore />}</span></div>}
            className={sidebarClasses.itemText}
            disableTypography={true}
          >
          </ListItemText>

        </ListItem>
      ),
      <Collapse key={listData.path + 'sub'} in={this.state.open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {submenu}
        </List>
      </Collapse>
    ];
  }

  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
}

export default withStyles(styles)(NestedList);

import React from "react";
import PropTypes from "prop-types";
import $ from 'jquery';
import utils from '../utils/utils'
import cx from 'classnames'
import { Switch, Route, Redirect } from "react-router-dom";
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core'
import FontRalewayRegular from 'assets/fonts/Raleway-Regular.ttf'
import FontRalewayBold from 'assets/fonts/Raleway-Bold.ttf'

import ChronicleDisplayRoman from 'assets/fonts/Chronicle-Display-Roman.otf'
import ProximaNova from 'assets/fonts/Proxima-Nova-ont.otf'
// Roboto
import RobotoBold from 'assets/fonts/roboto/Roboto-Bold.ttf'
import RobotoRegular from 'assets/fonts/roboto/Roboto-Regular.ttf'
// Neuton
import NeutonBold from 'assets/fonts/neuton/Neuton-Bold.ttf'
import NeutonRegular from 'assets/fonts/neuton/Neuton-Regular.ttf'

import { withRouter } from 'react-router'
import { Header, Sidebar } from "../components";
import allRoutes from '../routes/menu.jsx'
import anonymousRoutes from "../routes/anonymous.jsx";
import Manifest from './Manifest'
// import 'react-select/dist/react-select.css';
import appStyle from "variables/styles/appStyle.jsx";

import image from "../assets/img/sidebar-background.jpg";
import logo from "../assets/img/bl_logo_reverse.png";
import _ from "lodash";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';


const raleway = {
  fontFamily: 'Raleway-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url(${FontRalewayRegular}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const ralewayBold = {
  fontFamily: 'Raleway-Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Raleway Bold'),
    local('Raleway-Bold'),
    url(${FontRalewayBold}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const neuton = {
  fontFamily: 'Neuton-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Neuton'),
    local('NeutonRegular'),
    url(${NeutonRegular}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    h4: {
      fontSize: "2rem"
    },
    // fontFamily: ['Raleway', 'Raleway-Bold'].join(','),
    // fontFamily: 'Raleway-Regular',
    fontFamily: 'Neuton-Regular',
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#000",
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        // fontFamily: "Raleway-Bold"
        fontFamily: "Neuton-Bold"
      },
      shrink: {
        color: "#000",
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        // fontFamily: "Raleway-Bold"
        fontFamily: "Neuton-Bold"
      }
    },
    MuiTableCell: {
      root: {
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
      },
      head: {
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Neuton-Bold',
        fontWeight: 'normal',
        // fontSize: "1.25rem"
        fontSize: "1.5rem",
      }
    },
    MuiCssBaseline: {
      '@global': {
        // '@font-face': [raleway, ralewayBold],
        // '@font-face': [raleway],
        '@font-face': [neuton],

      },
    },
  },
});

const switchAllRoutes = (
  <Switch>
    {generateRoutes(allRoutes)}
  </Switch>
);

const switchAnonymousRoute = (
  <Switch>
    {
      generateRoutes(anonymousRoutes)
    }
    {/*{anonymousRoutes.map((prop, key) => {*/}
    {/*    console.log(prop);*/}
    {/*    if (prop.redirect) {*/}
    {/*        console.log('redirect');*/}
    {/*        return <Redirect from={prop.path} to={prop.to} key={prop.path} />;*/}
    {/*    }*/}
    {/*    return <Route ptah={prop.path} component={prop.component} key={prop.path}/>*/}
    {/*})}*/}
  </Switch>
);

// The function filter out routes that current role doesn't have permission
function filterRoutes(routes_input) {
  const general = {
    login: true,
    dashboard: true,
    logout: true,
    redirect: true,
  };
  let specialized = {};
  try {
    specialized = JSON.parse(localStorage.getItem('menu_list'));
  } catch (err) {
    // role_menu_list should be set after login
    specialized = {};
  }
  if (!specialized) specialized = {};

  // console.log("Todo, need to move the page permission filter back when backend is passing permissions!");
  // Todo, remove this when permissions are passed
  return routes_input;

  return (routes_input.filter((elem)=>{
    return general[elem.key] || specialized[elem.key]
  }));
}
function generateRoutes(inputRoutes) {
  let routes = [];
  // if current route is not in general view list
  // nor the specialized for each role, don't show it
  inputRoutes = filterRoutes(inputRoutes);
  for (let route of inputRoutes) {
    if (route.logout) continue;
    if (route.asButton) continue;
    if (route.redirect) {
      routes.push(<Redirect from={route.path} to={route.to} key={route.path} />);
    } else if (route.submenu) {
      routes = routes.concat(generateRoutes(route.submenu));
    } else {
      routes.push(<Route path={route.path} component={route.component} key={route.path} />);
    }
  }
  return routes;
}

class App extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }
  static childContextTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context);

    this.last_active_time = new Date().getTime();
    this.session_check = null;

    this.state = {
      mobileOpen: false,
      pcOpen: true,
      role: localStorage.getItem('role'),
      warehouse: localStorage.getItem('warehouse_id'),
      customer: localStorage.getItem('customer_id'),
    };

    // passing true to set dev mode on
    let settings = new Manifest(true);
    if (!window.AudioContext) window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
    let audioContext = null;
    try {
      audioContext = new window.AudioContext();
    } catch (e) {
      console.log('!Your browser does not support AudioContext');
    }
    this.data = {
      getAudioContext: ()=>{return audioContext},
      getRole: this.getRole,
      setRole: this.setRole,
      getWarehouse: this.getWarehouse,
      setWarehouse: this.setWarehouse,
      getCustomer: this.getCustomer,
      setCustomer: this.setCustomer,
      setWarehouseAndCustomer: this.setWarehouseAndCustomer,
      getBase: ()=> {return settings.getBaseUrl()},
      registerSessionCheck: this.registerSessionCheck,
      logout: this.logout,
    };
  }

  render() {
    const { classes, ...rest } = this.props;

    let role = this.state.role;
    let content = null;

    let mainStyle = {};
    if (!this.state.pcOpen) {
      mainStyle.width = '100%';
    }

    let available_routes = filterRoutes(allRoutes);

    if (!role) {
      content = (
        <div>
          <div>{switchAnonymousRoute}</div>
        </div>
      );
    } else {
      content = (
        <div className={classes.root}>
          <CssBaseline />
          <Header
            routes={available_routes}
            handleDrawerToggle={this.handleDrawerToggle}
            handlePCDrawerToggle={this.handlePCDrawerToggle}
            pcOpen={this.state.pcOpen}
            {...rest}
          />

          <Sidebar
            routes={available_routes}
            logout={this.logout}
            logoText={"Boxzooka"}
            logo={logo}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            pcOpen={this.state.pcOpen}
            handlePCDrawerToggle={this.handlePCDrawerToggle}
            color="blue"
            {...rest}
          />

          <main id="page-content-container" className={classes.content}>
            {/*<div className={classes.appBarSpacer} />*/}
            <Container id="main-app-container" maxWidth={false} className={classes.container}>
              {switchAllRoutes}
            </Container>
          </main>
        </div>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {content}
      </ThemeProvider>
    );
    // return content;
  }

  componentDidMount() {
    // Todo move this back when ready to add session timeout feature
    // // check mouse action
    // $(document).mousemove(this.debouncedUpdateSession);
    // $(document).click(this.debouncedUpdateSession);
    // $(document).scroll(this.debouncedUpdateSession);
    // $(document).mousedown(this.debouncedUpdateSession);
    // // check keyboard action
    // $(document).keypress(this.debouncedUpdateSession);
    // $(document).keydown(this.debouncedUpdateSession);
    // set session check interval
    // if (this.session_check) clearInterval(this.session_check);
    // this.session_check = setInterval(this.checkSessionExpire, 10000); // check session every 10 seconds

    if (!this.refs.mainPanel) return;
  }
  componentDidUpdate() {
    if (!this.refs.mainPanel) return;
    this.refs.mainPanel.scrollTop = 0;
  }
  componentWillUnmount() {
    if (this.session_check) clearInterval(this.session_check);
  }

  getChildContext() {
    return {
      data: this.data,
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  handlePCDrawerToggle = () => {
    this.setState({ pcOpen: !this.state.pcOpen});
  }

  // Setters and Getters
  getRole = () => {
    return this.state.role;
  }
  setRole = (val) => {
    this.setState({role: val});
  }
  getWarehouse = () => {
    return this.state.warehouse;
  }
  setWarehouse = (val) => {
    this.setState({warehouse: val});
  }
  getCustomer = () => {
    return this.state.customer;
  }
  setCustomer = (val) => {
    this.setState({customer: val});
  }
  setWarehouseAndCustomer = (newState) => {
    this.setState({warehouse: newState.warehouse, customer: newState.customer});
  }
  registerSessionCheck = () => {
    if (this.session_check) clearInterval(this.session_check);
    // check session every 10 seconds
    this.session_check = setInterval(this.checkSessionExpire, 10000);
  }

  updateSession = ()=> {
    this.last_active_time = new Date().getTime();
  }
  debouncedUpdateSession = _.debounce(this.updateSession, 150);
  checkSessionExpire = () => {
    const { role, warehouse, customer } = this.state;
    if (!role || !warehouse || !customer) return; // if not logged in, don't check

    // Todo for some certain role, might not want to check session expire
    // Todo for example, some roles might be used to only for Large screen display, there is not interaction

    let current_time = new Date().getTime();
    let last_time = this.last_active_time ? this.last_active_time : new Date().getTime();
    let session_length = 15 * 60 * 1000; // Session Length right now is 15 minutes
    if(current_time - last_time > session_length){ //Check if expired
      alert("Session expired please login again!");
      this.logout();
    }
  }
  logout = () => {
    // Todo may need to specifically remove the storage items
    localStorage.clear();
    this.setRole('');
    // window.location.reload();
    this.props.history.push("/login");

    // todo, need to call backend to logout
  }
}

export default withRouter(withStyles(appStyle)(App));

 import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Toolbar, withStyles, Typography, IconButton } from '@material-ui/core'
import {
    Search
} from "@material-ui/icons";
import TableSearch from './TableSearch'

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
        display: "flex",
        alignItems: "center"
    },
    button: {
        margin: theme.spacing(1),
    },
});

class EnhancedTableToolbar extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        tableTitle: PropTypes.string,
        filterable: PropTypes.bool,
        searchText: PropTypes.string,
        searchTextUpdate: PropTypes.func.isRequired,
        onSearchChange: PropTypes.func,
        headerActions: PropTypes.array,
        selected: PropTypes.instanceOf(Map),
    };

    static defaultProps = {
        tableTitle: '',
        selected: new Map()
    }

    constructor(props, context) {
        super(props, context);

        let showSearch = false;
        if (!props.tableTitle) showSearch = true;

        this.state = {
            showSearch: showSearch,
        };
    }

    render() {
        const { classes, tableTitle, searchText, filterable, searchTextUpdate, onSearchChange, headerActions } = this.props;

        let titleArea = (
            <div className={classes.title}>
                <Typography variant="h4" id="tableTitle">
                    {tableTitle ? tableTitle : ''}
                </Typography>
            </div>
        );

        let searchProps = {
            onSearch: searchTextUpdate,
            onHide: this.hideSearch,
            searchText: this.props.searchText
        };

        if (onSearchChange) {
            searchProps.onSearchChange = onSearchChange;
        }

        if (filterable && this.state.showSearch) {
            titleArea = (
                <TableSearch
                    {...searchProps}
                />
            );
        }

        return (
            <Toolbar
                style={{padding: 0}}
                className={classes.root}
            >
                <div style={{display: "flex",width: '100%',
                    // padding: "0 1rem"
                }}>
                    {titleArea}
                    <div className={classes.spacer} />
                    <div className={classes.actions} style={{display: 'inherit'}}>
                        {/*// need to pass filtered list to button*/}
                        {headerActions.map((elem, index)=>{
                            return (
                                <IconButton key={elem.key ? elem.key : index} style={{marginRight: '.5rem'}} onClick={elem.onClick.bind(this, this.props.selected)} color={elem.color} variant="fab" className={classes.button}>
                                    {elem.icon}
                                </IconButton>
                            );
                        })}

                        {filterable && (this.props.tableTitle) ? (
                            <IconButton
                                color='white'
                                aria-label={'Search'}
                                buttonRef={el => (this.searchButton = el)}
                                // classes={{ root: this.getActiveIcon(toolbarStyles, "search") }}
                                className={classes.button}
                                onClick={this.setActiveIcon}>
                                <Search/>
                            </IconButton>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </Toolbar>
        );
    }

    hideSearch = () => {
        this.props.searchTextUpdate(null);
        // if no table title passed in, always show search
        if (this.props.tableTitle) {
            this.setState(() => ({
                showSearch: false,
            }));

            this.searchButton.focus();
        }
    };

    setActiveIcon = () => {
        this.setState({showSearch: true});
    };

    getActiveIcon = (styles, iconName) => {
        return this.state.iconActive !== iconName ? styles.icon : styles.iconActive;
    };
}

export default EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

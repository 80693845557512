import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router'
import axios from 'axios';
import SelectCustWareDialog from 'views/Dialogs/SwitchWarehouse';
import {
  withStyles,
  Table, TableHead, TableCell, TableBody, TableRow,
  Grid, Typography, IconButton, Divider, Paper, Card, CardHeader, CardContent, CardActionArea, Hidden
} from "@material-ui/core";
import {
  CustomIcon,
  CardContainer,
  CardIcon,
  Button,
  DataTable,
  GridItem,
} from "components";
import {
  Search,
  AssignmentTurnedIn,
  ShoppingBasket,
  HourglassEmpty,
  SwapVerticalCircle,
  FlightTakeoff,
  FlightLand,
  Undo,
  DeleteForever
} from "@material-ui/icons";
import moment from 'moment-timezone'
import utils from 'utils/utils'
import { withAlert } from 'hoc'
import dashboardStyle from "variables/styles/dashboardStyle.jsx";

class Dashboard extends React.Component {
  static propTypes = {
    alert: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    let dialog  = '';
    if (!localStorage.getItem('warehouse_id')) {
      dialog = 'select_warehouse';
    }

    this.state = {
      orders: [],
      status_code: "",
      order_type: "",
      b2c_data: {},
      b2b_data: {},
      inbound_data: {},
      return_data: {},
      balance: {},
      rejected_orders: [],
      rejected_products: [],
      dialog: dialog,
      loading: false,
    };

    document.title = "Dashboard";
  }

  render() {
    const { classes } = this.props;
    let loadingBar = null;
    if (this.state.loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid className={classes.root} container spacing={3} >
        {loadingBar}

        {this.renderDialog()}

        {this.renderDashboardStats()}
        {this.renderBalance()}
        {this.renderDashboardOrders()}

        {this.renderRejectedOrders()}
        {this.renderRejectedProducts()}
      </Grid>
    );
  }

  componentWillMount() {
    this.loadData();
  }

  // api call
  // load dashboard data (stats and rejected order and products)
  loadData = () => {
    let customer = localStorage.getItem('customer_id');
    let warehouse = localStorage.getItem('warehouse_id');
    if (!customer || !warehouse) {
      // if customer of warehouse is not set, don't make ajax call
      // this.alert("Customer id or Warehouse id is not set, please select warehouse or login again");
      return;
    }

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': customer,
      'warehouse': warehouse
    };
    const API_URL = base + '/api/customer/v1/dashboard';

    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });

    req.then(this.LoadDashboardSuccess).catch(this.LoadDashboardError);
    this.setState({loading: true, customerDetail: null});
  }
  LoadDashboardSuccess = (resp) => {
    this.setState({loading: false});

    if (resp.data.Error) {
      if (resp.data.Error.message) this.props.alert(resp.data.Error.message);
      else this.props.alert(resp.data.Error);
      return;
    }

    this.setState({
      b2c_data: resp.data.b2c ? resp.data.b2c : {},
      b2b_data: resp.data.b2b ? resp.data.b2b : {},
      inbound_data: resp.data.inbound ? resp.data.inbound : {},
      return_data: resp.data.return ? resp.data.return : {},
      balance: resp.data.balance,
      rejected_orders: resp.data.reject_order,
      rejected_products: resp.data.reject_product,
    });
  }
  LoadDashboardError = (err)=>{utils.generalAjaxErrorHandler(this, err, "Load dashboard error.")}
  // load dashboard orders
  loadOrders = (status, order_type) => {

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };

    const API_URL = base + '/api/customer/v1/dashboard';
    let req = axios({
      method: 'post',
      url: API_URL,
      data: {
        status,
        order_type
      },
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.loadOrdersSuccess.bind(this, order_type, status)).catch(this.loadOrdersError);
  }
  loadOrdersSuccess = (order_type, status_code,  resp) => {
    this.setState({loading: false});
    resp = resp.data;
    if (resp.Error) {
      this.props.alert(resp.Error);
      return;
    }
    if (!resp) {
      this.props.alert("Internal server error");
      return;
    }
    if (Array.isArray(resp) && resp.length < 1) {
      this.props.alert("No data found.");
      return;
    }
    console.log(resp);

    this.setState({ orders: resp, order_type, status_code});
  }
  loadOrdersError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
  // clear up rejected orders
  clearRejectedOrders = () => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/removelog';

    let req = axios({
      method: 'post',
      data: {
        type: 'order',
        id: "NONE",
      },
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.clearRejectedOrdersSuccess).catch(this.clearRejectedOrdersError);
  }
  clearRejectedOrdersSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.props.alert(data.Error);
      return;
    }

    if (data) {
      this.setState({rejected_orders: []});
    }
  }
  clearRejectedOrdersError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
  // delete single Rejected Order
  deleteRejectedOrderSingle = (order, index) => {
    let rejected_orders = this.state.rejected_orders;
    let delete_order = Object.assign({}, rejected_orders[index]);
    if (!delete_order || delete_order.id !== order.id) {
      for (let i = 0; i < rejected_orders.length;i++) {
        if (rejected_orders[i].id === order.id) {
          index = i;
          break;
        }
      }
    }

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/removelog';

    let req = axios({
      method: 'post',
      data: {
        type: 'order',
        id: rejected_orders[index].id,
      },
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.deleteRejectedOrderSingleSuccess.bind(this, index)).catch(this.deleteRejectedOrderSingleError);
  }
  deleteRejectedOrderSingleSuccess = (index, resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.props.alert(data.Error);
      return;
    }

    if (data) {
      // delete success
      // remove this order from table
      let new_orders = Array.from(this.state.rejected_orders);
      new_orders.splice(index, 1);
      this.setState({rejected_orders: new_orders});
    }
  }
  deleteRejectedOrderSingleError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
  // clear up rejected products
  clearRejectedProducts = () => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/removelog';

    let req = axios({
      method: 'post',
      data: {
        type: 'product',
        id: "NONE",
      },
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.clearRejectedProductsSuccess).catch(this.clearRejectedProductsError);
  }
  clearRejectedProductsSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.props.alert(data.Error);
      return;
    }

    if (data) {
      this.setState({rejected_products: []});
    }
  }
  clearRejectedProductsError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
  // delete single Rejected Product
  deleteRejectedProductSingle = (product, index) => {
    let rejected_products = this.state.rejected_products;
    let delete_product = Object.assign({}, rejected_products[index]);
    if (!delete_product || delete_product.id !== product.id) {
      for (let i = 0; i < rejected_products.length;i++) {
        if (rejected_products[i].id === product.id) {
          index = i;
          break;
        }
      }
    }

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/removelog';

    let req = axios({
      method: 'post',
      data: {
        type: 'product',
        id: rejected_products[index].id,
      },
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.deleteRejectedProductSingleSuccess.bind(this, index)).catch(this.deleteRejectedProductSingleError);
  }
  deleteRejectedProductSingleSuccess = (index, resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.props.alert(data.Error);
      return;
    }

    if (data) {
      // delete success
      // remove this product from table
      let new_products = Array.from(this.state.rejected_products);
      new_products.splice(index, 1);
      this.setState({rejected_products: new_products});
    }
  }
  deleteRejectedProductSingleError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

  renderDashboardStats = () => {
    const { inbound_data, return_data } = this.state;

    return <React.Fragment>
      {this.renderOrderStatus('b2c')}
      {this.renderOrderStatus('b2b')}
      {this.renderSingleStatCard(return_data, 'Return', "")}
      {this.renderSingleStatCard(inbound_data, 'Inbound', "")}
    </React.Fragment>;
  }
  renderOrderStatus = (type) => {
    const { classes } = this.props;
    let data_source = this.state.b2c_data;
    if (type === "b2b") data_source = this.state.b2b_data;

    let received_content = this.renderSingleStatCard(data_source[2], "Received", type);
    let prepared_content = this.renderSingleStatCard(data_source[7], "Prepared", type);
    let shipped_content = this.renderSingleStatCard(data_source[9], "Shipped", type);
    let onhold_content = this.renderSingleStatCard(data_source[5], "Onhold", type);

    let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

    let content_large = <React.Fragment>
      <tr key="0">
        <td style={{border: "1px solid #ccc"}}>{received_content}</td>
        <td style={{border: "1px solid #ccc"}}> {prepared_content}</td>
      </tr>
      <tr key="1">
        <td style={{border: "1px solid #ccc"}}>{shipped_content}</td>
        <td style={{border: "1px solid #ccc"}}>{onhold_content}</td>
      </tr>
    </React.Fragment>;
    let content_small = <React.Fragment>
      <tr key="0">
        <td style={{border: "1px solid #ccc"}}>{received_content}</td>
      </tr>
      <tr key="1">
        <td style={{border: "1px solid #ccc"}}> {prepared_content}</td>
      </tr>
      <tr key="2">
        <td style={{border: "1px solid #ccc"}}>{shipped_content}</td>
      </tr>
      <tr key="3">
        <td style={{border: "1px solid #ccc"}}>{onhold_content}</td>
      </tr>
    </React.Fragment>;

    return (
      <GridItem key={type} xs={12} sm={12} md={6}>
        <CardContainer noPadding>
          <CardContent>
            <div className={classes.statCardContainerHeader}>{type === 'b2b' ? "Wholesale" : "Retail"}</div>
          </CardContent>
          {/*<table style={{borderCollapse: "collapse", borderStyle: "hidden", width: "100%", borderTop: "5px solid #3AB0AC"}}>*/}
          {/* <tbody>*/}

          {/* <Hidden xsDown>*/}
          {/*   {content_large}*/}
          {/* </Hidden>*/}

          {/* <Hidden smUp>*/}
          {/*   {content_small}*/}
          {/* </Hidden>*/}

          {/* </tbody>*/}
          {/*</table>*/}

          <div className={classes.flexContainer}>
            <div className={classes.flexItem} style={{border: "1px solid #ccc"}}>{received_content}</div>
            <div className={classes.flexItem} style={{border: "1px solid #ccc"}}> {prepared_content}</div>
            <div className={classes.flexItem} style={{border: "1px solid #ccc"}}>{shipped_content}</div>
            <div className={classes.flexItem} style={{border: "1px solid #ccc"}}>{onhold_content}</div>
          </div>

        </CardContainer>
      </GridItem>
    );
  }
  renderSingleStatCard = (data, key, order_type) => {
    const { classes } = this.props;
    let click_disabled = false;

    let status_code = 2;
    if (!data) data = {order_num: 0, order_quantity: 0, retail_value: 0};
    if (!parseInt(data.order_num)  && !parseInt(data.order_quantity) && !parseInt(data.retail_value)) click_disabled = true;

    let icon = null;

    switch (key) {
      case "Received":
        // icon = <CustomIcon iconName="received_small" className={classes.statIcon} />;
        icon = <ShoppingBasket style={{color: "#D24B90"}} className={classes.statIcon} />;
        status_code = 2;
        break;
      case "Prepared":
        // icon = <CustomIcon iconName="prepared_small" className={classes.statIcon} />;
        icon = <AssignmentTurnedIn style={{color: "#30C4EE"}} className={classes.statIcon} />;
        status_code = 7;
        break;
      case "Shipped":
        // icon = <CustomIcon iconName="shipped_small" className={classes.statIcon} />;
        icon = <FlightTakeoff style={{color: "#8791C8"}} className={classes.statIcon} />;
        status_code = 9;
        break;
      case "Onhold":
        // icon = <CustomIcon iconName="onhold_small" className={classes.statIcon} />;
        icon = <HourglassEmpty style={{color: "#D24B9B"}} className={classes.statIcon} />;
        status_code = 5;
        break;
      // Individual Card Area for Inbound and Return
      case "Return":
        status_code = 13;
        return (
          <GridItem xs={12} md={6}>
            <CardContainer allowOverflow noPadding>
              <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                <div className={classes.statReturnCard}  onClick={()=>{if (click_disabled) return; this.loadOrders(status_code, order_type);}}>
                  {/*<CardIcon style={{backgroundColor: "#17a689"}} className={classes.statReturnCardIcon}>*/}
                  {/*  <Undo className={classes.statReturnIcon} />*/}
                  {/*</CardIcon>*/}

                  <div className={classes.floatingIconContainer}>
                    {/*<CardIcon style={{backgroundColor: "#F17022"}} className={classes.statReturnCardIcon}>*/}
                    {/*  <CustomIcon iconName="returned" className={classes.customCardIcon} />*/}
                    {/*</CardIcon>*/}
                    <CardIcon style={{
                      backgroundColor: "#F17022"
                      // backgroundColor: "#17a689"
                    }} className={classes.statReturnCardIcon}>
                      <Undo className={classes.statReturnIcon} />
                    </CardIcon>
                  </div>

                  <CardContent style={{
                    // backgroundColor: "#1abc9c"
                  }} className={classes.statReturnValueContainer}>
                    <table className={classes.statReturnTable}>
                      <tbody>
                      {/*<tr>*/}
                      {/*  <td className={classes.statLabelCell}>Retail Value:</td>*/}
                      {/*  <td className={classes.statValeCell}>{"$" + utils.numberWithComma(data.retail_value)}</td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*  <td className={classes.statLabelCell}>{key} Order | Unit:</td>*/}
                      {/*  <td className={classes.statValeCell}>{utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}</td>*/}
                      {/*</tr>*/}

                      <tr>
                        <td style={{
                          // color: "white"
                        }} className={classes.statLabelCell}>{key} Value | Order | Unit:</td>
                      </tr>
                      <tr>
                        <td className={classes.statValeCell}>{"$" + utils.numberWithComma(data.retail_value)} | {utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}</td>
                      </tr>

                      </tbody>
                    </table>
                  </CardContent>
                </div>
              </CardActionArea>
            </CardContainer>
          </GridItem>
        );
      case "Inbound":
        return (
          <GridItem xs={12} md={6}>
            <CardContainer allowOverflow noPadding>
              <CardActionArea className={(classes.clickEnabled)}>
                <div className={classes.statReturnCard}>
                  {/*<CardIcon style={{*/}
                  {/*  backgroundColor: "#29b765"*/}
                  {/*}} className={classes.statReturnCardIcon}>*/}
                  {/*  <FlightLand className={classes.statReturnIcon} />*/}
                  {/*</CardIcon>*/}

                  <div className={classes.floatingIconContainer}>
                    {/*<CardIcon style={{backgroundColor: "#8791C8"}} className={classes.statReturnCardIcon}>*/}
                    {/*  <CustomIcon iconName="arrived" className={classes.customCardIcon} />*/}
                    {/*</CardIcon>*/}

                    <CardIcon style={{
                      backgroundColor: "#8791C8"
                      // backgroundColor: "#29b765"
                    }} className={classes.statReturnCardIcon}>
                      <FlightLand className={classes.statReturnIcon} />
                    </CardIcon>
                  </div>

                  <CardContent className={classes.statReturnValueContainer}>
                    <table className={classes.statReturnTable}>
                      <tbody>
                      {/*<tr>*/}
                      {/*  <td className={classes.statLabelCell}>{key} Order | Unit:</td>*/}
                      {/*  <td className={classes.statValeCell}>{utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}</td>*/}
                      {/*</tr>*/}

                      <tr>
                        <td style={{
                          // color: "white"
                        }} className={classes.statLabelCell}>Inbounds | Unit:</td>
                      </tr>
                      <tr>
                        <td className={classes.statValeCell}>{"$" + utils.numberWithComma(data.retail_value)} | {utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}</td>
                      </tr>
                      </tbody>
                    </table>
                  </CardContent>
                </div>
              </CardActionArea>
            </CardContainer>
          </GridItem>
        );
    }

    return (
      <div className={classes.statCard}>
      <CardActionArea className={classes.statCardAction + " " + (click_disabled ? classes.clickDisabled : classes.clickEnabled)}  onClick={()=>{if (click_disabled) return; this.loadOrders(status_code, order_type);}}>
        <CardIcon className={classes.statIconContainer}>
          {icon}
        </CardIcon>

        <div style={{display: "flex", flexDirection: "column", flexGrow: "1"}}>
          {/*<div className={classes.statDescContainer}>*/}
          {/*  <div className={classes.statLabelCell}>*/}
          {/*    Retail Value:*/}
          {/*  </div>*/}
          {/*  <div className={classes.statValeCell}>*/}
          {/*    {"$" + utils.numberWithComma(data.retail_value)}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className={classes.statDescContainer}>*/}
          {/*  <div className={classes.statLabelCell}>*/}
          {/*    {key} Order | Unit:*/}
          {/*  </div>*/}
          {/*  <div className={classes.statValeCell}>*/}
          {/*    {utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className={classes.statDescContainer}>
            <div className={classes.statLabelCell}>
              {key} Value | Order | Unit:
            </div>
            <div className={classes.statValeCell}>
              {"$" + utils.numberWithComma(data.retail_value) + " | " + utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}
            </div>
          </div>

        </div>
        {/*<table className={classes.statTable}>*/}
        {/*  <tbody>*/}
        {/*  <tr>*/}
        {/*    <td className={classes.statLabelCell}>Retail Value:</td>*/}
        {/*    <td className={classes.statValeCell}>{"$" + utils.numberWithComma(data.retail_value)}</td>*/}
        {/*  </tr>*/}
        {/*  <tr>*/}
        {/*    <td className={classes.statLabelCell}>{key} Order | Unit:</td>*/}
        {/*    <td className={classes.statValeCell}>{utils.numberWithComma(data.order_num)  + " | " + utils.numberWithComma(data.order_quantity)}</td>*/}
        {/*  </tr>*/}
        {/*  </tbody>*/}
        {/*</table>*/}

      </CardActionArea>
    </div>);
  }
  renderBalance = () => {
    const { classes } = this.props;
    const { balance } = this.state;

    let curr_date = moment().format('MMM DD, YYYY');
    let days_14_content = balance.days_14 ? utils.numberWithComma(balance.days_14, 2) : "N/A";
    let days_30_content = balance.days_30 ? utils.numberWithComma(balance.days_30, 2) : "N/A";
    let days_60_content = balance.days_60 ? utils.numberWithComma(balance.days_60, 2) : "N/A";
    let overdays_60_content = balance.overdays_60 ? utils.numberWithComma(balance.overdays_60, 2) : "N/A";
    let total_content = balance.total ? "$" + utils.numberWithComma(balance.total, 2) : "N/A";

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div className={classes.tableContainer}>
            <div className={classes.title} style={{textAlign: "center"}}>
              <Typography variant="h4" id="tableTitle">
                Account Balance as of {curr_date}
              </Typography>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>0-14 Days</TableCell>
                  <TableCell>15-30 Days</TableCell>
                  <TableCell>31-60 Days</TableCell>
                  <TableCell>60+</TableCell>
                  <TableCell>TOTAL</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>{days_14_content}</TableCell>
                  <TableCell>{days_30_content}</TableCell>
                  <TableCell>{days_60_content}</TableCell>
                  <TableCell>{overdays_60_content}</TableCell>
                  <TableCell>{total_content}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderDashboardOrders = () => {
    const { classes } = this.props;
    const { orders, status_code, order_type } = this.state;
    let rows = Array.from(orders);

    if (!orders || orders.length === 0 || !order_type || !status_code) return null;

    let colSettings = [
      {
        key: 'order_key',
        label: 'OrderId',
        render: (val, key, row)=>{
          return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span style={{marginRight: '.5rem'}}>{val}</span>
              <Search  style={{cursor: 'pointer'}} title='Click to see order detail' onClick={()=>{
                localStorage.setItem("dbOrder", row.order_id);
                this.props.history.push("/manageOrder");
              }}>sss</Search>
            </div>
          );
        },
      }, {
        key: 'method',
        label: 'ShippingMethod',
        render: utils.convertShippingMethod
      }, {
        key: 'name',
        label: 'Name',
      }, {
        key: 'sku',
        label: 'SKU',
      },  {
        key: 'unit_value',
        label: 'UnitValue',
      },{
        key: 'quantity',
        label: 'Quantity',
      },{
        key: 'created_dt',
        label: 'Date',
        render: utils.formatDate
      }
    ];

    // utils.convertStatus(status_code) might need to change code
    let order_status = this.convertStatus(status_code);

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div className={classes.title} style={{height: '3rem'}}>
            <Typography variant="h4" id="tableTitle">
              { order_type.toUpperCase() + " " + order_status} Orders
              <Button style={{float: 'right'}} onClick={()=>{utils.export_table_to_csv("db_order",  `dashboard_${order_type}_${order_status}_order`);}}>Export</Button>
            </Typography>
          </div>

          <DataTable
            data={rows}
            withoutPaper
            className={classes.table}
            tableSettings={{
              maxHeight: '500px',
              tableProps: {
                id: "db_order",
              },
            }}
            colSettings={colSettings}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderRejectedOrders = () => {
    const { classes } = this.props;
    const { rejected_orders } = this.state;
    let rows = [];
    if (rejected_orders) {
      rows = Array.from(rejected_orders);
    }

    let colSettings = [
      {
        key: 'order_key',
        label: 'Order Id',
      }, {
        key: 'text',
        label: 'Error',
      }, {
        key: 'created_dt',
        label: 'Date',
        render: utils.formatDate,
      },
      {
        key: 'delete',
        label: 'Delete',
        render: (val, key, row, index) => {
          return (
            <IconButton onClick={()=>{
              this.props.confirm(
                "Are you sure to delete this error log?",
                this.deleteRejectedOrderSingle.bind(this, row, index))
            }} style={{backgroundColor: "red"}} variant="fab" aria-label="Delete">
              {/*<CustomIcon iconName='remove'/>*/}
              <DeleteForever style={{color: 'white'}}/>
            </IconButton>
          );
        }
      }
    ];

    return (
      <GridItem xs={12} md={6}>
        <CardContainer>
          <div className={classes.title} style={{height: '3rem'}}>
            <Typography variant="h4" id="tableTitle">
              Rejected Orders
              {rows.length > 0 && <Button style={{float: 'right'}} onClick={()=>{
                this.props.confirm("Are you sure to clear all rejected orders?", this.clearRejectedOrders)
              }}>Clear All</Button>}
            </Typography>
          </div>

          <DataTable
            data={rows}
            tableSettings={{
              maxHeight: '400px',
              tableProps: {
                id: "rejected_orders",
              },
            }}
            withoutPaper
            colSettings={colSettings}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderRejectedProducts = () => {
    const { classes } = this.props;
    const { rejected_products } = this.state;
    let rows = [];
    if (rejected_products) {
      rows = Array.from(rejected_products);
    }

    let colSettings = [
      {
        key: 'sku',
        label: 'SKU',
      }, {
        key: 'text',
        label: 'Error',
      }, {
        key: 'created_dt',
        label: 'Date',
        render: utils.formatDate,
      },
      {
        key: 'delete',
        label: 'Delete',
        render: (val, key, row, index) => {
          return (
            <IconButton onClick={()=>{
              this.props.confirm(
                "Are you sure to delete this error log?",
                this.deleteRejectedProductSingle.bind(this, row, index))
            }} style={{backgroundColor: "red"}} variant="fab" aria-label="Delete" className={classes.button}>
              <DeleteForever style={{color: 'white'}}/>
              {/*<CustomIcon iconName='remove'/>*/}
            </IconButton>
          );
        }
      }
    ];

    return (
      <GridItem xs={12} md={6}>
        <CardContainer>
          <div className={classes.title} style={{height: '3rem'}}>
            <Typography variant="h4" id="tableTitle">
              Rejected Products
              {rows.length > 0 && <Button style={{float: 'right'}} onClick={()=>{
                this.props.confirm("Are you sure to clear all rejected products?", this.clearRejectedProducts)
              }}>Clear All</Button>}
            </Typography>
          </div>

          <DataTable
            data={rows}
            tableSettings={{
              maxHeight: '400px'
            }}
            withoutPaper
            colSettings={colSettings}
          />
        </CardContainer>


      </GridItem>
    );
  }
  renderDialog = () => {
    const { dialog} = this.state;
    let render_content = null;
    switch (dialog) {
      case 'select_warehouse':
        render_content = (
          <SelectCustWareDialog
            display={true}
            closeDialog={()=>{this.setState({dialog: ''});}}
          />
        );
        break;
    }
    return render_content;
  }

  // Utils
  convertStatus = (code) => {
    let method_map = {
      2:"Received",
      3:"Deleted",
      5: "On Hold",
      6:"Cancelled",
      7:"Prepared",
      8:"Prepared",
      9:"Shipped",
      12:"Verified",
      13:"Returned",
      14: "Inbound",
    };
    return method_map[code] ? method_map[code] : code;
  }
}

export default withAlert(withRouter(withStyles(dashboardStyle)(Dashboard)));


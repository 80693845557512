import React, { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from 'prop-types'
import axios from 'axios';
import utils from 'utils/utils'
import _ from 'lodash'
import {
  withStyles, Grid, Container, Avatar, Paper, Link, CssBaseline, TextField, FormControl, FormControlLabel, FormHelperText, Box, Typography,
  Stepper, Step, StepLabel, StepContent
} from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import {
  Button,
  GridItem,
  CustomInput,
  LoadingBar,
  PopupAlert
} from "components";
import { LockOutlined } from '@material-ui/icons'

const styles = theme => ({
  containerPaper: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%"
  },
  stepper: {
    margin: theme.spacing(1, 0),
    backgroundColor: "inherit"
  },
  resendLink: {
    cursor: "pointer",
  },
  loginLink: {
    textAlign: "center"
  },
  disabledResendLink: {
    cursor: "not-allowed",
    color: "gray"
  },
  inputLabel: {
    fontWeight: "bold"
  }
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://boxzooka.com">
        Boxzooka
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export class ResetPassword extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      username: "",
      username_error: "",
      password: "",
      active_step: 0,
      password_confirm: "",
      password_error: "",
      feedback_msg: "",
      feedback_type : "",
      loading: false,
      verification_code: "",
      resend_countdown: 0,
      countdown_interval: null,
      // Pop up alert
      message_type: "",
      message_content: "",
    };

    document.title = "Reset Password";
  }

  render() {
    const { classes } = this.props;
    const { active_step, feedback_msg, feedback_type, loading, username, username_error, password, password_confirm, password_error, verification_code, resend_countdown } = this.state;

    let messageField = null;
    if (feedback_msg) {
      messageField = (
        <div style={{width: "100%", padding: "1.5rem 0"}}>
          <Alert
            variant="filled"
            severity={feedback_type ? feedback_type : "error"}
          >
            {feedback_msg}
          </Alert>
        </div>
      );
    }

    let btn_resend = (
      <Link onClick={this.resendEmail} className={classes.resendLink} variant="body2">
        (Didn't receive the email? Click to resend.)
      </Link>
    );

    if (resend_countdown > 0) {
      btn_resend = (
        <Link className={classes.disabledResendLink} variant="body2">
          ({resend_countdown} seconds to resend email)
        </Link>
      );
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {loading ? <LoadingBar/> : null}

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h4">
            Reset Password
          </Typography>

          {messageField}

          <Stepper activeStep={active_step} orientation="vertical" className={classes.stepper}>
            <Step>
              <StepLabel>{!!username && active_step > 0 ? "Reset password for " + username : "Enter Username"}</StepLabel>
              <StepContent>
                <form className={classes.form} onSubmit={this.resendEmail}>
                  <Typography >
                    Forgot your password? Please enter your username. You will receive a temp password via email.
                  </Typography>

                  <FormControl fullWidth error={!!username_error}>
                    <TextField
                      // variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Username"
                      InputLabelProps={{shrink: true, className: classes.inputLabel}}
                      name="username"
                      autoComplete="username"
                      autoFocus
                      onChange={(e)=>{this.setState({username: e.target.value})}}
                      value={username}
                    />
                    {username_error && <FormHelperText>{username_error}</FormHelperText>}
                  </FormControl>

                  <div className={classes.actionsContainer}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              </StepContent>
            </Step>

            <Step>
              {/*<StepLabel>Create your new password</StepLabel>*/}
              <StepLabel>Use the temp password to login</StepLabel>
              <StepContent>
                <form className={classes.form}
                      // onSubmit={this.resetPassword}
                      onSubmit={this.login}
                >
                  <Typography>
                    {/*A temp password has been sent to your email, enter it below to create a new password.*/}
                    A temp password has been sent to your email, enter it below to login. After login, you can go to Profile page to create a new password.
                    {btn_resend}
                  </Typography>

                  <TextField
                    // variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    name="Temp Password"
                    InputLabelProps={{shrink: true, className: classes.inputLabel}}
                    label="Temp Password"
                    // type="password"
                    onChange={(e)=>{this.setState({verification_code: e.target.value})}}
                    value={verification_code}
                  />

                  {/*<TextField*/}
                  {/*  // variant="outlined"*/}
                  {/*  margin="dense"*/}
                  {/*  required*/}
                  {/*  fullWidth*/}
                  {/*  InputLabelProps={{shrink: true}}*/}
                  {/*  name="New Password"*/}
                  {/*  label="New Password"*/}
                  {/*  type="password"*/}
                  {/*  onChange={this.handlePasswordChange.bind(this, "password")}*/}
                  {/*  value={password}*/}
                  {/*/>*/}
                  {/*<FormControl fullWidth error={!!password_error}>*/}
                  {/*  <TextField*/}
                  {/*    // variant="outlined"*/}
                  {/*    margin="dense"*/}
                  {/*    required*/}
                  {/*    fullWidth*/}
                  {/*    InputLabelProps={{shrink: true}}*/}
                  {/*    name="Confirm New Password"*/}
                  {/*    label="Confirm New Password"*/}
                  {/*    type="password"*/}
                  {/*    onChange={this.handlePasswordChange.bind(this, 'password_confirm')}*/}
                  {/*    value={password_confirm}*/}
                  {/*  />*/}

                  {/*  {password_error && <FormHelperText>{password_error}</FormHelperText>}*/}
                  {/*</FormControl>*/}

                  <div className={classes.actionsContainer}>
                    <Button
                      onClick={()=> {this.setState({ active_step: active_step - 1 });}}
                      className={classes.button}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Login
                    </Button>
                  </div>
                </form>
              </StepContent>
            </Step>
          </Stepper>

          <Grid container className={classes.loginLink}>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Remember your password? Click here to login
              </Link>
            </Grid>
          </Grid>
        </div>

        <Box mt={8}>
          <Copyright />
        </Box>

        {this.renderAlert()}
      </Container>
    );
  }

  componentWillUnmount() {
    clearInterval(this.state.countdown_interval);
  }

  renderAlert = () => {
    const { message_type, message_content } = this.state;

    if (!message_content) return;

    return (
      <PopupAlert
        open={true}
        header={message_type}
        type={message_type}
        content={message_content}
        onClose={this.closeAlert}
      />
    );
  }
  closeAlert = ()=>{
    this.setState({message_type: "", message_content: "" })
  }
  alert = (message, type) => {
    if (!type) type = "Error";
    this.setState({message_content: message, message_type: type});
  }

  handlePasswordChange = (key, e) => {
    let pwd = this.state.password;
    let pwd_cfm = this.state.password_confirm;
    if (key === "password") pwd = e.target.value;
    else  pwd_cfm = e.target.value;

    let pwd_err = "";
    if (pwd !== pwd_cfm) pwd_err = "New Password and Confirm New Password don't match";
    this.setState({password: pwd, password_confirm: pwd_cfm, password_error: pwd_err});
  }

  resendEmail = (e) => {
    e.preventDefault();
    const { username } = this.state;
    // todo might need to format username remove special char
    let formatted_username = username;
    // Todo might not allow - and _
    let regex = new RegExp(/[^a-zA-Z0-9-_]/g);
    if (regex.test(username)) {
      // Todo might not allow - and _
      this.setState({username_error: "Username can only contain letters, number, underscore and hyphen"});
      return;
    }
    // formatted_username = formatted_username.replace(regex, "");

    this.resendEmailOnce(formatted_username);
  }
  resendEmailAjax= (username) => {
    let base = this.context.data.getBase();
    const API_URL = base + '/api/customer/v1/resetpassword/' + username;
    let req = axios.get(API_URL);
    req.then(this.resendEmailSuccess).catch(this.resendEmailError);
    this.setState({loading: true, feedback_msg: ''});
  }
  resendEmailOnce = _.once(this.resendEmailAjax);
  resendEmailSuccess = (resp) => {
    this.resendEmailOnce = _.once(this.resendEmailAjax);
    this.setState({loading: false});

    if (resp.data.Error) {
      let msg = resp.data.Error;
      this.setState({ feedback_msg: msg, feedback_type: "error" });
      return;
    }

    if (resp.data) {
      if (this.state.countdown_interval) clearInterval(this.state.countdown_interval);
      let countdown_interval = setInterval(()=>{
        if (this.state.resend_countdown > 0) {
          this.setState({resend_countdown: this.state.resend_countdown-1});
        }
      }, 1000);
      this.setState({
        feedback_msg: "A new verification code has been sent to your email",
        feedback_type: "success",
        resend_countdown: 60,
        active_step: this.state.active_step + 1,
        countdown_interval
      });
    } else {
      this.setState({ error: "Internal Server Error" });
    }
  }
  resendEmailError = (err) => {
    this.resendEmailOnce = _.once(this.resendEmailAjax);
    this.setState({loading: false});
    let msg = "Internal server error";
    alert(msg);
  }

  login = (e) => {
    e.preventDefault();
    const { username, verification_code } = this.state;

    this.loginOnce({
      username,
      password: verification_code,
    });
  }
  loginAjax = (data) => {
    let base = this.context.data.getBase();
    const API_URL = base + '/api/customer/v1/login';

    let req = axios.post(API_URL,
      data);

    req.then(this.loginSuccess).catch(this.loginError);
    this.setState({loading: true, feedback_msg: ''});
  }
  loginOnce = _.once(this.loginAjax);
  loginSuccess = (resp) => {
    this.loginOnce = _.once(this.loginAjax);
    this.setState({loading: false});

    if (resp.data.Error) {
      let msg = resp.data.Error;
      this.setState({ feedback_msg: msg });
      return;
    }

    // User just reset his password, should not verify access code
    // if (resp.data.check_ip) {
    //   if (this.state.countdown_interval) clearInterval(this.state.countdown_interval);
    //   let countdown_interval = setInterval(()=>{
    //     if (this.state.resend_countdown > 0) {
    //       this.setState({resend_countdown: this.state.resend_countdown-1});
    //     }
    //   }, 1000);
    //
    //   this.setState({
    //     feedback_msg: "You are logging in from a different IP, access code needed, please check your email",
    //     feedback_type: "error",
    //     verification_required: true,
    //     resend_countdown: 60,
    //     countdown_interval
    //   });
    //   return;
    // }

    // set the tokens
    let account = resp.data;
    let default_warehouse = "";
    let warehouse_detail = {};
    if (Array.isArray(account.warehouse_detail)) {
      for (let warehouse of account.warehouse_detail) {
        warehouse_detail[warehouse.warehouse_id] = warehouse.name;
      }
    } else {
      warehouse_detail = account.warehouse_detail;
    }
    // If warehouse list has only one warehouse, set it to default warehouse
    let warehouse_id_list = Object.keys(warehouse_detail);
    if (warehouse_id_list.length === 1) {
      default_warehouse = warehouse_id_list[0];
    }

    // set the tokens
    localStorage.setItem('token', account.token);
    localStorage.setItem('user_id', account.user_id);
    localStorage.setItem('username', account.user_name);
    localStorage.setItem('role', account.role);
    localStorage.setItem('customer_id', (account.customer_id ? account.customer_id : ''));
    localStorage.setItem('customer_name', (account.customer_id ? account.name : ''));
    localStorage.setItem('warehouse_id', (account.warehouse_id ? account.warehouse_id : default_warehouse));


    let customer_detail = (account.customer_detail && !_.isEmpty(account.customer_detail)) ? account.customer_detail : null;
    if (account.customer_detail) localStorage.setItem('customer_detail', JSON.stringify(customer_detail));

    localStorage.setItem('warehouse_detail', JSON.stringify(warehouse_detail));
    localStorage.setItem('menu_list', JSON.stringify(account.permission));
    let selectMode = 'w';
    if (account.warehouse_id) selectMode = 'c';
    localStorage.setItem('select_mode', selectMode);

    this.context.data.setRole(account.role);
    this.props.history.push("/dashboard");
    // Todo move this back when ready to add session timeout feature
    // this.context.data.registerSessionCheck();
    // window.location.reload();
  }
  loginError = (err) => {
    this.loginOnce = _.once(this.loginAjax);
    this.setState({loading: false});
    let msg = '';
    if (err.response) {
      msg = err.response.data.Error;
    } else {
      msg = 'Internal Server Error';
    }
    this.setState({feedback_msg: msg,  feedback_type: "error",});
  }

  // resetPassword = (e) => {
  //   e.preventDefault();
  //   const { verification_code, password } = this.state;
  //   // Todo might not allow - and _
  //   let regex = new RegExp(/[^a-zA-Z0-9-_]/g);
  //   // Todo might not allow - and _
  //   if (regex.test(password)) {
  //     this.setState({feedback_msg: "Password can only contain letters, number, underscore and hyphen", feedback_type: "error"});
  //     return;
  //   }
  //
  //   this.resendEmailOnce(
  //     verification_code,
  //     {
  //       password
  //   });
  // }
  // resetPasswordAjax= (temp_password, data) => {
  //   let base = this.context.data.getBase();
  //   const API_URL = base + '/api/v1/login/' + temp_password;
  //
  //   // Todo decide whether reset password or just login here
  //
  //   let req = axios.get(API_URL);
  //   req.then(this.resetPasswordSuccess).catch(this.resetPasswordError);
  //   this.setState({loading: true, feedback_msg: ''});
  // }
  // resetPasswordOnce = _.once(this.resetPasswordAjax);
  // resetPasswordSuccess = (resp) => {
  //   this.resetPasswordOnce = _.once(this.resetPasswordAjax);
  //   this.setState({loading: false});
  //
  //   if (resp.data.Error) {
  //     let msg = resp.data.Error;
  //     this.setState({ feedback_msg: msg, feedback_type: "error" });
  //     return;
  //   }
  //
  //   if (resp.data) {
  //     // Todo decide whether go login directly or navigate to login page
  //   } else {
  //     this.setState({ feedback_msg: "Internal Server Error", feedback_type: "error" });
  //   }
  // }
  // resetPasswordError = () => {
  //   this.resetPasswordOnce = _.once(this.resetPasswordAjax);
  //   this.setState({loading: false});
  //   let msg = "Internal server error";
  //   alert(msg);
  // }
}
export default withRouter(withStyles(styles)(ResetPassword));

import arrived from '../../assets/img/arrived.svg'
import avg_value from '../../assets/img/avg_value.svg'
import remove from '../../assets/img/remove.svg'
import discrepancy from '../../assets/img/discrepancy.svg'
import fulfilled from '../../assets/img/fulfilled.svg'
import location from '../../assets/img/location.svg'
import onhold_small from '../../assets/img/onhold_small.svg'
import order from '../../assets/img/order.svg'
import pending from '../../assets/img/pending.svg'
import prepared_small from '../../assets/img/prepared_small.svg'
import received from '../../assets/img/received.svg'
import received_small from '../../assets/img/received_small.svg'
import receiving from '../../assets/img/receiving.svg'
import returned from '../../assets/img/returned.svg'
import revenue from '../../assets/img/revenue.svg'
import shipped from '../../assets/img/shipped.svg'
import shipped_small from '../../assets/img/shipped_small.svg'
import side_admin from '../../assets/img/side_admin.svg'
import side_analysis from '../../assets/img/side_analysis.svg'
import side_dashboard from '../../assets/img/side_dashboard.svg'
import side_forecast from '../../assets/img/side_forecast.svg'
import side_inbound from '../../assets/img/side_inbound.svg'
import side_logout from '../../assets/img/side_logout.svg'
import side_order from '../../assets/img/side_order.svg'
import side_profile from '../../assets/img/side_profile.svg'
import side_product from '../../assets/img/side_product.svg'
import side_report from '../../assets/img/side_report.svg'
import sidebar_icon_1 from '../../assets/img/sidebar_icon_1.svg'
import sidebar_icon_2 from '../../assets/img/sidebar_icon_2.svg'
import units from '../../assets/img/units.svg'

export default {
  arrived,
  avg_value,
  remove,
  discrepancy,
  fulfilled,
  location,
  onhold_small,
  order,
  pending,
  prepared_small,
  received,
  received_small,
  receiving,
  returned,
  revenue,
  shipped,
  shipped_small,
  side_admin,
  side_analysis,
  side_dashboard,
  side_forecast,
  side_inbound,
  side_logout,
  side_order,
  side_product,
  side_profile,
  side_report,
  sidebar_icon_1,
  sidebar_icon_2,
  units
}

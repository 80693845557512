import React, { Component } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Button, CustomInput, BasicDialog } from 'components'
import {
    InputLabel,
    Select,
    MenuItem,
    withStyles,
    FormControl,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import $ from "jquery";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectInput: {
        width: '100%',
        margin: theme.spacing(1, 0),
    },
    selectEmpty: {
        margin: theme.spacing(1, 0),
    },
    customInput: {
        margin: theme.spacing(1, 0),
    },
});

class NewLabelDialog extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        display: PropTypes.bool.isRequired,
        closeDialog: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        title: "New Shipping Label"
    }

    constructor(props, context) {
        super(props, context);

        this.weight_input = null;
        this.length_input = null;
        this.width_input = null;
        this.height_input = null;

        this.state = {
            method: '',
            length: '',
            width: '',
            height: '',
            weight: '',
            signature_required: false,
            saturday_delivery: false,
        };
    }

    render() {
        const { classes, display, closeDialog, onSubmit, title } = this.props;
        const { weight, method, length, width, height, saturday_delivery, signature_required } = this.state;

        let content = (
            <form onSubmit={onSubmit}>
                <FormControl required fullWidth className={classes.selectInput}>
                    <InputLabel htmlFor="method" shrink>Shipping Method</InputLabel>
                    <Select
                        value={(method)}
                        onChange={(e)=>{this.setState({method: e.target.value})}}
                        inputProps={{
                            name: 'method',
                            id: 'method',
                        }}
                    >
                        <MenuItem value='BXZ.PKP'>Pack and Hold</MenuItem>
                        <MenuItem value='BXZ.USA.1'>Boxzooka 1-Day</MenuItem>,
                        <MenuItem value='BXZ.USA.2'>Boxzooka 2-Day</MenuItem>,
                        <MenuItem value='BXZ.USA.3'>Boxzooka 3-Day</MenuItem>,
                        <MenuItem value='BXZ.USA.5'>Boxzooka 5-Day</MenuItem>,
                        <MenuItem value='BXZ.USA.7'>Boxzooka 7-Day</MenuItem>,
                        <MenuItem value='BXZ.SAMEDAY.NYC'>BXZ Same Day</MenuItem>
                        <MenuItem value='UPS.EXP.1'>UPS Next Day Air Early(Next Business Day by 10AM)</MenuItem>
                        <MenuItem value='UPS.DOM.1'>UPS Next Day Air(Next Business Day by 2pm)</MenuItem>
                        <MenuItem value='UPS.DOM.2'>UPS Second Day Air(Second Business Day by 2pm)</MenuItem>
                        <MenuItem value='UPS.DOM.3'>UPS 3-Day Air (Third Business Day by 2pm)</MenuItem>
                        <MenuItem value='UPS.GRD.RESI'>UPS Ground (1 - 5 Business Day)</MenuItem>
                        <MenuItem value='SUREPOST'>UPS Surepost</MenuItem>
                        <MenuItem value='FDX.EXP.1'>FedEx Next Day Air Early(Next Business Day by 10AM)</MenuItem>
                        <MenuItem value='FDX.DOM.1'>FedEx Next Day Air(Next Business Day by 2pm)</MenuItem>
                        <MenuItem value='FDX.DOM.2'>FedEx Second Day Air(Second Business Day by 2pm)</MenuItem>
                        <MenuItem value='FDX.DOM.3'>FedEx 3-Day Air (Third Business Day by 2pm)</MenuItem>
                        <MenuItem value='FDX.GRD'>FedEx Ground (1 - 5 Business Day)</MenuItem>
                        <MenuItem value='FDX.HOME'>FedEx Home</MenuItem>
                        <MenuItem value='SMARTPOST'>FedEx SmartPost</MenuItem>
                        <MenuItem value='DHLEC.BPM'>DHL Bound Printed Matter Service</MenuItem>
                        {/*<MenuItem value='DHLEC.SAMEDAY'>DHL Same Day</MenuItem>*/}
                        <MenuItem value='DHLEC.MAX'>DHL 3-Day Priority (Usually Delivered W/IN 3 Calendar Days)</MenuItem>
                        <MenuItem value='DHLEC.STD.GRD'>DHL Standard Ground Shipping (2- 7 Calendar Days)</MenuItem>
                        <MenuItem value='USPS.PRIORITY' >USPS Priority</MenuItem>
                        <MenuItem value='USPS.PARCEL' >USPS ParcelSelect</MenuItem>
                        <MenuItem value='USPS.FIRST' >USPS First</MenuItem>
                        <MenuItem value='USPS.EXPRESS' >USPS Express</MenuItem>
                        <MenuItem value='FDX.INTL.ECO'>FedEx International Expedited (2-5 Days)</MenuItem>
                        <MenuItem value='UPS.INTL.EXP'>UPS International Expedited (2-5 Days)</MenuItem>
                        <MenuItem value='DHLEC.PLT'>International Standard (4-12 Days)</MenuItem>
                    </Select>
                </FormControl>

                <CustomInput
                    labelText='Package Weight'
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.customInput
                    }}
                    labelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        onChange: (e)=>{let val = e.target.value; this.setState({weight: val});},
                        onKeyUp: (e)=>{
                            if (e.key === "Enter") {
                                $(this.length_input).focus();
                            }
                        },
                        value: weight,
                        inputRef: (elem)=>{this.weight_input = elem}
                    }}
                />

                <CustomInput
                    labelText='Package Length'
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.customInput
                    }}
                    labelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        onChange: (e)=>{let val = e.target.value; this.setState({length: val});},
                        onKeyUp: (e)=>{
                            if (e.key === "Enter") {
                                $(this.width_input).focus();
                            }
                        },
                        value: length,
                        inputRef: (elem)=>{this.length_input = elem}
                    }}
                />

                <CustomInput
                    labelText='Package Width'
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.customInput
                    }}
                    labelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        onChange: (e)=>{let val = e.target.value; this.setState({width: val});},
                        onKeyUp: (e)=>{
                            if (e.key === "Enter") {
                                $(this.height_input).focus();
                            }
                        },
                        value: width,
                        inputRef: (elem)=>{this.width_input = elem}
                    }}
                />

                <CustomInput
                    labelText='Package Height'
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: classes.customInput
                    }}
                    labelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        onChange: (e)=>{let val = e.target.value; this.setState({height: val});},
                        onKeyUp: (e)=>{
                            if (e.key === "Enter") {
                                _.delay(()=>{this.submit()}, 100);
                            }
                        },
                        value: height,
                        inputRef: (elem)=>{this.height_input = elem}
                    }}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={saturday_delivery}
                            onChange={(e)=>{this.setState({saturday_delivery: e.target.checked});}}
                            color='primary'
                            value='saturday_delivery'
                        />
                    }
                    label='Saturday Delivery'
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={signature_required}
                            onChange={(e)=>{this.setState({signature_required: e.target.checked});}}
                            color='primary'
                            value='signature_required'
                        />
                    }
                    label='Signature Required'
                />
            </form>
        );

        let actions = [];
        actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
        actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

        return (
            <BasicDialog
                open={display}
                onClose={closeDialog}
                header={title}
                content={content}
                actions={actions}
            />
        );
    }

    handleOrderChange = (key, e) => {
        let val = e.target.value;

        // remove quote
        // val = this.formatInput(val);

        let order = Object.assign({}, this.state.order);
        order[key] = val;
        this.setState({order: order});
    }

    formatInput = (val) => {
        if (!val) return val;
        if (typeof val === "number") val = '' + val;

        let result = val;
        try {
            result = val.trim();
            result = result.replace(/["']/g, "");
        }
        catch (err) {
            console.log(err);
        }
        return result;
    }

    handleSubmit = () => {
        let { method, length, width, height, weight, saturday_delivery, signature_required } = this.state;
        let shipping_info = {
            method,
            length: this.formatInput(length),
            width: this.formatInput(width),
            height: this.formatInput(height),
            weight: this.formatInput(weight),
            saturday_delivery,
            signature_required
        };
        this.props.onSubmit(shipping_info);
    }
}

export default withStyles(styles)(NewLabelDialog)

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
  withStyles,
  makeStyles
} from "@material-ui/core";
import { NestedList } from "components";
import nestedMenuStyle from "variables/styles/nestedMenuStyle.jsx";
import { ExpandLess, ExpandMore } from '@material-ui/icons';

class NestedMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    menuList: PropTypes.array.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      active_item: ""
    };
  }
  render() {
    const { classes, menuList } = this.props;

    let list_items = [];
    for (let elem of menuList) {
      list_items.push(this.formatItem(elem));
    }

    return <List
      component="nav"
      className={classes.root}
    >
      {list_items}
    </List>;
  }

  formatItem = (item) => {
    const { classes } = this.props;
    const { active_item } = this.state;
    let item_key = item.key ? item.key : item.content;
    if (!item.sublist) {
      // if no sublist, this is a simple item
      return (
        <ListItem key={item_key}
          // button onClick={item.onClick ? item.onClick : ()=>{}}
        className={item.active ? classes.activeSingle : classes.listItem}>

          <Link href={item.path} onClick={item.onClick ? item.onClick : ()=>{}} underline='none' color='inherit' style={{display: "flex"}}>
            <ListItemIcon style={{margin: "auto"}}>
              {item.icon && <item.icon style={{color: "white"}}/>}
            </ListItemIcon>

            <ListItemText classes={{primary: item.active ? classes.activeListItemText : classes.listItemText}}>{item.content}</ListItemText>
          </Link>
        </ListItem>
      );
    } else {
      // item has a sub list
      if (!item_key) item_key = "";
      let sublist_items = [];
      for (let elem of item.sublist) {
        sublist_items.push(this.formatItem(elem));
      }
      return (
        <React.Fragment>
          <ListItem key={item_key + "_listitem"} button onClick={()=>{
            if (active_item === item_key) this.setState({active_item: null});
            else {
              this.setState({active_item: item_key});
            }
          }}
          className={item.active ? classes.activeList : classes.listItem}
          >
            <ListItemIcon style={{margin: "auto"}}>
              {item.icon && <item.icon style={{color: "white"}}/>}
            </ListItemIcon>

            <ListItemText classes={{primary: item.active ? classes.activeListItemText : classes.listItemText}}>{item.content}</ListItemText>
            {active_item === item_key ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse key={item_key + "_collapse"} in={active_item === item_key} timeout="auto" unmountOnExit>
            <List component="div" className={classes.nested} disablePadding>
                {sublist_items}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }
  }
}
export default withStyles(nestedMenuStyle)(NestedMenu);

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  InputWithSuggestion
} from "components";
import utils from 'utils/utils'
import { withAlert } from '../../hoc';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class Barcode extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    alert: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      type: 'product_tag',
      codes: '',
    };

    document.title = "Barcode";
  }

  render() {
    const { classes } = this.props;
    const { loading, codes, type } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container>
        {loadingBar}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.printBarcode}>
              <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="type">Template</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                  inputProps={{
                    name: 'type',
                    id: 'type',
                  }}
                >
                  {/*<MenuItem value='uline_4x2'>ULINE: S-11892-4x2</MenuItem>*/}
                  {/*<MenuItem value='zebra_2x1'>Zebra: 2x1</MenuItem>*/}
                  <MenuItem value='product_tag'>Product Barcode</MenuItem>
                  <MenuItem value='location_tag'>Location Barcode</MenuItem>
                  <MenuItem value='order_tag'>Order Barcode</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Codes(1 code/line)'
                labelProps={{shrink:true}}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  onChange: (e)=>{this.setState({codes: e.target.value})},
                  value: codes,
                  multiline: true,
                  rows: 3
                }}
              />

              <Button type='submit' className={classes.button}>Submit</Button>
            </form>
          </CardContainer>
        </GridItem>
      </Grid>
    );
  }

  printOrderBarcode = () => {
    let codes = utils.formatString(this.state.codes, true);
    let type = this.state.type;
    if (type !== 'order_tag') return;

    let base = this.context.data.getBase();
    const API_URL = base + '/api/customer/v1/barcode4by2/' + codes;

    let req = axios({
      method: 'get',
      url: API_URL,
    });

    this.setState({loading: true});
    req.then(this.printSuccess.bind(this, true)).catch(this.printError);
  }

  printBarcode = (e) => {
    e.preventDefault();

    // let codes = this.state.codes;
    let codes = utils.formatString(this.state.codes, true);
    let type = this.state.type;

    if (type === 'order_tag') {
      // if printing order barcode, call different API
      this.printOrderBarcode();
      return;
    }

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/barcode';

    let req = axios({
      method: 'post',
      url: API_URL,
      data: {
        type: type,
        codes: codes,
      },
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.printSuccess.bind(this, false)).catch(this.printError);
  }
  printSuccess = (same_page, resp) => {
    this.setState({loading: false});

    // alert error if any
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    if (!resp.data) {
      this.props.alert("Print barcode error");
      return;
    }

    utils.printPage(resp.data, same_page);
    window.location.reload();
  }
  printError = (err) => {
    console.log(err.response.data);
    // logout if session expired
    if (err.response.status === 401) {
      this.props.alert('Session expired, please login again');
      this.context.data.logout();
      return;
    }

    // alert error if any
    let resp = err.response;
    if (!resp) this.props.alert("Print barcode error");
    else if (resp.data.Error) {
      this.props.alert(resp.data.Error);
    }

    // remove loader
    this.setState({loading: false});
  }
}
export default withAlert(withStyles(styles)(Barcode));

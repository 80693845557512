import React, { Component } from 'react';
import PropTypes from 'prop-types'
import EditProductDialog from '../Dialogs/EditProductDialog'
import axios from 'axios';
import _ from 'lodash';
import utils from 'utils/utils'
import {
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  withStyles,
  Typography
} from '@material-ui/core';
import { withAlert } from 'hoc'
import {
  EnhancedTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  PopupAlert, DataTable
} from 'components';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class ManageProduct extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    alert: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.keys = [];

    this.product = null;

    this.state = {
      loading: false,
      products: {},
      filterType: 'sku',
      filterText: '',
      type: '',
    };

    document.title = "Manage Product";
  }

  render() {
    const { classes } = this.props;
    const { loading, filterType, filterText } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    let dialog = this.renderDialog();

    return (
      <Grid container spacing={3}>
        {loadingBar}
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchProducts}>
              <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel htmlFor="search_type">Search Type</InputLabel>
                <Select
                  value={filterType}
                  onChange={(e) => {
                    let val = e.target.value;
                    this.setState({filterType: val});
                  }}
                  inputProps={{
                    name: 'search_type',
                    id: 'search_type',
                  }}
                >
                  <MenuItem value='sku'>SKU</MenuItem>
                  <MenuItem value='upc'>UPC</MenuItem>
                  <MenuItem value='item_name'>Item Name</MenuItem>
                  <MenuItem value='category'>Category</MenuItem>
                  <MenuItem value='style'>Style</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Search Product'
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                labelProps={{shrink: true}}
                inputProps={{
                  onChange: (e) => {
                    let val = e.target.value;
                    this.setState({filterText: val});
                  },
                  value: filterText,
                }}
              />

              <Button type='submit' className={classes.button}>Search</Button>

              <Button onClick={this.openAddProduct} className={classes.button_add} type='button'>Add Product</Button>
            </form>
          </CardContainer>
        </GridItem>

        {dialog}

        {this.renderProductTable()}
      </Grid>
    );
  }

    // search products
  searchProducts = (e) => {
    e.preventDefault();
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };

    const API_URL = base + '/api/customer/v1/product';
    let req = axios({
      method: 'post',
      data: {
        type: this.state.filterType,
        keyword: this.state.filterText
      },
      url: API_URL,
      headers: headers
    });

    req.then(this.searchProductsSuccess).catch(this.searchProductsError);
    this.setState({loading: true})
  }
  searchProductsSuccess = (resp) => {
    this.setState({loading: false});
    if (!resp.data) {
      this.props.alert("Product search error");
      return;
    }
    // alert error if any
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    let keys = [];
    let products = {};

    if (resp.data && resp.data.length === 0) {
      this.props.alert("No result found");
      return;
    }

    for (let product of resp.data) {
      keys.push(product.item_id);

      products[product.item_id] = Object.assign({}, product);
    }
    this.keys = keys;
    this.setState({products: products});

    // display result
  }
  searchProductsError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    // logout if session expired
    if (err.response.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
      return;
    }

    // alert error if any
    if (!resp) this.props.alert("Product search error");
    else if (resp.data.Error) {
      this.props.alert(resp.data.Error);
    }
  }
    // update product
  updateProduct = (product) => {
    // edit product
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/product';


    let req = axios({
      method: 'put',
      url: API_URL,
      headers: headers,
      data: product
    });
    this.setState({loading: true});
    req.then(this.updateProductSuccess.bind(this, product)).catch(this.updateProductError);
  }
  updateProductSuccess = (product, resp) => {
    this.setState({loading: false});

    if (!resp.data) {
      this.props.alert("Internal server error");
      return;
    }
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      // update product success
      // update local product data
      let products = Object.assign({}, this.state.products);
      products[product.item_id] = product;
      this.setState({products: products});
      this.props.alert("Product updated", "Success");
      this.setState({type: ''});
    }
  }
  updateProductError = (err) => {
    this.setState({loading: false});
    if (!err.response) return;
    if (err.response.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
      return;
    }

    if (err.response.data.Error) {
      this.props.alert(err.response.data.Error);
    } else {
      this.props.alert("Update product error");
    }

  }
    // add product
  addProduct = (product) => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/newproduct';

    let req = axios({
      method: 'post',
      url: API_URL,
      headers: headers,
      data: product
    });

    this.setState({loading: true});
    req.then(this.addProductSuccess).catch(this.addProductError);
  }
  addProductOnce = _.once(this.addProduct)
  addProductSuccess = (resp) => {
    this.addProductOnce = _.once(this.addProduct)
    this.setState({loading: false});

    if (!resp.data) {
      this.props.alert("Internal server error");
      return;
    }
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.props.alert("Product added", "Success");
      window.location.reload();
    } else {
      this.props.alert("Add product error");
    }
  }
  addProductError = (err) => {
    this.addProductOnce = _.once(this.addProduct)
    this.setState({loading: false});
    if (!err.response) return;
    if (err.response.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
      return;
    }

    if (err.response.data.Error) {
      this.props.alert(err.response.data.Error);
    } else {
      this.props.alert("Add product error");
    }
  }

  // render product table
  renderProductTable = () => {
    const { classes } = this.props;
    const { products } = this.state;

    let colSettings = [
      {
        key: 'sku',
        label: 'SKU',
        tooltip: 'Edit Product',
        render: (sku, key, product)=> {
          return (<Button onClick={this.editProduct.bind(this, product.item_id)}>{sku}</Button>);
        },
        sortable: true
      },
      {
        key: 'upc',
        label: 'UPC',
        sortable: true
      },
      {
        key: 'name',
        label: 'Name',
        render: (val)=>{
          return (<div style={{maxHeight: "80px", overflow: "auto"}}>{val}</div>);
        },
        sortable: true
      },
      {
        key: 'retail_value',
        label: 'Retail Value',
        sortable: true
      },
      {
        key: 'wholesale_value',
        label: 'Wholesale Value',
        sortable: true
      },
      {
        key: 'cost',
        label: 'Cost',
        sortable: true
      },
      {
        key: 'ldp_cost',
        label: 'LDP Cost',
        sortable: true
      },
      {
        key: 'hs_code',
        label: 'HSCode',
        sortable: true,
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'length',
        label: 'Length',
      },{
        key: 'width',
        label: 'Width',
      },{
        key: 'height',
        label: 'Height',
      },{
        key: 'category',
        label: 'Category',
      },{
        key: 'brand',
        label: 'VendorName',
      },{
        key: 'color',
        label: 'Color',
      },{
        key: 'description',
        label: 'Description',
        render: (val)=>{
          return (<div style={{maxHeight: "80px", overflow: "auto"}}>{val}</div>);
        },
      },
      // {
      //   key: 'short_description',
      //   label: 'ShortDescription',
      //   render: (val)=>{
      //     return (<div style={{maxHeight: "80px", overflow: "auto"}}>{val}</div>);
      //   },
      // },
      {
        key: 'image',
        label: 'Image',
        render: (val)=>{
          if (val) {
            return (<img className='table-image hover-grow' src={val}/>);
          } else {
            return "No Image";
          }
        },
        cellProps: {
          style: {position: "relative"}
        }
      },{
        key: 'material',
        label: 'Material',
      },{
        key: 'size',
        label: 'Size',
      },{
        key: 'style',
        label: 'Style',
      },{
        key: 'weight',
        label: 'Weight',
      },{
        key: 'country',
        label: 'Country',
      }
    ];
    let rows = [];
    for (let id of this.keys) {
      let product = products[id];
      rows.push(product);
    }

    if (rows.length < 1) return;

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div style={{marginTop: "-36px"}}>
            <EnhancedTable
              data={rows}
              withoutPaper
              tableSettings={{
                tableProps: {
                  className: "with-border"
                }
              }}
              tableTitle={"Products Result"}
              filterable={true}
              colSettings={colSettings}
            />
          </div>
        </CardContainer>


        {/*<EnhancedTable*/}
        {/*  data={rows}*/}
        {/*  tableSettings={{*/}
        {/*    tableProps: {*/}
        {/*      className: "with-border"*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  tableTitle={"Products Result"}*/}
        {/*  filterable={true}*/}
        {/*  colSettings={colSettings}*/}
        {/*/>*/}
      </GridItem>
    );
  }
  // open edit product dialog
  editProduct = (id) => {
    let product = this.state.products[id];
    this.product = product;
    this.setState({ type: 'edit' });
  }
  // open add product dialog
  openAddProduct = () => {
    this.product = null;
    this.setState({ type: 'add' });
  }
  // render dialog
  renderDialog = () => {
    const { type } = this.state;

    switch (type) {
      case "edit":
        // render edit dialog
        return (
          <EditProductDialog
            display={true}
            closeDialog={()=>{this.setState({type: ''})}}
            onSubmit={this.dialogSubmit}
            product={this.product}
            title='Edit Product'
          />
        );
        break;
      case 'add':
        // render add dialog
        return (
          <EditProductDialog
            display={true}
            closeDialog={()=>{this.setState({type: ''})}}
            onSubmit={this.dialogSubmit}
            product={this.product}
            title='Add Product'
          />
        );
        break;
      default:
        return null;
    }
  }

  // dialog submit
  dialogSubmit = (product) => {
    // check required field
    let err = '';
    let warning = '';
    let regex = /[^A-Za-z0-9-_]+/g;
    product.name = utils.formatString(product.name);
    if (this.state.type === 'edit') {
      if (!product.sku) {
        warning += 'SKU is empty. \n';
      }
      if (product.sku.length > 15) {
        warning += 'SKU is too long (more than 15 characters). \n';
      }
      if (regex.test(product.sku)) {
        warning += 'SKU only accepts number, letter, dash and underscore (whitespace is not allowed). \n';
      }
    } else {
      // adding new product
      if (!product.sku) {
        err += 'SKU is required. \n';
      }
      if (product.sku.length > 15) {
        err += 'SKU is too long. \n';
      }
      if (regex.test(product.sku)) {
        err += 'SKU only accepts number, letter, dash and underscore (whitespace is not allowed). \n';
      }
    }
    if (!product.name) err += "Product name is invalid. \n";
    // upc is not required
    // if (!product.upc) {
    //     err += 'UPC is required. \n';
    // }
    if (product.upc.length > 15) {
      err += 'UPC is too long. \n';
    }
    if (regex.test(product.upc)) {
      err += 'UPC only accepts number, letter, dash and underscore (whitespace is not allowed). \n';
    }
    if (!product.category) {
      err += 'Category is required. \n';
    }
    if (!product.retail_value && product.retail_value !== 0) {
      err += 'Retail value is required. \n';
    }
    if (!parseFloat(product.retail_value) || parseFloat(product.retail_value) < 1) {
      if (parseFloat(product.retail_value) != 0) err += 'Retail value should be greater than or equal to 0. \n';
    }
    if (!product.weight) err += 'Weight is required. \n';
    else {
      if (!parseFloat(product.weight) || parseFloat(product.weight) <= 0) err += 'Weight should be a number and greater than 0. \n';
    }

    if (err) {
      this.props.alert(err);
      return;
    }

    if (warning) {
      this.props.alert("Following error might cause system problem, please contact IT to correct them:\n" + warning);
    }

    // remove quotes and spaces in the input fields
    // for (let key in product) {
    //     product[key] = utils.formatString(product[key]);
    // }

    // if (!product.sku || !product.upc) {
    //     this.props.alert("SKU and UPC can't contain space or quotes");
    //     return;
    // }

    if (this.state.type === 'edit') {
      this.updateProduct(product);
    } else {
      // add product
      this.addProductOnce(product);
    }
  }
}
export default withAlert(withStyles(styles)(ManageProduct));

// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import moment from 'moment-timezone'
import $ from 'jquery'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Paper,
    Grid,
    withStyles, CardActionArea, CardContent
} from '@material-ui/core';
// BXZ render modules
import {
    CardIcon,
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, Cell
} from 'recharts';
import { withAlert } from 'hoc'
import {EuroSymbol, FlightTakeoff, Assignment, Place} from "@material-ui/icons";
// Our view components

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        fontSize: "1rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Neuton-Regular',
        fontSize: "1.25rem",
        whiteSpace: "nowrap",
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Neuton-Bold',
        fontSize: "1.25rem",
        fontWeight: "bold",
        flexGrow: "1"
    },
})};

class ShipmentAnalysis extends Component {
    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired
    }
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            dashboard_data: {},
            stats_detail: [],
            stat_type: '',
            zone_weight: [],
            shipped_day: [],
            date_from: '',
            date_to: '',
            loading: false,
        };

        document.title = "Shipment Analysis";
    }

    render() {
        let loadingBar = null;
        if (this.state.loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={3}>
                {loadingBar}

                {this.renderStats()}
                {this.renderStatsDetail()}
                {this.renderZoneWeight()}
                {this.renderShipmentTable()}
            </Grid>
        );
    }

    // api call
    componentWillMount() {
        this.loadStats();
    }

    // api call
    // load initial data
    loadStats = () => {
        let customer = localStorage.getItem('customer_id');
        let warehouse = localStorage.getItem('warehouse_id');
        if (!customer || !warehouse) {
            // if customer of warehouse is not set, don't make ajax call
            this.props.alert("Customer id or Warehouse id is not set, please select warehouse or login again");
            return;
        }

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': customer,
            'warehouse': warehouse
        };
        const API_URL = base + '/api/customer/v1/analysis/shipment';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        req.then(this.loadStatsSuccess).catch(this.loadStatsError);
        this.setState({loading: true});
    }
    loadStatsSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        this.setState({
            dashboard_data: resp.data,
            zone_weight: resp.data.zone_weight,
            shipped_day: resp.data.shipped_day
        });
    }
    loadStatsError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
    // load stat details
    loadDetail = (type) => {
        let date_from = '';
        let date_to = '';
        if (!type) {
            // if type is not passed in, it is loading by date range, get data from state
            type = this.state.stat_type;
            date_from = this.state.date_from;
            date_to = this.state.date_to;
        } else {
            // if type is passed in, it is loading default, reset date range
            this.setState({date_from: '', date_to: ''})
        }

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        // TODO load dashboard analysis detail, change url
        const API_URL = base + '/api/customer/v1/analysis/shipment';

        let req = axios({
            method: 'post',
            url: API_URL,
            data: {
                type,
                date_from,
                date_to,
            },
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadDetailSuccess.bind(this, type)).catch(this.loadDetailError);
    }
    loadDetailSuccess = (type, resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.props.alert(resp.Error);
            return;
        }

        if (!resp || (Array.isArray(resp) && resp.length === 0)) {
            this.props.alert("No data found.");
            return;
        }
        this.setState({stats_detail: resp, stat_type: type});
    }
    loadDetailError =(err)=>{utils.generalAjaxErrorHandler(this, err)}

    // render func
    renderStats = () => {
        const { dashboard_data } = this.state;

        let revenue = dashboard_data.annual_shipped_value ? ('$'+utils.numberWithComma(dashboard_data.annual_shipped_value)) : "N/A";
        let orders = dashboard_data.annual_shipped_number ? utils.numberWithComma(dashboard_data.annual_shipped_number) : "N/A";
        let top_province = dashboard_data.top10_province ? 'Click to see detail' : "N/A";
        let fulfilled_rate = dashboard_data.fulfilled_rate ? utils.formatPercentage(dashboard_data.fulfilled_rate) : "N/A";

        return (
          <React.Fragment>
              {this.renderSingleStatCard(revenue, "annual_shipped_value")}
              {this.renderSingleStatCard(orders, "annual_shipped_number")}
              {this.renderSingleStatCard(top_province, "top10_province")}
              {this.renderSingleStatCard(fulfilled_rate, "fulfilled_rate")}
          </React.Fragment>
        );
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        if (!val || val === "N/A") click_disabled = true;

        let title = key;
        let icon = null;
        switch (key) {
            case "annual_shipped_value":
                icon = <CardIcon style={{
                    backgroundColor: "#30C4EE"
                }} className={classes.statIconContainer}>
                    <EuroSymbol className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Revenue";
                break;
            case "annual_shipped_number":
                icon = <CardIcon style={{
                    backgroundColor: "#8791C8"
                }} className={classes.statIconContainer}>
                    <FlightTakeoff className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Shipments";
                break;
            case "top10_province":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B90"
                }} className={classes.statIconContainer}>
                    <Place className={classes.statIcon} />
                </CardIcon>;
                title = "Top Provinces";
                break;
            case "fulfilled_rate":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B9B"
                }} className={classes.statIconContainer}>
                    <Assignment className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Fulfilled Rate";
                break;
        }

        let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

        return (
          <GridItem xs={12} sm={6} md={limit_width ? 6 : 3}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadDetail(key);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                      whiteSpace: "normal"
                                  }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{val}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderStatsDetail = () => {
        const { classes } = this.props;
        const { stats_detail, stat_type, date_from, date_to } = this.state;

        if (!stats_detail || stats_detail.length === 0 || !stat_type) return null;

        let detail_title = stat_type;
        let chart_value = '';
        let data = Array.from(stats_detail);

        let colSettings = [];
        switch (stat_type) {
            case 'annual_shipped_value':
                detail_title = "Total Shipped Revenue";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'shipment_value',
                        label: 'Shipped Revenue',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "shipment_value";
                break;
            case 'annual_shipped_number':
                detail_title = "Number of Shipments";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'shipment_number',
                        label: 'Number of Shipments',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "shipment_number";
                data = data.map((val)=>{return {
                    month: val.month,
                    shipment_number: parseInt(val.shipment_number)
                };});
                break;
            case 'top10_province':
                detail_title = "Top Provinces";
                colSettings = [
                    {
                        key: 'province',
                        label: 'Province'
                    },
                    {
                        key: 'quantity',
                        label: 'Quantity',
                        render: utils.numberWithComma
                    }
                ];
                break;
            case 'fulfilled_rate':
                detail_title = "Average Fulfilled Rate";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_received',
                        label: 'Received',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'total_shipped',
                        label: 'Shipped',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'onhold',
                        label: 'Onhold',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'percentage',
                        label: 'Fulfilled Rate',
                        render: (val) => {
                            if (!val) val = 0;
                            return (parseFloat(val*100).toFixed(2)+'%')
                        }
                    }
                ];
                // todo need to modify the chart setting to show percentage, may also need to format data
                chart_value = "percentage";
                // data = data.map((val)=>{return {
                //     month: val.month,
                //     percentage: parseFloat(val.percentage)
                // };});
                break;
        }

        let rows = Array.from(stats_detail);

        return (
            <GridItem xs={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h4" id="tableTitle">
                                {detail_title}
                            </Typography>
                            <br/>

                            {
                                stat_type !== 'top10_province' && <div>
                                    <CustomInput
                                      labelText='Date From'
                                      formControlProps={{
                                          required: true,
                                          className: classes.customInput
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          style:{marginRight: '1rem'},
                                          views:['year', 'month'],
                                          type: 'date',
                                          value: date_from,
                                          onChange: (e)=>{this.setState({date_from: e.target.value})}
                                      }}
                                    />
                                    <CustomInput
                                      labelText='Date To'
                                      formControlProps={{
                                          required: true,
                                          className: classes.customInput
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          type: 'date',
                                          value: date_to,
                                          onChange: (e)=>{this.setState({date_to: e.target.value})}
                                      }}
                                    />
                                    <Button style={{marginLeft: '2rem', marginTop: "1rem"}}  onClick={()=>{
                                        const { date_from, date_to } = this.state;
                                        if (moment(date_to).isBefore(date_from)) this.props.alert("Date to should be later than Date from.");
                                        else this.loadDetail();
                                    }}>Submit</Button>
                                </div>
                            }
                        </div>

                        {
                            stat_type !== 'top10_province' &&  <ResponsiveContainer width='95%' height={500}>
                                <LineChart
                                  data={data}
                                  margin={{
                                      top: 5, right: 30, left: 20, bottom: 5,
                                  }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" tickFormatter={(val)=>{
                                        if (typeof val === "string" && val.length >= 3){
                                            return val.substr(0,3);
                                        } else return val;
                                    }}/>
                                    <YAxis />
                                    <Tooltip labelFormatter={(val)=>{
                                        let month = val;
                                        let year = '';
                                        if (typeof val === "string" && val.length >= 3){
                                            month = val.substr(0,3);
                                            year = val.substr(3);
                                        }
                                        month = this.displayFullMonth(month);
                                        return month + (year ? ', '+ year : '');
                                    }}
                                             formatter={
                                                 (value, name) => {
                                                     let label = name;
                                                     switch(name) {
                                                         case 'shipment_value':
                                                             label = 'Shipped Revenue';
                                                             value = parseFloat(value) ? ('$' + utils.numberWithComma(value)) : value;
                                                             break;
                                                         case 'shipment_number':
                                                             label = 'Number of Shipments';
                                                             value = parseInt(value) ? (utils.numberWithComma(value)) : value;
                                                             break;
                                                         case 'provinces':
                                                             label = 'Units';
                                                             value = parseInt(value) ? (utils.numberWithComma(value)) : value;
                                                             break;
                                                         case 'percentage':
                                                             label = 'Percentage';
                                                             value = utils.formatPercentage(value);
                                                             break;
                                                     }
                                                     return [value, label];
                                                 }
                                             }
                                    />
                                    <Legend verticalAlign="top" height={36} formatter={
                                        (value) => {
                                            let label_map = {
                                                'shipment_value': 'Shipped Revenue',
                                                'shipment_number': 'Number of Shipments',
                                                'provinces': 'Number of Units',
                                                'percentage': 'Percentage',
                                            };
                                            return label_map[value] ? label_map[value] : value;
                                        }
                                    }/>
                                    <Line type="monotone" dataKey={chart_value} stroke="#8884d8" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        }

                        <DataTable
                          data={rows}
                          className={classes.table}
                          tableSettings={{
                              tableProps: {
                                  id: "db_order"
                              },
                          }}
                          withoutPaper
                          colSettings={colSettings}
                        />

                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderZoneWeight = () => {
        const { classes } = this.props;
        const { zone_weight } = this.state;

        if (!zone_weight || zone_weight.length === 0) return null;

        return (
            <GridItem xs={12} md={6}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h4" id="tableTitle">
                                Zone Weight
                            </Typography>
                        </div>

                        <ResponsiveContainer width='95%' height={500}>
                            <BarChart
                              data={zone_weight}
                              margin={{
                                  top: 5, right: 30, left: 20, bottom: 5,
                              }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tickFormatter={(val)=>{
                                    if (typeof val === "string" && val.length >= 3){
                                        return val.substr(0,3);
                                    } else return val;
                                }}/>
                                <YAxis />
                                <Tooltip labelFormatter={(val)=>{
                                    let month = val;
                                    let year = '';
                                    if (typeof val === "string" && val.length >= 3){
                                        month = val.substr(0,3);
                                        year = val.substr(3);
                                    }
                                    month = this.displayFullMonth(month);
                                    return month + (year ? ', '+ year : '');
                                }}/>
                                <Legend verticalAlign="top" height={36}/>
                                <Bar dataKey="weight" fill="#3E5485" />
                                <Bar dataKey="zone" fill="#F17022" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderShipmentTable = () => {
        const { classes } = this.props;
        const { shipped_day } = this.state;

        let rows = shipped_day ? shipped_day : [];

        let colSettings = [
            {
                key: 'method',
                label: "Method",
                render: utils.convertShippingMethod
            },
            {
                key: 'shipment_day',
                label: "Avg. Delivery Days",
            },
            {
                key: 'rate',
                label: "Avg. Cost",
                render: (val)=>{
                    return '$'+val;
                }
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h4" id="tableTitle">
                            Shipment Data
                            <Button style={{float: 'right'}}  onClick={()=>{utils.export_table_to_csv("shipment_data");}}>Export</Button>
                        </Typography>
                    </div>

                    <DataTable
                      data={rows}
                      className={classes.table}
                      tableSettings={{
                          tableProps: {
                              id: "shipment_data"
                          },
                      }}
                      withoutPaper
                      colSettings={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }

    displayFullMonth = (code) => {
        let monthMap = {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        };
        return monthMap[code] ? monthMap[code] : code;
    }
}

export default withAlert(withStyles(styles)(ShipmentAnalysis))

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Button, CustomInput, BasicDialog } from 'components'
import {
    InputLabel,
    Select,
    MenuItem,
    withStyles,
    FormControl,
} from '@material-ui/core'
import utils from 'utils/utils'
import { withAlert } from 'hoc'

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    // custom styles
})};

class EditUserDialog extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        alert: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        display: PropTypes.bool.isRequired,
        closeDialog: PropTypes.func.isRequired,
        user: PropTypes.object,
        onSubmit: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        title: "Create User"
    }

    constructor(props, context) {
        super(props, context);

        let user = {};
        if (props.user) {
            user = Object.assign(props.user, {
                user_role: props.user.user_role ? props.user.user_role : '4',
                username: props.user.username ? props.user.username : '',
                // Do not set password, use email instead, password will be generated by system and sent to email

                // password: props.user.password ? props.user.password : '',
                email: props.user.email ? props.user.email : '',
            });
        } else {
            user = {
                user_role: '4',
                username: '',
                // password: '',
                email: "",
            };
        }

        this.state = {
            user: user,
            loading: false
        };
    }

    render() {
        const { display, closeDialog, title } = this.props;

        let actions = [];
        actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
        actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

        return (
            <BasicDialog
                open={display}
                onClose={closeDialog}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                header={title}
                content={this.renderContent()}
                actions={actions}
            />
        );
    }

    renderContent = () => {
        const { classes } = this.props;
        const { user_role, username, password, email } = this.state.user;

        return (
           <form>
               <FormControl required fullWidth className={classes.selectInput}>
                   <InputLabel htmlFor="user_role" shrink>Role</InputLabel>
                   <Select
                       value={user_role}
                       onChange={(e)=>{this.onUserChange(e.target.value, 'user_role')}}
                       inputProps={{
                           name: 'user_role',
                           id: 'user_role',
                       }}
                   >
                       <MenuItem value='4'>Employee</MenuItem>
                       <MenuItem value='8'>Manager</MenuItem>
                   </Select>
               </FormControl>

               <CustomInput
                   labelText='Username'
                   formControlProps={{
                       fullWidth: true,
                       required: true,
                       className: this.props.classes.customInput
                   }}
                   labelProps={{
                       shrink: true,
                   }}
                   inputProps={{
                       onChange: (e)=>{this.onUserChange(e.target.value, 'username')},
                       value: username,
                   }}
               />

               {/*Do not set password, use email instead, password will be generated by system and sent to email*/}

               {/*<CustomInput*/}
               {/*    labelText='Password'*/}
               {/*    formControlProps={{*/}
               {/*        fullWidth: true,*/}
               {/*        required: true,*/}
               {/*        className: this.props.classes.customInput*/}
               {/*    }}*/}
               {/*    labelProps={{*/}
               {/*        shrink: true,*/}
               {/*    }}*/}
               {/*    inputProps={{*/}
               {/*        onChange: (e)=>{this.onUserChange(e.target.value, 'password')},*/}
               {/*        value: password,*/}
               {/*    }}*/}
               {/*/>*/}
               <CustomInput
                 labelText='Email'
                 formControlProps={{
                     fullWidth: true,
                     required: true,
                     className: this.props.classes.customInput
                 }}
                 labelProps={{
                     shrink: true,
                 }}
                 inputProps={{
                     placeholder: "Account email you want to receive the password",
                     onChange: (e)=>{this.onUserChange(e.target.value, 'email')},
                     value: email,
                 }}
               />
           </form>
       );
    }
    onUserChange = (val, key) => {
        let newUser = Object.assign({}, this.state.user);
        newUser[key] = val;
        this.setState({user: newUser});
    }

    handleSubmit = () => {
        const { user } = this.state;
        this.props.onSubmit(user);
    }
}

export default withAlert(withStyles(styles)(EditUserDialog))

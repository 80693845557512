import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Toolbar, Typography, withStyles, Paper, Table, TableHead, TableBody, TableRow, TableCell  } from '@material-ui/core'
import EnhancedTableToolbar from './EnhancedTableToolbar'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    // table: {
    //     minWidth: 'initial',
    // },
    tableWrapper: {
        overflowX: 'auto',
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

class DataTable extends React.Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        colSettings: PropTypes.array.isRequired,
        tableSettings: PropTypes.object,
        tableTitle: PropTypes.any,
        rowSettings: PropTypes.object,
        emptyDescription: PropTypes.any,
        fixHeight: PropTypes.bool,
        withoutPaper: PropTypes.bool,
        bodyMaxHeight: PropTypes.string,
        // useVirtualized: PropTypes.bool,
    };

    static defaultProps = {
        rowSettings: {},
        tableSettings: {},
        fixHeight: false,
    };

    render() {
        const { classes, tableTitle, tableSettings, withoutPaper, bodyMaxHeight } = this.props;
        // todo decide how to deal with empty rows

        // if not table title, don't show toolbar
        // let title = tableTitle ? tableTitle : '';
        let toolbar = null;
        if (tableTitle) {
            toolbar = (
                <Toolbar
                    className={classes.root}
                    style={{padding: 0}}
                >
                    <div style={{width: '100%', padding: "0 1rem"}}>
                        <div className={classes.title}>
                            <Typography variant="h4" id="tableTitle">
                                {tableTitle}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            );
        }

        let tableHead = this.renderHead();

        let tableProps = {
            className: classes.table,
        };
        if (tableSettings.tableProps) {
            tableProps = Object.assign(tableProps,tableSettings.tableProps);
        }

        let containerStyle = {};
        if (tableSettings.containerStyle) {
            containerStyle = tableSettings.containerStyle;
        }

        let wrapperStyle = {};
        if (tableSettings.maxHeight) {
            wrapperStyle = {
                maxHeight: tableSettings.maxHeight,
                overflow: 'auto',
            };
        }
        if (withoutPaper) {
            return (
                <div className={classes.root} style={containerStyle}>
                    {toolbar}
                    <div className={classes.tableWrapper} style={wrapperStyle}>
                        <Table {...tableProps}>
                            {tableHead}
                            <TableBody>
                                {this.renderRows()}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            );
        } else {
            return (
                <Paper className={classes.root} style={containerStyle}>
                    {toolbar}
                    <div className={classes.tableWrapper} style={wrapperStyle}>
                        <Table {...tableProps}>
                            {tableHead}
                            <TableBody>
                                {this.renderRows()}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            );
        }
    }

    renderHead = () => {
        const { colSettings } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {colSettings.map(column => {
                        return (
                            <TableCell
                                key={column.key}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                                style={column.header_style ? column.header_style : {}}
                                className={column.header_class ? column.header_class : ''}
                            >
                                {column.label}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }

    formatDataRow = (rowData, index) => {
        const { colSettings, rowSettings } = this.props;

        let cells = [];

        for (let cellData of colSettings) {
            let fieldKey = index + '_' + cellData.key;
            if (rowData && rowData.id) {
                fieldKey = rowData.id + '_' + cellData.key;
            }
            let cellProps = {
                key: fieldKey
            };
            if (cellData.cellProps) {
                cellProps = Object.assign(cellProps, cellData.cellProps);
            }

            // pass data to click handler
            if (cellProps.onClick) {
                let oldOnClick = cellProps.onClick;
                let newOnClick = ()=>{
                    oldOnClick(rowData[cellData.key], cellData.key, rowData, index);
                };
                cellProps.onClick = newOnClick;
            }
            if (cellProps.onDoubleClick) {
                let oldOnDoubleClick = cellProps.onDoubleClick;
                let newOnDoubleClick = ()=>{
                    oldOnDoubleClick(rowData[cellData.key], cellData.key, rowData, index);
                };
                cellProps.onDoubleClick = newOnDoubleClick;
            }

            // cellData.key is the property name to access the cell data from the rowData object
            let cellContent = rowData[cellData.key];
            if (cellData.render) {
                // Render func params: cell Value, cell key, row data object
                cellContent = cellData.render(rowData[cellData.key], cellData.key, rowData, index);
            }

            // if cellPropsOnDisplay is passed in, use it to alter cellProps
            if (_.isFunction(cellData.cellPropsOnDisplay)) {
                cellProps = cellData.cellPropsOnDisplay(cellProps, rowData[cellData.key], cellData.key, rowData, index)
            }

            // if the content is undefined, don't render it
            // if (_.isUndefined(cellContent)) {
            //     continue;
            // }
            // still have to render it, set it to ''
            if (_.isUndefined(cellContent)) {
                cellContent = '';
            }


            cells.push(
                <TableCell {...cellProps}>{cellContent}</TableCell>
            );
        }

        let rowProps = {
            hover: true,
            tabIndex: -1,
            key: rowData.id ? rowData.id : index
        };

        if (rowSettings) {
            rowProps = Object.assign(rowProps, rowSettings.rowProps);

            // this classNameOnDisplay may give row color based on the row data
            if (rowSettings.classNameOnDisplay) {
                rowProps = rowSettings.classNameOnDisplay(rowData, rowProps);
            }
        }

        if (rowProps.onClick) {
            rowProps.onClick = rowProps.onClick.bind(this, rowData, index);
        }

        return (
            <TableRow {...rowProps}>
                {cells}
            </TableRow>
        );
    }
    renderRows = () => {
        const { colSettings, emptyDescription } = this.props;

        let rows = Array.from(this.props.data);

        if (rows.length === 0) {
            let empty = (<TableRow><TableCell colSpan={colSettings.length}>No Data</TableCell></TableRow>);
            if (emptyDescription) empty = emptyDescription;
            return empty;
        }

        // display current page
        return rows.map((n, index) => {
            // use formatDataRow to format the data element
            return this.formatDataRow(n, index);
        })
    }
}

export default withStyles(styles)(DataTable);

import Login from "../views/Login/Login";
import ResetPassword from '../views/Login/ResetPassword';

const anonyRoutes = [
  {
    key: 'login',
    path: "/login",
    component: Login
  },
  {
    key: 'resetPassword',
    path: "/resetPassword",
    component: ResetPassword
  },
  { key: 'redirect', redirect: true, path: "*", to: "/login" },
];

export default anonyRoutes;

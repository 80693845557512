import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import $ from 'jquery'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
  Typography,
  Grid,
  withStyles
} from "@material-ui/core";
// BXZ render modules
import {
  DataTable,
  CardContainer,
  Button,
  GridItem,
  AutoSuggestInput,
  PopupAlert
} from "components";
import EditPODialog from '../Dialogs/EditPODialog';
// Our view components

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  customInput: {
    margin: '0'
  },
});

class ManageInbound extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.po_list = [];
    this.po_id = '';
    this.po_number = '';

    this.state = {
      loading: false,
      po: null,
      dialog: '',
      active_line: null,
      active_index: -1,
      forceUpdate: {},
      // Pop up alert
      message_type: "",
      message_content: "",
      confirm_callback: null,
    };

    document.title = "Manage PO";
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container spacing={3}>
        {loadingBar}
        {this.renderAlert()}
        {this.renderDialog()}

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <Typography variant="h4" id="tableTitle">
             Please select a PO
            </Typography>

            <div>
              <AutoSuggestInput
                suggestions={this.po_list}
                label="PO#"
                inputProps={{
                  placeholder: "Please select a PO"
                }}
                onSelected={this.loadPODetail}
              />
            </div>
          </CardContainer>
        </GridItem>

        <GridItem xs={12}>
          {this.renderPOTable()}
        </GridItem>
      </Grid>
    );
  }

  componentWillMount() {
    this.loadPOList();
    let po_id = localStorage.getItem("dbPO");
    let po_name = localStorage.getItem("dbPOName");

    if (po_id) {
      this.po_name = po_name ? po_name : "";
      this.po_id = po_id;
      this.loadDBPO();
    }
  }

  // api call
  loadDBPO = () => {
    let po_id = localStorage.getItem("dbPO");
    if (!po_id) return;

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/inboundlist/' + po_id;

    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });
    localStorage.removeItem("dbPO");
    this.setState({loading: true, po: null});
    req.then(this.loadDBPOSuccess).catch(this.loadDBPOError);
  }
  loadDBPOSuccess = (resp) => {
    this.setState({loading: false});
    resp = resp.data;
    if (resp.Error) {
      alert(resp.Error);
      return;
    }
    this.setState({po: resp});
  }
  loadDBPOError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
  // Load PO auto-complete options
  loadPOList = () => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/inboundlist';
    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });
    this.setState({loading: true});
    req.then(this.loadPOListSuccess).catch(this.loadPOListError);
  }
  loadPOListSuccess = (resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
      if (resp.data.Error.message) this.alert(resp.data.Error.message);
      else this.alert(resp.data.Error);
      return;
    }

    resp = resp.data;
    if (!Array.isArray(resp)) resp = [];
    let options = resp.map((elem)=>{
      return ({
        label: elem['po'],
        value: elem['po_id']
      });
    });

    this.po_list = options;
    this.setState({forceUpdate: {}});
  }
  loadPOListError = (err) => {
    this.setState({loading: false});
    // logout if session expired
    let resp = err.response;
    if (!resp) return;
    if (resp.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
      return;
    }

    // alert error if any
    if (resp.data.Error) {
      if (resp.data.Error.message) this.alert(resp.data.Error.message);
      else this.alert(resp.data.Error);
    }
  }
  // Load PO Detail By po_id
  loadPODetail = (suggestion) => {
    if (!suggestion) return;

    this.po_id = suggestion.value;
    this.po_number = suggestion.label;

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'username': localStorage.getItem('username'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/inboundlist/' + this.po_id;

    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true, po: null});
    req.then(this.loadPODetailSuccess).catch(this.loadPODetailError);
  }
  loadPODetailSuccess = (resp) => {
    this.setState({loading: false});
    resp = resp.data;
    if (resp.Error) {
      alert(resp.Error);
      return;
    }

    this.setState({po: resp});
  }
  loadPODetailError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    if (resp.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
    }

    let msg = "Load PO error";
    if (resp.data.Error) {
      if (resp.data.Error.message) this.alert(resp.data.Error.message);
      else this.alert(resp.data.Error);
      return;
    }
    alert(msg);
  }
  // Cancel PO
  cancelPO = () => {
    let po_id = this.po_id;

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/inbound/' + po_id;

    let req = axios({
      method: 'delete',
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.cancelPOSuccess).catch(this.cancelPOError);
  }
  cancelPOSuccess = (resp) => {
    this.setState({loading: false});
    resp = resp.data;
    if (resp.Error) {
      alert(resp.Error);
      return;
    }

    if (resp) {
      window.location.reload();
    } else {
      this.alert("Cancel PO error");
    }
  }
  cancelPOError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    if (resp.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
    }

    let msg = "Cancel PO error";
    if (resp.data.Error) {
      if (resp.data.Error.message) this.alert(resp.data.Error.message);
      else this.alert(resp.data.Error);
      return;
    }
    alert(msg);

  }
  // Close PO
  closePO = () => {
    let po_id = this.po_id;

    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/closepo/' + po_id;

    let req = axios({
      method: 'get',
      url: API_URL,
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.closePOSuccess).catch(this.closePOError);
  }
  closePOSuccess = (resp) => {
    this.setState({loading: false});
    resp = resp.data;
    if (resp.Error) {
      alert(resp.Error);
      return;
    }

    if (resp) {
      window.location.reload();
    } else {
      this.alert("Close PO error");
    }
  }
  closePOError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    if (resp.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
    }

    let msg = "Close PO error";
    if (resp.data.Error) {
      if (resp.data.Error.message) this.alert(resp.data.Error.message);
      else this.alert(resp.data.Error);
      return;
    }
    alert(msg);
  }
  // Edit PO Line Item
  editLineItem = (new_line_item) => {
    let base = this.context.data.getBase();
    const headers = {
      'token': localStorage.getItem('token'),
      'user': localStorage.getItem('user_id'),
      'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
      'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
    };
    const API_URL = base + '/api/customer/v1/inbound';

    let req = axios({
      method: 'post',
      url: API_URL,
      data: {
        line_item_id: new_line_item['line_item_id'],
        quantity: new_line_item['quantity'],
        tracking: new_line_item['tracking'] ? new_line_item['tracking'] : '',
        estimated_date: new_line_item['estimated_date'] ? new_line_item['estimated_date'] : '',
        po_id: this.po_id
      },
      headers: headers
    });

    this.setState({loading: true});
    req.then(this.editLineItemSuccess.bind(this, new_line_item)).catch(this.editLineItemError);
  }
  editLineItemSuccess = (new_line_item, resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      alert(data.Error);
      return;
    }

    let new_po = Array.from(this.state.po);
    new_po[this.state.active_index] = new_line_item;
    // update whole po tracking for each line item
    for (let line_item of new_po) {
      line_item.tracking = new_line_item.tracking;
    }
    if (resp) {
      this.setState({dialog: '', po: new_po});
    }

  }
  editLineItemError = (err) => {
    this.setState({loading: false});
    let resp = err.response;
    if (!resp) return;
    if (resp.status === 401) {
      alert('Session expired, please login again');
      this.context.data.logout();
    }

    let msg = "Internal server error";
    if (resp.data.Error) {
      if (resp.data.Error.message) this.alert(resp.data.Error.message);
      else this.alert(resp.data.Error);
      return;
    }
    alert(msg);
  }

  // render functions
  renderPOTable = () => {
    const { po } = this.state;

    if (!po) return null;

    let colSettings = [
      {
        key: 'po',
        label: 'PO',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'name',
        label: 'ItemName',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'sku',
        label: 'SKU',
        // render: utils.tableCellOnDisplay,
        render: (val, key, row, index)=>{
          let po = this.state.po;
          if (parseInt(po[0].po_status) === 2) {
            if (po['line_item_id'] !== row['line_item_id']) {
              for (let i = 0; i < po.length; i++) {
                if (po[i]['line_item_id'] === row['line_item_id']) {
                  index = i;
                  break;
                }
              }
            }
            return <Button style={{whiteSpace: "nowrap"}} onClick={()=>{
              this.setState({dialog: 'edit_line_item', active_line: row, active_index: index});
            }}>{val}</Button>;
          } else {
           return <span style={{whiteSpace: "nowrap"}}>{val}</span>;
          }
        }
      },
      {
        key: 'upc',
        label: 'UPC',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'style',
        label: 'Style',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'color',
        label: 'Color',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'quantity',
        label: 'Quantity',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'received',
        label: 'Received',
        render: utils.tableCellOnDisplay,
      },
      {
        key: 'discrepancy',
        label: 'Discrepancy',
        render: (val, key, row)=>{
          return (
            parseInt(row['received']) - parseInt(row['quantity'])
          );
        },
      },
      {
        key: 'estimated_date',
        label: 'EstimatedDate',
        render: (val) => {
          return utils.formatDateTime(val, "MM/DD/YYYY");
        },
      },
      {
        key: 'arrival_dt',
        label: 'ArrivalDate',
        render: utils.formatDate,
      },
      {
        key: 'received_dt',
        label: 'ReceivedDate',
        render: utils.formatDate,
      },
      {
        key: 'status_id',
        label: 'Status',
        render: utils.convertPOStatus,
      },
      {
        key: 'tracking',
        label: 'Tracking',
      },
    ];

    let btnCancel = null;
    // can only edit po when po status is 2 (pending received)
    if (po[0] && parseInt(po[0].po_status) === 2) {
      btnCancel = (
        <Button onClick={()=>{
          this.confirm(
            "Are you sure you want to cancel this PO?",
            this.cancelPO
          )
        }} style={{marginRight: '1rem'}} color="secondary">Cancel PO</Button>
      );
      // colSettings.push({
      //   key: 'edit',
      //   label: 'Edit',
      //   render: (val, key, row, index)=>{
      //     let po = this.state.po;
      //     if (po['line_item_id'] !== row['line_item_id']) {
      //       for (let i = 0; i < po.length; i++) {
      //         if (po[i]['line_item_id'] === row['line_item_id']) {
      //           index = i;
      //           break;
      //         }
      //       }
      //     }
      //     return <Button onClick={()=>{
      //       this.setState({dialog: 'edit_line_item', active_line: row, active_index: index});
      //     }}>Edit</Button>;
      //   },
      // },);
    } else if (po[0] && (parseInt(po[0].po_status) === 4 || parseInt(po[0].po_status) === 7)) {
      // For received or receiving PO, show close button
      btnCancel = (
        <Button onClick={()=>{
          this.confirm(
            "Are you sure you want to close this PO?",
            this.closePO
          )
        }} style={{marginRight: '1rem'}} color="secondary">Close PO</Button>
      );
    }


    return (
      <CardContainer>
        <div>
          {btnCancel}
          <Button style={{float: "right"}} onClick={
            ()=>{
              let filename = '';
              filename += this.po_number + '_detail';
              utils.export_table_to_csv('table-export', filename)
            }
          }>Export to CSV</Button>

          <DataTable
            data={po}
            colSettings={colSettings}
            withoutPaper
            tableSettings={{
              tableProps: {
                // className: "with-border",
                id: 'table-export'
              }
            }}
          />
        </div>
      </CardContainer>
    );
  }
  renderDialog = () => {
    switch (this.state.dialog) {
      case 'edit_line_item':
        return <EditPODialog
          po={this.state.active_line}
          onSubmit={this.editLineItem}
          closeDialog={()=>{this.setState({dialog: ''})}}
        />;
        break;
    }
  }
  renderAlert = () => {
    const { classes } = this.props;
    const { message_type, message_content, confirm_callback } = this.state;

    if (!message_content) return;

    return (
      <PopupAlert
        open={true}
        header={message_type}
        type={message_type}
        content={message_content}
        onSubmit={confirm_callback ? confirm_callback : this.closeAlert}
        onClose={this.closeAlert}
      />
    );
  }
  closeAlert = ()=>{
    this.setState({message_type: "", message_content: "", confirm_callback: null})
  }

  alert = (message, type) => {
    if (!type) type = "Error";
    this.setState({message_content: message, message_type: type});
  }
  confirm = (message, confirm_callback) => {
    this.setState({message_content: message, message_type: "Confirm", confirm_callback});
  }
}
export default withStyles(styles)(ManageInbound);

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
  PopupAlert
} from "components";

export default function withAlert(WrappedComponent) {
  class ComponentWithAlert extends Component {
    static contextTypes = {
      data: PropTypes.object.isRequired,
    };
    static propTypes = {
      classes: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.state = {
        message_type: "",
        message_content: "",
        confirm_callback: null,
      };
    }

    render() {

      const { message_type, message_content, confirm_callback } = this.state;
      return (
        <React.Fragment>
          <WrappedComponent alert={this.alert} confirm={this.confirm} {...this.props}/>

          { message_content && <PopupAlert
            open={true}
            header={message_type}
            type={message_type}
            content={message_content}
            onSubmit={confirm_callback ? confirm_callback : this.closeAlert}
            onClose={()=>{this.setState({message_type: "", message_content: "", confirm_callback: null})}}
          />}

        </React.Fragment>
      );
    }
    alert = (message, type) => {
      if (!type) type = "Error";
      this.setState({message_content: message, message_type: type});
    }
    confirm = (message, confirm_callback) => {
      this.setState({message_content: message, message_type: "Confirm", confirm_callback});
    }
  }

  ComponentWithAlert.displayName = `WithAlert(${getDisplayName(WrappedComponent)})`;

  return ComponentWithAlert;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

import {
  TrendingUp,
  Gavel,
  PermIdentity,
  Email,
  Search,
  AlarmAdd,
  AlarmOff,
  Publish,
  Schedule,
  PermMedia,
  BugReport,
  AccountCircle,
  InsertChart,
  SpeakerNotes,
  AssignmentInd,
  WatchLater,
  Assessment,
  AssignmentTurnedIn,
  CloudUpload,
  Book,
  Receipt,
  DonutLarge,
  Build,
  CloudDownload,
  Android,
  Work,
  AllInclusive,
  AddShoppingCart,
  LocalPrintshop,
  List,
  Group,
  DirectionsBike,
  AddCircle,
  ShoppingBasket,
  Store,
  Face,
  Assignment,
  Dashboard,
  Description,
  CompareArrows,
  Accessibility,
  Input,
  Poll,
  Person,
  Home,
  Undo,
  FlightTakeoff,
  Notifications,
  ExitToApp,
  FlightLand, ShoppingCart
} from '@material-ui/icons';
//
import {
  CustomIcon
} from "components";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import InboundDashboard from '../views/Inbound/InboundDashboard';
import ManageInbound from 'views/Inbound/ManageInbound'
import ImportInbound from "views/Inbound/ImportInbound";
import ImportProduct from "views/Product/ImportProduct";
import ImportOrder from "../views/Order/ImportOrder";
import NewOrder from '../views/Order/NewOrder';
import CreateLabel from '../views/Order/CreateLabel';
import ManageOrders from '../views/Order/ManageOrders';
import ManageProduct from '../views/Product/ManageProduct';
import Inventory from '../views/Inventory/Inventory';
import Profile from '../views/User/Profile';
import ManageUsers from '../views/User/ManageUsers';
import DownloadReport from '../views/Report/DownloadReport';
import EmailReport from '../views/Report/EmailReport';
import InboundAnalysis from '../views/Analysis/InboundAnalysis';
import OrderAnalysis from '../views/Analysis/OrderAnalysis';
import ReturnAnalysis from '../views/Analysis/ReturnAnalysis';
import ShipmentAnalysis from '../views/Analysis/ShipmentAnalysis';
import ImportForecast from '../views/Forecast/ImportForecast';
import Barcode from 'views/Barcode/Barcode'
import React from 'react';

const allRoutes = [
  {
    key: 'dashboard',
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    // icon: ()=><CustomIcon iconName="side_dashboard"/>,
    component: DashboardPage
  },
  {
    key: 'inbound',
    path: "/inbound",
    sidebarName: "Inbound",
    navbarName: "Inbound",
    icon: FlightLand,
    // icon: ()=><CustomIcon iconName="side_inbound"/>,
    submenu: [
      {
        key: 'inboundDashboard',
        path: '/inboundDashboard',
        sidebarName: 'Inbound Dashboard',
        navbarName: 'Inbound Dashboard',
        // icon: CloudUpload,
        component: InboundDashboard
      },
      {
        key: 'manageInbound',
        path: '/manageInbound',
        sidebarName: 'Manage PO',
        navbarName: 'Manage PO',
        // icon: CloudUpload,
        component: ManageInbound
      },
      {
        key: 'importInbound',
        path: '/importInbound',
        sidebarName: 'Import PO',
        navbarName: 'Import PO',
        // icon: CloudUpload,
        component: ImportInbound
      },
    ]
  },
  {
    key: "product",
    path: "/product",
    sidebarName: "Product",
    navbarName: "Product",
    icon: Store,
    // icon: ()=><CustomIcon iconName="side_product"/>,
    submenu: [
      {
        key: "manageProduct",
        path: '/manageProduct',
        sidebarName: 'Manage Product',
        navbarName: 'Manage Product',
        // icon: CloudUpload,
        component: ManageProduct
      },
      {
        key: "inventory",
        path: '/inventory',
        sidebarName: 'Inventory',
        navbarName: 'Inventory',
        // icon: CloudUpload,
        component: Inventory
      },
      {
        key: "importProduct",
        path: '/importProduct',
        sidebarName: 'Import Product',
        navbarName: 'Import Product',
        // icon: CloudUpload,
        component: ImportProduct
      },
      {
        key: 'barcode',
        path: "/barcode",
        sidebarName: "Barcode",
        navbarName: "Barcode",
        // icon: LocalPrintshop,
        component: Barcode
      },
    ]
  },
  {
    key: "order",
    path: "/order",
    sidebarName: "Order",
    navbarName: "Order",
    icon: ShoppingCart,
    // icon: ()=><CustomIcon iconName="side_order"/>,
    submenu: [
      {
        key: "newOrder",
        path: '/newOrder',
        sidebarName: 'New Order',
        navbarName: 'New Order',
        // icon: CloudUpload,
        component: NewOrder
      },
      {
        key: "createLabel",
        path: '/createLabel',
        sidebarName: 'Create Label',
        navbarName: 'Create Label',
        // icon: CloudUpload,
        component: CreateLabel
      },
      {
        key: "manageOrder",
        path: '/manageOrder',
        sidebarName: 'Manage Order',
        navbarName: 'Manage Order',
        // icon: CloudUpload,
        component: ManageOrders
      },
      {
        key: "importOrder",
        path: '/importOrder',
        sidebarName: 'Import Order',
        navbarName: 'Import Order',
        // icon: CloudUpload,
        component: ImportOrder
      },
    ]
  },
  {
    path: "/analysis",
    sidebarName: "Analysis",
    navbarName: "Analysis",
    icon: InsertChart,
    // icon: ()=><CustomIcon iconName="side_analysis"/>,
    submenu: [
      {
        path: "/inboundAnalysis",
        sidebarName: "Inbound",
        navbarName: "Inbound",
        // icon: Input,
        component: InboundAnalysis
      },
      {
        path: "/shipmentAnalysis",
        sidebarName: "Shipment",
        navbarName: "Shipment",
        // icon: AirportShuttle,
        component: ShipmentAnalysis
      },
      {
        path: "/returnAnalysis",
        sidebarName: "Return",
        navbarName: "Return",
        // icon: Undo,
        component: ReturnAnalysis
      },
      {
        path: '/orderAnalysis',
        sidebarName: 'Order',
        navbarName: 'Order',
        // icon: ShoppingBasket,
        component: OrderAnalysis
      }
    ]
  },
  {
    path: "/report",
    sidebarName: "Report",
    navbarName: "Report",
    icon: Assignment,
    // icon: ()=><CustomIcon iconName="side_report"/>,
    submenu: [
      {
        path: "/downloadReport",
        sidebarName: "Download Report",
        navbarName: "Download Report",
        // icon: CloudDownload,
        component: DownloadReport
      },
      {
        path: "/emailReport",
        sidebarName: "Email Report",
        navbarName: "Email Report",
        // icon: Email,
        component: EmailReport
      }
    ]
  },
  {
    path: "/admin",
    sidebarName: "Admin",
    navbarName: "Admin",
    icon: Group,
    // icon: ()=><CustomIcon iconName="side_admin"/>,
    submenu: [
      {
        path: "/manageUsers",
        sidebarName: "Manage User",
        navbarName: "Manage User",
        // icon: Group,
        component: ManageUsers
      },
    ]
  },
  {
    path: "/forecast",
    sidebarName: "Forecast",
    navbarName: "Forecast",
    icon: TrendingUp,
    // icon: ()=><CustomIcon iconName="side_forecast"/>,
    submenu: [
      {
        key: 'importForecast',
        path: "/forecastImport",
        sidebarName: "Import Forecast",
        navbarName: "Import Forecast",
        // icon: TrendingUp,
        component: ImportForecast
      },
    ]
  },
  {
    path: "/profile",
    sidebarName: "User Profile",
    navbarName: "User Profile",
    icon: AccountCircle,
    // icon: ()=><CustomIcon iconName="side_profile"/>,
    component: Profile
  },
  {
    key: 'logout',
    path: "/logout",
    sidebarName: "Logout",
    navbarName: "Logout",
    icon: ExitToApp,
    // icon: ()=><CustomIcon iconName="side_logout"/>,
    logout: true,
  },
  { key: 'redirect', redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default allRoutes;

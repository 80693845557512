import React, { Component } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Button, CustomInput, BasicDialog, PopupAlert } from 'components'
import {
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  FormControl, Grid,
} from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: '1rem',
  },
  selectInput: {
    width: '100%',
    margin: '0 0 .625rem 0',
  },
});

class EditPODialog extends Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    po: PropTypes.object.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: "Edit PO"
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      po: Object.assign({}, props.po),
      // Pop up alert
      message_type: "",
      message_content: "",
    };
  }

  render() {
    const { classes, closeDialog, onSubmit, title } = this.props;
    const { po } = this.state;

    let content = (
      <form onSubmit={onSubmit}>
        <CustomInput
          labelText='SKU'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            disabled: true,
            value: po.sku ? po.sku : '',
            onChange: this.handlePOChange.bind(this, 'sku'),
          }}
        />

        <CustomInput
          labelText='Item Name'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            disabled: true,
            value: po.name ? po.name : '',
            onChange: this.handlePOChange.bind(this, 'name'),
          }}
        />

        <CustomInput
          labelText='UPC'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            disabled: true,
            value: po.upc ? po.upc : '',
            onChange: this.handlePOChange.bind(this, 'upc'),
          }}
        />

        <CustomInput
          labelText='Quantity'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            inputProps: {
              min: 1,
              step: 1,
            },
            type: 'number',
            value: po.quantity ? po.quantity : '',
            onChange: this.handlePOChange.bind(this, 'quantity'),
          }}
        />

        <CustomInput
          labelText='Tracking Number'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: po.tracking ? po.tracking : '',
            onChange: this.handlePOChange.bind(this, 'tracking'),
          }}
        />

        <CustomInput
          labelText='Estimated Date'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            type: 'date',
            inputProps: {
              min: moment().format("YYYY-MM-DD"),
            },
            value: po.estimated_date ? po.estimated_date : '',
            onChange: this.handlePOChange.bind(this, 'estimated_date'),
          }}
        />
      </form>
    );

    let actions = [];
    actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
    actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

    return (
      <React.Fragment>
        {this.renderAlert()}
        <BasicDialog
          key="dialog"
          open={true}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          onClose={closeDialog}
          header={title}
          content={content}
          actions={actions}
        />
      </React.Fragment>
    );
  }

  handlePOChange = (key, e) => {
    let val = e.target.value;

    let po = Object.assign({}, this.state.po);
    po[key] = val;
    this.setState({po: po});
  }
  handleSubmit = () => {
    let new_po = Object.assign({}, this.state.po);
    let qty = new_po.quantity;
    if (!parseInt(qty) || parseInt(qty) < 1) {
      this.alert("Quantity should be a positive integer.");
      return;
    }
    new_po.quantity = parseInt(qty);
    this.props.onSubmit(new_po);
  }
  renderAlert = () => {
    const { message_type, message_content } = this.state;

    if (!message_content) return;

    return (
      <PopupAlert
        open={true}
        key="alert"
        header={message_type}
        type={message_type}
        content={message_content}
        onClose={this.closeAlert}
      />
    );
  }
  closeAlert = ()=>{
    this.setState({message_type: "", message_content: "" })
  }
  alert = (message, type) => {
    if (!type) type = "Error";
    this.setState({message_content: message, message_type: type});
  }
}

export default withStyles(styles)(EditPODialog)

export default class Manifest {

  constructor(dev_mode) {
    this.dev_mode = dev_mode;
  }

  getBaseUrl() {

    let base = window.location.origin;
    if (base === "http://localhost:3000" || base === "http://localhost:3001") base = 'https://customer.boxzooka.com';
    return base;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import {
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    Typography
} from '@material-ui/core';
import {
    CardContainer,
    Button,
    CustomInput,
    GridItem,
    DataTable
} from "components";
import { withAlert } from 'hoc'
import utils from 'utils/utils'

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    // custom styles
})};

class DownloadReport extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.keys = [];

        this.state = {
            loading: false,
            type_options: {},
            type: '',
            date_from: '',
            date_to: ''
        };

        document.title = 'Download Report';
    }

    render() {
        const { classes } = this.props;
        const { loading, type, type_options,date_from, date_to } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let options = [];
        for (let key of this.keys) {
            let option = type_options[key];
            options.push(<MenuItem key={key} title={option.definition} value={key}>{option.name}</MenuItem>);
        }

        let dateFrom = null;
        let dateTo = null;

        if (type_options[type] && type_options[type]['required_date'] === '1') {
            dateFrom = (
                <CustomInput
                    labelText='Date From'
                    formControlProps={{
                        fullWidth: true,
                        required: true,
                    }}
                    labelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        type: 'date',
                        value: date_from,
                        onChange: (e)=>{this.setState({date_from:e.target.value})}
                    }}
                />
            );

            dateTo = (
                <CustomInput
                    labelText='Date To'
                    formControlProps={{
                        fullWidth: true,
                        required: true
                    }}
                    labelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        type: 'date',
                        value: date_to,
                        onChange: (e)=>{this.setState({date_to:e.target.value})}
                    }}
                />
            );
        }

        return (
          <Grid container spacing={3}>
                {loadingBar}

                <GridItem xs={12} sm={6} md={6}>
                    <CardContainer>
                        <form onSubmit={this.downloadReport} className={classes.root}>
                            <FormControl required fullWidth className={classes.selectInput}>
                                <InputLabel shrink htmlFor="report_type">Report Type</InputLabel>
                                <Select
                                  value={type}
                                  onChange={(e)=>{
                                      let val = e.target.value;
                                      if (type_options[val]['required_date'] === '0') {
                                          this.setState({type: val, date_from: '', date_to: ''});
                                      } else {
                                          this.setState({type: val})}
                                  }

                                  }
                                  inputProps={{
                                      name: 'report_type',
                                      id: 'report_type',
                                  }}
                                >
                                    {options}
                                </Select>
                            </FormControl>

                            {dateFrom}

                            {dateTo}

                            <Button className={classes.button} type='submit'>Download</Button>
                        </form>
                    </CardContainer>
                </GridItem>

                {this.renderDefinitionTable()}
            </Grid>
        );
    }

    componentWillMount() {
        this.loadTypes();
    }

        // load product table
    loadTypes = () => {
        let base = this.context.data.getBase();
        const headers = {
           'token': localStorage.getItem('token'),
           'user': localStorage.getItem('user_id'),
           'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
           'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/report';

        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.loadTypesSuccess).catch(this.loadTypesError);
    }
    loadTypesSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

        if (!resp.data) {
            this.props.alert("Load report types error");
            return;
        }

        let keys = [];
        let options = {};
        for (let option of resp.data) {
            options[option.value] = option;
            keys.push(option.value);
        }

        let defaultType = keys[0] ? keys[0] : '';

        this.keys = keys;
        this.setState({type_options: options, type: defaultType});
    }
    loadTypesError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
        // download report
    downloadReport = (e) => {
        e.preventDefault();

        let { type, date_from, date_to } = this.state;

        let dtFrom = new Date(date_from);
        let dtTo = new Date(date_to);

        if (dtFrom > dtTo) {
            this.props.alert("Date From can not be later than Date To");
            return;
        }

        if (!date_from) date_from = 'empty';
        if (!date_to)  date_to = 'empty';

        let base = this.context.data.getBase();
        // let url = base + '/report/' + localStorage.getItem('warehouse_id') + '/' + localStorage.getItem('customer_id') + '/' + type + '/' + date_from + '/' + date_to;
        let url = base + '/api/customer/v1/report/' + localStorage.getItem('warehouse_id') + '/' + localStorage.getItem('customer_id') + '/' + type + '/' + date_from + '/' + date_to;
        // let w = window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
        let w = window.open(url);
        if (!w) {
            this.props.alert('To enable download, please allow pop up window');
        }
    }
    downloadReportSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.props.alert(resp.data.Error);
            return;
        }

       //  if (!resp.data) {
       //      this.props.alert("Download report error");
       //      return;
       //  }
       //
       //
       // utils.printPage(resp.date);
    }
    downloadReportError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

    renderDefinitionTable = () => {
        const { classes } = this.props;
        const { type_options } = this.state;
        if (!type_options) return null;

        let rows = [];
        for (let key of this.keys) {
            let option = type_options[key];
            rows.push(option);
        }

        if (rows.length === 0 ) return null;
        let colSettings = [{
            key: 'name',
            label: 'Report Type',
        },{
            key: 'definition',
            label: 'Definition',
        }];

        return (
          <GridItem xs={12} sm={12} md={6}>
              <CardContainer>
                  <div className={classes.title}>
                      <Typography variant="h4">
                          Report Type Definitions
                      </Typography>
                  </div>

                  <DataTable
                    data={rows}
                    withoutPaper
                    colSettings={colSettings}
                  />
              </CardContainer>
          </GridItem>
        );
    }
}
export default withAlert(withStyles(styles)(DownloadReport));

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Button, CustomInput, BasicDialog } from 'components'
import {
    withStyles,
} from '@material-ui/core'
import ItemCart from 'views/Order/ItemCart'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    customInput: {
        margin: theme.spacing(1, 0),
    },
});

class EditItemDialog extends Component {
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        display: PropTypes.bool.isRequired,
        closeDialog: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        items: PropTypes.array.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        title: "Edit Items"
    }

    constructor(props, context) {
        super(props, context);

        // handle removable mark
        let items = [];

        for (let item of props.items) {
            item.quantity_input = item.quantity;
            item.removable = false;
            items.push(item);
        }

        this.state = {
            items: Array.from(items),
        };
    }

    render() {
        const { classes, display, closeDialog, title } = this.props;
        const { items } = this.state;

        let content = (
            <ItemCart
                cart={items}
                onCartChange={(v)=>{this.setState({items: v})}}
                asDialog={true}
            />
        );

        let actions = [];
        actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
        actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

        return (
            <BasicDialog
                fullWidth
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                maxWidth={'md'}
                open={display}
                onClose={closeDialog}
                header={title}
                content={content}
                actions={actions}
            />
        );
    }

    handleSubmit = () => {
        this.props.onSubmit(this.state.items);
    }
}

export default withStyles(styles)(EditItemDialog)

// ##############################
// // // Dashboard styles
// #############################
const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

const dashboardStyle = theme => ({
  floatingIconContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(-1)
  },
  clickable: {
    cursor: "pointer"
  },
  statCardContainer: {},
  statCardContainerHeader: {
    textAlign: "center",
    // fontSize: theme.spacing(3),
    fontSize: theme.spacing(3.5),

  },
  statCard: {
    overflow: 'visible',
    // margin: theme.spacing(4, 0),
  },
  statReturnCard: {
    display: "flex"
  },
  statReturnCardIcon: {
    // backgroundColor: "#29b765",
    borderRadius: "3px",
    boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
    marginTop: theme.spacing(-2),
  },
  statCardAction: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  statDescContainer: {
    display: "flex",
    lineHeight: "1.5rem",
    flexDirection: "column"
  },
  statReturnIcon: {
    color: "white",
    fontSize: theme.spacing(4),
    // margin: theme.spacing(2, 5),
    margin: theme.spacing(2),
  },
  customCardIcon: {
    height: "67px",
    width: "64px",
  },
  statReturnValueContainer: {
    flexGrow: "1",
    padding: theme.spacing(1),
    paddingBottom: ".5rem !important",
    // backgroundColor: "#2ecc71",
    // color: "white",
    // fontSize: "1rem"
    fontSize: "1.25rem"
  },
  statReturnTable : {
    height: "100%",
    width: "100%"
  },
  clickEnabled: {
    cursor: "pointer",
  },
  clickDisabled: {
    cursor: "default",
  },
  statIconContainer: {
    // Old card style

    // borderRadius: "3px",
    // backgroundColor: "lightblue !important",
    // padding: "15px",
    // marginTop: "-20px",
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // float: "left",
    // color: "white",
    // boxShadow: "0 1px 4px 0 rgba(" + hexToRgb("#000") + ", 0.14)",

    // New style
    marginRight: theme.spacing(2)
  },
  statIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  statTable: {
    flexGrow: 1
  },
  statLabelCell: {
    // textAlign: "left",
    textAlign: "center",
    // color: "#999",
    whiteSpace: "nowrap",
    // fontSize: "1.25rem",
    fontSize: "1.5rem",
    // fontFamily: 'Raleway-Regular',
    fontFamily: 'Neuton-Regular',
    // width: theme.spacing(18)
  },
  statValeCell: {
    // textAlign: "right",
    textAlign: "center",
    whiteSpace: "nowrap",
    // fontSize: "1.25rem",
    fontSize: "1.5rem",
    // fontFamily: 'Raleway-Bold',
    fontFamily: 'Neuton-Bold',
    fontWeight: "bold",
    flexGrow: "1"
  },
  flexContainer: {
    borderTop: "5px solid #3AB0AC",
    display: "inline-flex",
    flexWrap: "wrap",
  },
  flexItem: {
    minWidth: "50%",
    flexGrow: 99
  },
});

export default dashboardStyle;

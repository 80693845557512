import React, { Component } from 'react'
import PropTypes from 'prop-types'
import utils from 'utils/utils'
import $ from 'jquery'
import _ from 'lodash'
import {
    Typography,
    Paper,
    Grid,
    withStyles, CardActionArea, CardContent
} from '@material-ui/core';
import {
    CardIcon,
    DataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, Cell
} from 'recharts';
import {
    Receipt,
    EuroSymbol,
    ShoppingBasket,
    ShoppingCart,
} from '@material-ui/icons';
import { withAlert } from 'hoc'
import moment from "moment-timezone";
import axios from 'axios';

const styles = theme => {return Object.assign(utils.getGeneralStyles(theme), {
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        fontSize: "1rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Roboto-Regular',
        fontSize: "1.25rem",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Roboto-Bold',
        fontSize: "1.25rem",
        fontWeight: "bold",
        flexGrow: "1"
    },
})};

class OrderAnalysis extends Component {
    static propTypes = {
        alert: PropTypes.func.isRequired,
        confirm: PropTypes.func.isRequired,
        classes:PropTypes.object.isRequired,
    };
    static contextTypes = {
        data: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            date_from: '',
            date_to: '',
            loading: false,
            dashboard_stats: null,
            detail_type: '',
            detail_data: null,
            top10_product: [],
            top10_customer: []
        };
        document.title = 'Order Analysis';
    }

    render() {
        let loadingBar = null;
        if (this.state.loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={3}>
                {loadingBar}

                {this.renderDashboard()}
                {this.renderDetail()}
                {this.renderTopProducts()}
                {this.renderTopCustomers()}

            </Grid>
        );
    }

    componentDidMount() {
        this.loadDashboard();
    }

    // load data api called when page is load
    loadDashboard = () => {
        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/analysis/order';
        let req = axios({
            method: 'get',
            url: API_URL,
            headers: headers
        });
        this.setState({loading: true});
        req.then(this.__loadDashboardSuccess).catch(this.__loadDashboardError);
    }
    __loadDashboardSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.props.alert(data.Error);
            return;
        }
        this.setState({
            dashboard_stats: data,
            top10_product: data.top10_product,
            top10_customer: data.top10_customer
        });
    }
    __loadDashboardError = (err)=>{utils.generalAjaxErrorHandler(this, err)}
    // load detail api called when each panel is clicked
    loadDetail = (type, date_from, date_to) => {
        type = type ? type : this.state.detail_type;
        date_from = date_from ? date_from : '';
        date_to = date_to ? date_to : '';

        let base = this.context.data.getBase();
        const headers = {
            'token': localStorage.getItem('token'),
            'user': localStorage.getItem('user_id'),
            'customer': (localStorage.getItem('customer_id') ? localStorage.getItem('customer_id') : '25'),
            'warehouse': (localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : '4')
        };
        const API_URL = base + '/api/customer/v1/analysis/order';

        let req = axios({
            method: 'post',
            data: {
                type,
                date_from,
                date_to
            },
            url: API_URL,
            headers: headers
        });

        this.setState({loading: true});
        req.then(this.__loadDetailSuccess.bind(this, type)).catch(this.__loadDetailError);
    }
    __loadDetailSuccess = (type, resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.props.alert(data.Error);
            return;
        }

        if (!data || (Array.isArray(data) && data.length === 0)) {
            this.props.alert("No data found.");
            return;
        }

        this.setState({detail_data: data, detail_type: type})
    }
    __loadDetailError = (err)=>{utils.generalAjaxErrorHandler(this, err)}

    renderDashboard = () => {
        let dashboard_stats = this.state.dashboard_stats ? this.state.dashboard_stats : {
            annual_order_value: "N/A",
            annual_order_number: "N/A",
            average_order_value: "N/A",
            average_order_unit: "N/A",
        };
        let revenue = (dashboard_stats.annual_order_value !== "N/A" ? '$' : "")+utils.numberWithComma(dashboard_stats.annual_order_value);
        let order = utils.numberWithComma(dashboard_stats.annual_order_number);
        let average_value = (dashboard_stats.average_order_value !== "N/A" ? '$' : "")+utils.numberWithComma(dashboard_stats.average_order_value);
        let average_unit = utils.numberWithComma(dashboard_stats.average_order_unit);

        return (
          <React.Fragment>
              {this.renderSingleStatCard(revenue, "annual_order_value")}
              {this.renderSingleStatCard(order, "annual_order_number")}
              {this.renderSingleStatCard(average_value, "average_order_value")}
              {this.renderSingleStatCard(average_unit, "average_order_unit")}
          </React.Fragment>
        );
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        if (!val || val === "N/A") click_disabled = true;

        let title = key;
        let icon = null;
        switch (key) {
            case "annual_order_value":
                icon = <CardIcon style={{
                    backgroundColor: "#ffa726"
                }} className={classes.statIconContainer}>
                    <EuroSymbol className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Revenue";
                break;
            case "annual_order_number":
                icon = <CardIcon style={{
                    backgroundColor: "#26c6da"
                }} className={classes.statIconContainer}>
                    <ShoppingCart className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Orders";
                break;
            case "average_order_value":
                icon = <CardIcon style={{
                    backgroundColor: "#66bb6a"
                }} className={classes.statIconContainer}>
                    <Receipt className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Avg. Value";
                break;
            case "average_order_unit":
                icon = <CardIcon style={{
                    backgroundColor: "#8e24aa"
                }} className={classes.statIconContainer}>
                    <ShoppingBasket className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Avg. Unit";
                break;
        }

        let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

        return (
          <GridItem xs={12} sm={6} md={limit_width ? 6 : 3}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadDetail(key);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                      whiteSpace: "normal"
                                  }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{val}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderDetail = () => {
        const { classes } = this.props;
        const { date_from, date_to, detail_type, detail_data } = this.state;
        let stat_type = detail_type ? detail_type : '';

        if (_.isEmpty(detail_data) || !detail_data || detail_data.length === 0 || !stat_type) return null;

        let detail_title = stat_type;
        let chart_value = '';
        let data = Array.from(detail_data);

        let colSettings = [];
        switch (stat_type) {
            case 'annual_order_value':
                detail_title = "Total Orders Revenue";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'order_value',
                        label: 'Orders Revenue',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "order_value";
                break;
            case 'annual_order_number':
                detail_title = "Total Orders";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_shipped',
                        label: 'Number of Orders',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "total_shipped";
                break;
            case 'average_order_value':
                detail_title = "Average Order Value";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_shipped',
                        label: 'Orders',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'order_value',
                        label: 'Value',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    },
                    {
                        key: 'avg_order_value',
                        label: 'Avg. Order Value',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "avg_order_value";
                break;
            case 'average_order_unit':
                detail_title = "Average Order Units";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_shipped',
                        label: 'Orders',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'order_unit',
                        label: 'Units',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'avg_order_unit',
                        label: 'Avg. Order Unit',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "avg_order_unit";
                data = data.map((val)=>{
                    return {
                        month: val.month,
                        avg_order_unit: parseFloat(val.avg_order_unit)
                    };
                });
                break;
        }

        let rows = Array.from(detail_data);

        return (
            <GridItem xs={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h4" id="tableTitle">
                                {detail_title}
                            </Typography>
                            <br/>
                            <CustomInput
                              labelText='Date From'
                              formControlProps={{
                                  required: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  style:{marginRight: '1rem'},
                                  type: 'date',
                                  value: date_from,
                                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                              }}
                            />
                            <CustomInput
                              labelText='Date To'
                              formControlProps={{
                                  required: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  type: 'date',
                                  value: date_to,
                                  onChange: (e)=>{this.setState({date_to: e.target.value})}
                              }}
                            />
                            <Button style={{marginLeft: '2rem', marginTop: "1rem"}} onClick={()=>{
                                const { date_from, date_to } = this.state;
                                if (moment(date_to).isBefore(date_from)) this.props.alert("Date to should be later than Date from.");
                                else this.loadDetail(null, date_from, date_to);
                            }}>Submit</Button>
                        </div>

                        <ResponsiveContainer width='95%' height={500}>
                            <LineChart
                              data={data}
                              margin={{
                                  top: 5, right: 30, left: 20, bottom: 5,
                              }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tickFormatter={(val)=>{
                                    if (typeof val === "string" && val.length >= 3){
                                        return val.substr(0,3);
                                    } else return val;
                                }}/>
                                <YAxis />
                                <Tooltip labelFormatter={(val)=>{
                                    let month = val;
                                    let year = '';
                                    if (typeof val === "string" && val.length >= 3){
                                        month = val.substr(0,3);
                                        year = val.substr(3);
                                    }
                                    month = this.displayFullMonth(month);
                                    return month + (year ? ', '+ year : '');
                                }}
                                         formatter={
                                             (value, name) => {
                                                 let label = name;
                                                 switch(name) {
                                                     case 'order_value':
                                                         label = 'Orders Revenue';
                                                         value = parseFloat(value) ? ('$' + utils.numberWithComma(value)) : value;
                                                         break;
                                                     case 'total_shipped':
                                                         label = 'Number of Orders';
                                                         value = parseInt(value) ? (utils.numberWithComma(value)) : value;
                                                         break;
                                                     case 'avg_order_value':
                                                         label = 'Average Order Value';
                                                         value = parseFloat(value) ? ('$' + utils.numberWithComma(value)) : value;
                                                         break;
                                                     case 'avg_order_unit':
                                                         label = 'Average Order Unit';
                                                         value =  value = parseFloat(value) ? (utils.numberWithComma(value)) : value;;
                                                         break;
                                                 }
                                                 return [value, label];
                                             }
                                         }
                                />
                                <Legend verticalAlign="top" height={36} formatter={
                                    (value) => {
                                        let label_map = {
                                            'order_value': 'Orders Revenue',
                                            'total_shipped': 'Number of Orders',
                                            'avg_order_value': 'Average Order Value',
                                            'avg_order_unit': 'Average Order Unit',
                                        };
                                        return label_map[value] ? label_map[value] : value;
                                    }
                                }/>
                                <Line type="monotone" dataKey={chart_value} stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>

                        <DataTable
                          data={rows}
                          className={classes.table}
                          tableSettings={{
                              tableProps: {
                                  id: "db_order"
                              },
                          }}
                          withoutPaper
                          colSettings={colSettings}
                        />

                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderTopProducts = () => {
        const { classes } = this.props;
        const { top10_product } = this.state;
        let rows = top10_product ? top10_product : [];

        let colSettings = [
            {
                key: 'sku',
                label: "SKU",
            },
            {
                key: 'ItemName',
                label: "ItemName",
            },
            {
                key: 'item_unit',
                label: "Quantity",
                render: utils.numberWithComma
            },
            {
                key: 'item_value',
                label: "Product Revenue",
                render: (val)=>{
                    return ('$' + utils.numberWithComma(val));
                }
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h4" id="tableTitle">
                            Top Products
                            <Button style={{float: 'right'}}  onClick={()=>{utils.export_table_to_csv("top_products", "top_products");}}>Export</Button>
                        </Typography>
                    </div>

                    <DataTable
                      data={rows}
                      className={classes.table}
                      tableSettings={{
                          tableProps: {
                              id: "top_products"
                          },
                      }}
                      withoutPaper
                      colSettings={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }
    renderTopCustomers = () => {
        const { classes } = this.props;
        const { top10_customer } = this.state;
        let rows = top10_customer ? top10_customer : [];

        let colSettings = [
            {
                key: 'name',
                label: "Name",
            },
            {
                key: 'order_number',
                label: "Orders",
                render: utils.numberWithComma
            },
            {
                key: 'order_unit',
                label: "Units",
                render: utils.numberWithComma
            },
            {
                key: 'order_value',
                label: "Orders Revenue",
                render: (val)=>{
                    return ('$' + utils.numberWithComma(val));
                }
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h4" id="tableTitle">
                            Top Customers
                            <Button style={{float: 'right'}}  onClick={()=>{utils.export_table_to_csv("top_customers", "top_customers");}}>Export</Button>
                        </Typography>
                    </div>

                    <DataTable
                      data={rows}
                      className={classes.table}
                      tableSettings={{
                          tableProps: {
                              id: "top_customers"
                          },
                      }}
                      withoutPaper
                      colSettings={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }

    displayFullMonth = (code) => {
        let monthMap = {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        };
        return monthMap[code] ? monthMap[code] : code;
    }
}
export default withAlert(withStyles(styles)(OrderAnalysis));
